import React from 'react';

import { 
} from '@material-ui/core';

import { Page } from '@components/v2/organisms';

export default function Index() {
  return (
    <Page title='Feedback'>
    </Page>

  );
}