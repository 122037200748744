import React, { useCallback, useContext, useEffect, useState } from 'react';

import { 
  Box
} from '@mui/material';

import { Page } from '@components/v2/organisms';
import { UserContext, BillingContext } from '@context';
import Goals from './goals';
import ToDo from './to-do/to-do';
import Community from './community';
import { useHistory } from 'react-router-dom';
import Invites from './invites';
import OnboardingBanner from './../../../components/organisms/onboarding-banner';
import { EventsContext } from '@context/events';

import useNotificationsStore from '@stores/notifications';
import { useDispatch } from 'react-redux';
import { loadUser } from './../../../actions/users';
import { ServicesContext } from '@context/services';
import { TriggersContext } from '@context/triggers';

export default function Home(){
  const { user, userFromContext, getUserToken } = useContext(UserContext);
  const history = useHistory();
  const { isLoading: isLoadingSubscription, checkBillingStatus } = useContext(BillingContext);
  const [communityHeight, setCommunityHeight] = useState(null);
  const { trackEvent } = useContext(EventsContext);
  const { api } = useContext(ServicesContext);
  const { checkTriggers } = useContext(TriggersContext);
  const dispatch = useDispatch();
  const { isLoading : isLoadingNotifications, load : loadNotifications } = useNotificationsStore();

  useEffect(() => {
    if(userFromContext){
      dispatch(loadUser());
      if(userFromContext.status == 'PENDING')
        history.push('/index');

      trackEvent('Home:Open');
    }
    if(user?.id && !isLoadingNotifications){
      checkTriggers();
      loadNotifications(api, getUserToken);
    }
  }, [userFromContext]);

  useEffect(() => {
    if(!isLoadingSubscription){
      checkBillingStatus(history);
    }
  }, [isLoadingSubscription]);

  const communityRefContainer = useCallback((n) => {
    if (!n) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      var height = entries[0].contentRect.height;
      if(height){
        setCommunityHeight( height);
      }
    });
    resizeObserver.observe(n);
  }, []);

  return (
    <Page includeProfile={true} OnboardingBanner={<OnboardingBanner/>}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Goals />
        <ToDo />
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }} ref={communityRefContainer}>
          <Community height={communityHeight}/>
        </Box>
      </Box>
      <Invites />
    </Page>
  );
}

