import React from 'react';
import moment from 'moment';

import {
  Chart as ChartJS,
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
} from 'chart.js';

import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  annotationPlugin
);

import { buildDataset, buildOptions, getMinDate } from './helpers';

export default function Chart(props){
  const { data, activeDatasetName, activeRange, activeDay } = props;

  const options = buildOptions({ minDate: getMinDate(data), activeRange, data, activeDay  });

  const chartData = {
    datasets: [
      buildDataset({
        label: 'Mood',  
        data: data.map(d => {return { y: d.mood, x: moment(d.createdAt) };}),
        activeDatasetName
      }),
      buildDataset({
        label: 'Energy',  
        data: data.map(d => {return { y: d.energy, x: moment(d.createdAt) };}),
        activeDatasetName
      }),
      buildDataset({
        label: 'Pain',  
        data: data.map(d => {return { y: d.pain, x: moment(d.createdAt) };}),
        activeDatasetName
      }),
      buildDataset({
        label: 'Sleep',  
        data: data.filter(d => d.sleep > 0).map(d => {return { y: d.sleep, x: moment(d.createdAt) };}),
        activeDatasetName
      })
    ]
  };

  return (
    <Line 
      options={options}
      data={chartData}/>
  );
}