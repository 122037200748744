import React, { useContext, useEffect } from 'react';

import {  Box } from '@components/v2/atoms';
import { UserContext, EventsContext } from '@context';
import { useTheme } from '@emotion/react';
import { Page } from '@components/v2/organisms';
import Profile from '@components/v2/templates/profile';

export default function Index(){
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);

  useEffect(() => {
    if(user?.id){
      trackEvent('Profile:Open');
    }
  }, [user]);

  return (
    <Page title='Profile' includeBackButton={true}>
      <Box fullHeight sx={{ overflowY: 'scroll' }}>

        <Box sx={{ 
          height: '30px', 
          background: theme.palette.primary.main
        }}>
        </Box>
        <Box sx={{ marginTop: '-25px', display:'flex', justifyContent: 'center', px: 2.5 }}>
          <Profile profileId={user?.id}/>
        </Box>
      </Box>
    </Page>
  );
}

