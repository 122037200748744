import React, { useCallback, useContext, useState } from 'react';

import { 
  Box,
  Typography,
  Stack,
  IconButton
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { RecoveryContext } from '@context';

import Section from './section';
import { LoadableList, LoadableText } from '@components/atoms';
import { Icon, Card, Dot } from '@components/v2/atoms';
import { useHistory } from 'react-router-dom';
import { getProgramCompletionPercentage, getDaysLeft } from '@helpers/programs';
import { useTheme } from '@emotion/react';
import EmptySection from './empty-section';
import CreateProgramDialog from '../programs/new/create-program-dialog';
import { CircularProgress } from '@components/v2/molecules';
import { optionalPlural } from '@helpers/text';

export default function Programs(){
  const PROGRAMS_SECTION_HEIGHT_IN_PX = 68;
  const { plans, programs, isLoadingPrograms, loadPrograms, loadActiveDaySessions, loadDailySessionStats } = useContext(RecoveryContext);
  const history = useHistory();
  const theme = useTheme();
  const [isCreateProgramDialogOpen, setIsCreateProgramDialogOpen] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState(1);

  const programsStackRef = useCallback(node => {
    if (!node) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      var height = entries[0].contentRect.height;
      if(height){
        setNumberOfRows(Math.max(1,
          Math.floor(height / PROGRAMS_SECTION_HEIGHT_IN_PX))
        );
      }
    });
    resizeObserver.observe(node);
  }, []);

  const Program = (props) => {
    const { program } = props;

    return (
      <Box
        data-testid={`recovery-program-card-${program.name}`}
        onClick={() => history.push(`/recovery/programs/${program.id}`)}>
        <Card sx={{ p: 1, background: 'transparent', display: 'flex', alignItems: 'center'  }}>
          <Box center='h' sx={{ paddingRight: 1.5, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'  }}>
            <CircularProgress
              fontSize={14}
              light={true} 
              size = {42}
              fontColor='#000'
              text={`${getProgramCompletionPercentage(program)}%`}
              value={getProgramCompletionPercentage(program)}
              count={program?.sessions?.totalCount}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography component='div'
                variant='body1'
                fontWeight='medium'
                color='#000'>
                <LoadableText isLoading={isLoadingPrograms} text={program?.name} minWidth={200}/>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography component='div' variant='caption' color='text.secondary'>
                <LoadableText isLoading={isLoadingPrograms} text={`${program.adherence}% Adherence`} minWidth={100}/>
              </Typography>
              <Dot sx={{ mx: 1 }}/>
              <Typography component='div' variant='caption' color='text.secondary'>
                <LoadableText isLoading={isLoadingPrograms} text={`${getDaysLeft(program)} ${optionalPlural('Day', getDaysLeft(program))} Remaining`} minWidth={100}/>
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={() => history.push(`/recovery/programs/${program.id}`)} sx={{ paddingRight: 0 }}>
            <ChevronRightIcon color='disabled' sx={{ fontSize: 16 }} />
          </IconButton>
        </Card>
      </Box>
    );
  };

  return (
    <Section title='Programs'
      buttonDataTestId='recovery-program-manage-button'
      buttonText={programs.length > 0 ? 'View All' : null}
      buttonAction={
        plans.length == 0 ?
          null
          : () => { programs.length > 0  ? history.push('/recovery/programs') : setIsCreateProgramDialogOpen(true);}}
      buttonIcon= {programs.length > 0 ?         
        <ChevronRightIcon /> : 
        <Icon 
          type='circle-plus'
          fill={theme.palette.secondary.main}
          stroke={theme.palette.primary.main}
          size={24}/>
      }
    >
      {(!isLoadingPrograms && programs.length == 0) &&
        <>
          <EmptySection 
            opacity= {plans.length === 0 ? 0.4 : 1}
            iconType='dumbbell'

            message={plans.length > 0 ? 
              'Create a recovery program from your plans.' 
              : 'Create an exercise plan to assign to your recovery program.'}
            action={plans.length > 0 ?
              () => programs.length > 0  ? history.push('/recovery/programs') : setIsCreateProgramDialogOpen(true)
              : null}/>
          <CreateProgramDialog 
            isOpen={isCreateProgramDialogOpen}
            close={(shouldRefresh) => {
              setIsCreateProgramDialogOpen(false);
              if(shouldRefresh){
                loadPrograms(true);
                loadActiveDaySessions(true);
                loadDailySessionStats(true);
              }
            }}
          />
      
        </>
      }

      <Stack spacing={1} sx={{ flexGrow: 1, px: 2  }} ref={programsStackRef}>
        <LoadableList 
          numberOfRows={numberOfRows}
          isLoading={isLoadingPrograms}
          rows={programs.slice(0, numberOfRows)}
          getRow={(program) => <Program key={program.id} program={program}/> }
        />
      </Stack>
    </Section>
  );
}

