import React, { useContext, useEffect, useState } from 'react';

import { 
  Stack,
  Typography,
  MobileStepper
} from '@mui/material';

import { Box } from '@components/v2/atoms';
import OnboardingPage from './../../page';
import Image from 'mui-image';
import { useTheme } from '@emotion/react';
import { OnboardingContext } from '../../../context';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { EventsContext, UserContext, ServicesContext } from '@context';
import { StatusBar } from '@capacitor/status-bar';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const steps =  [
  {
    title: 'Mental Health',
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding_step_3_mock_up_1.png',
    text1: 'Brace helps you prioritise wellbeing throughout your recovery. Gather insights on your mental health through "Diary Entries" and build you understanding of the journey in "Learn"'
  },
  {
    title: 'Physical Recovery',
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding_step_3_mock_up_2.png',
    text1: 'Brace helps you stay on track to reach your recovery goals. Build and manage your exercises programmed in "Recovery" and celebrate your progress with "Trophies".'
  },
  {
    title: 'Community of Peers',
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding_step_3_mock_up_3.png',
    text1: 'Brace\'s Community ensures you\'re never alone in your journey. Ask questions and access peer insights in "Discussion". Share your journey and celebrate milestones with "Check-Ins"'
  }
];

export default function Step1(){
  const theme  = useTheme();
  const { next, back, setIsLoading } = useContext(OnboardingContext);
  const { trackEvent } = useContext(EventsContext);
  const { getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [activeStep, setActiveStep] = useState(0);

  const handleBack = () => {
    if(activeStep == 0){
      back();
    }else{
      setActiveStep(activeStep - 1);
    }
  };

  const handleNext = () => {
    if(activeStep >= steps.length - 1){
      trackEvent('Onboarding:Carousel:Complete');
      completeOnboarding();
    }else{
      setActiveStep(activeStep + 1);
    }
  };

  const handleSwipe = (s) => {
    if(s >= steps.length - 1){
      trackEvent('Onboarding:Carousel:Complete');
    }

    setActiveStep(s);
  };

  const completeOnboarding = async () => {
    setIsLoading(true);
    await api.put({ url: 'accounts/onboarding/complete', getUserToken  });
    setIsLoading(false);
    next();
  };

  useEffect(() => {
    StatusBar.setOverlaysWebView({ overlay: false });
  }, []);

  return (
    <OnboardingPage 
      shouldAppearBehindStatusBar
      sx={{ overflow: 'hidden' }} 
      nextButtonText={activeStep == steps.length - 1 ? 'Continue' :'Next'}
      nextOverride={handleNext} 
      backOverride={handleBack}>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          autoplay={false}
          interval={10000}
          onChangeIndex={handleSwipe}
          enableMouseEvents
          style={{ width: '100vw', flexGrow: 1 }}
          containerStyle={{  width: '100%', height: '100%', px: 2 } }
        >
          {steps.map((step, index) => (
            <Box key={index} sx={{  width: '100%', height: '100%' }}>
              <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                color: 'braceGreenDark.main',
                fontWeight: 'medium',
                height: '100%' }}>
                <Box flex
                  center='h'
                  sx={{ 
                    paddingTop: 'calc(env(safe-area-inset-top) + 4px)',
                    background: `radial-gradient(${theme.palette.secondary.main}, ${theme.palette.primary.main})`, 
                    width: '100%' }}>
                  <Image 
                    duration={100} 
                    style={{ zIndex: 2 }}
                    width='75vw'
                    height='calc(75vw * 1.61)'
                    src={step.image}/>
                </Box>
                <Box sx={{ position: 'relative', zIndex: 3 , width: '100%', height: '77px', marginTop:'-75px', backgroundImage: 'linear-gradient(transparent, #fafafa, #fafafa)' }}>
                </Box>
                <Box sx={{ position: 'relative', zIndex: 4 , width: '100%', marginTop:'-25px' }}>
                  <MobileStepper
                    sx={{ 
                      py:1, 
                      backgroundColor: '#fafafa', 
                      justifyContent: 'center', 
                      '.MuiMobileStepper-dotActive' : {
                        backgroundColor: theme.palette.primary.main
                      } }}
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                  />
                </Box>
                <Box sx={{ 
                  fontSize: 'h3.fontSize', 
                  textAlign: 'center',
                  fontWeight: 'medium'
                }}>
                  {step.title}
                </Box>
                <Box 
                  sx={{ 
                    fontSize: 'body1.fontSize', 
                    color: 'text.disabled',
                    textAlign: 'center',
                    fontWeight: 'regular',
                    px: 2,
                    paddingBottom: 1
                  }}>
                  {step.text1}
                </Box>
              </Stack>
                
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
    </OnboardingPage>
  );
}