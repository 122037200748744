import React from 'react';

import {
  Stack
} from '@mui/material';

import { TextField, DatePicker, CountrySelect } from '@components/v2/atoms';

export default function Step1(props){
  const { formik } = props;
  return (
    <Stack 
      spacing={3}
      sx={{ flexGrow: 1, marginTop: 2, marginBottom: 2 }}
    >
      <TextField label='First Name'
        placeholder='Enter your first name'
        autoComplete='given-name'
        id='firstName'
        inputProps={{ 'data-testid': 'index-sign-up-text-first-name' }}
        required
        onChange={formik.handleChange}
        value={formik.values.firstName}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
      />
      <TextField label='Last Name'
        placeholder='Enter your last name'
        autoComplete='family-name'
        id='lastName'
        inputProps={{ 'data-testid': 'index-sign-up-text-last-name' }}
        required
        onChange={formik.handleChange}
        value={formik.values.lastName}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
      />
      <DatePicker 
        disableFuture
        required
        inputProps={{ 'data-testid': 'index-sign-up-text-date-of-birth' }}
        label='Date of Birth'  
        id='dateOfBirth'
        autoComplete='bday'
        value={formik.values.dateOfBirth}
        error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
        helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
        onBlur={() => {
          formik.setFieldTouched('dateOfBirth', true);
        }}
        onChange={(value) => {
          formik.setFieldValue('dateOfBirth', value ?  value.toISOString() : null, true);
        }}
      />  

      <CountrySelect
        autoComplete='country'
        label='Location' 
        fullWidth={true}
        placeholder='Select your location'
        onChange={(value) => {formik.setFieldValue('country', value);}}
        value={formik.values.country}
        touched={formik.touched.country}
        error={formik.errors.country}/>

    </Stack>
  );
}