import React, { useContext } from 'react';
import { Share } from '@capacitor/share';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { 
  Box,
  Drawer,
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { logOut } from '../../actions/users';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SpeakerNotesIcon from '@material-ui/icons//SpeakerNotes';
import BookIcon from '@material-ui/icons//Book';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SettingsIcon from '@mui/icons-material/Settings';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import { makeStyles } from '@material-ui/core/styles';
import { UserContext, DialogContext, EventsContext } from '@context';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  signOutList: {
    width: 250
  }
}));

export default function MenuDrawer(props) {
  const classes = useStyles();
  const { handleLogout, user } = useContext(UserContext);
  const { openFeedbackDialog } = useContext(DialogContext);
  const { trackEvent } = useContext(EventsContext);
  const notificationCount = useSelector(state => state.notifications.notifications.filter(n => !n.hasBeenRead)).length;
  const dispatch = useDispatch();
  const history = useHistory();
  
  const signOut = () => {
    trackEvent('User:LogOut');
    return dispatch(logOut())
      .then(() => {
        handleLogout(() => {
          history.push('/index');
        });
     
      });
  };

  const goTo = (location) => {
    props.closeMenu();
    history.push(location);
  };

  const share = async () => {
    // this is the complete list of currently supported params you can pass to the plugin (all optional)
    var options = {
      text:  user?.type === 'PRACTITIONER' ?
        'I am using Brace to empower my clients recovery! You can download for free and join the Brace Community too.' :
        'I’m using Brace to empower my recovery journey! Try yourself by downloading the free app and joining the community at.', // not supported on some apps (Facebook, Instagram)
      title: 'Brace', // fi. for emailan array of filenames either locally or remotely
      url: 'https://www.bracesocial.com',
      dialogTitle: 'Brace' // Android only, you can override the default share sheet title
    };

    await Share.share(options);
  };

  return (
    <Box className={classes.root}>
      <React.Fragment>
        <Drawer anchor={'left'} open={props.isMenuOpen} onClose={props.closeMenu}>
          <Box display="flex"
            flexDirection="column"
            height='100%'
            width={250}>
            <Box flexGrow={1} marginTop={4}>
              <ListItem button onClick={() => goTo(`?profileId=${user?.id}`)} data-testid='menu-drawer-profile-button'>
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText primary={'Profile'} />
              </ListItem>
              <ListItem button component={Link} to='/notifications'>
                <ListItemIcon>              
                  <Badge badgeContent={notificationCount} color="secondary" variant="dot">
                    <NotificationsIcon />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={'Notifications'} />
              </ListItem>
              <ListItem button component={Link} to='/trophies'>
                <ListItemIcon><EmojiEventsIcon /></ListItemIcon>
                <ListItemText primary={'Trophies'} />
              </ListItem>
              <ListItem onClick={() => openFeedbackDialog()}>
                <ListItemIcon><SpeakerNotesIcon /></ListItemIcon>
                <ListItemText primary={'Support Chat'} />
              </ListItem>
              <ListItem button
                component="a"
                href="https://www.bracesocial.com/blog"
                target="_blank">
                <ListItemIcon><BookIcon /></ListItemIcon>
                <ListItemText primary={'Brace Blog'} />
              </ListItem>
              {user?.role != 'PRACTITIONER' &&
                <ListItem button
                  onClick={share}
                  target="_blank">
                  <ListItemIcon><EmojiPeopleIcon /></ListItemIcon>
                  <ListItemText primary={'Invite Friends'} />
                </ListItem>
              }
              <ListItem button component={Link} to='/settings'>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText primary={'Settings'} />
              </ListItem>
            </Box>
            <Box>
              <List className={classes.signOutList}>
                <ListItem button onClick={signOut} data-testid='menu-drawer-button-log-out'>
                  <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                  <ListItemText primary={'Log Out'} />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </Box>
  );
}

