import React from 'react';

import { useMediaQuery } from '@mui/material';
import Image from 'mui-image';
import { useTheme } from '@emotion/react';
import { Box, Icon, IconButton } from '@components/v2/atoms';

export default function PaywallPage(props){
  const { children, sx, back, showHeader = true } = props;
  const theme = useTheme();
  const matches = useMediaQuery('(min-height: 900px)');

  return (
    <Box flexColumn
      sx={{ 
        ...sx,
        marginTop: 'calc(0px - env(safe-area-inset-top))',
        paddingTop: showHeader ? 'calc(env(safe-area-inset-top))' : 0,
        height:'calc(100vh - env(safe-area-inset-bottom))'
        
      }}>
      {showHeader &&
        <Box flex sx={{ background: theme.palette.primary.main, p: matches ? 3 : 2, width: '100%' }}>
          <Box center='vertical' sx={{ width: '20%' }}>
            {back &&
                <IconButton 
                  variant='rounded-outlined' 
                  onClick={back}
                >
                  <Icon 
                    size={20}
                    type={'back'}
                    fill='rgba(255, 255, 255, 0.12)'
                    stroke={theme.palette.braceGreenDark.contrastText}
                  />
                </IconButton>
            }
          </Box>
          <Box sx={{ width: '60%', display: 'flex', justifyContent: 'center', py: 1 }}>
            <Image 
              duration={0} 
              width='133px' 
              src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_White%20and%20grad.png'/>
          </Box>
        </Box>
      }
      <Box flexColumn sx={{ flexGrow: 1, background: '#fafafa' }}>
        {children}
      </Box>
    </Box>
  );
}