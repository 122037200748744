import React, { useContext, useEffect, useState } from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import { ServicesContext, UserContext } from '@context';
import { Icon, Radio } from '@components/v2/atoms';
import { NewPostContext } from '@components/organisms';
import { useDispatch } from 'react-redux';
import { closeModal, openSuccessModal } from '../../../../actions/modal';
import { useTheme } from '@emotion/react';
import { LoadableText } from '@components/atoms';
import { toShortDateString } from '@helpers/dates';

export default function UserGoal(props){
  const { isLoadingGoals, areGoalsExpanded } = props;
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  var dispatch = useDispatch();
  var { goal } = props;
  const theme = useTheme();

  var [isCompleted, setIsCompleted] = useState(false);
  
  useEffect(() => {
    setIsCompleted(goal.isCompleted ?? false);
  }, [goal]);
  
  const savePost = async (data) => {
    const formData = new FormData();
    formData.append('message', data.message);
  
    if(data.image){
      formData.append('image', data.image);
    }
  
    if(data.organisation){
      formData.append('organisationId', data.organisation.id);
    }
  
    if(data.team){
      var teamIds = [ data.team.id ];
      formData.append('teamIds', teamIds);
    }
  
    await api.post({ data: formData, url: 'community/posts', user });
    dispatch(closeModal());
  };
  
  const openCreatePost = () => {
    openNewPostDialog({ 
      title: 'New Check-In', 
      placeholder: 'Your Post..', 
      buttonAction: savePost, 
      successToastMessage : 'Check-in Created!', 
      isAccessControlEnabled: true,
      isPrivateEnabled: true
    });
  };
  
  const handleOnClick = () => {
    if(!isCompleted){
      setIsCompleted(true);
      api.put({ user, url : `goals/${goal.id}/toggle` })
        .then(() => {
          dispatch(openSuccessModal('Goal Complete', (<><p>Well done on completing your recovery goal!</p><p>Celebrate your progress with the Brace Community!</p></>), 
            'target', { text: 'Post New Check-In', onClick: openCreatePost }));
        });
    }else{
      setIsCompleted(false);
      api.put({ user, url : `goals/${goal.id}/toggle` });
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: areGoalsExpanded ? 'flex-start' : 'center', py: 0.25 }}>
      <Box sx={{ display: 'flex', alignItems: areGoalsExpanded ? 'flex-start' : 'center', marginTop : areGoalsExpanded ? '2px' : 0 }}>
        <Icon type='target'
          size={18}
          stroke={
            isCompleted ? 
              theme.palette.secondary.dark:
              theme.palette.primary.main
          }/>
      </Box>
      <Box sx={{ flexGrow: 1, paddingX: 1, overflow: 'hidden', lineHeight: 1 }}>
        <Typography component='div'
          sx={{ 
            color: isCompleted ? theme.palette.secondary.dark : '#030712',
            textDecoration: isCompleted ? 'line-through': 'none'
          }}>
          <LoadableText isLoading={isLoadingGoals}
            text={
              areGoalsExpanded ?
                <Box sx={{ display: 'flex', fontSize:'body2.fontSize' }}>
                  <Typography fontWeight='medium' fontSize='body2.fontSize'>
                    {`${goal.description}${goal.targetDate ? ` by ${toShortDateString(goal.targetDate, false)}` : ''}`}
                  </Typography>
                </Box> :
                <Box sx={{ display: 'flex', fontSize:'body2.fontSize', overflow: 'hidden' }}>
                  <Typography className='goal-description'
                    fontWeight='medium'
                    fontSize='body2.fontSize'
                    sx={{ overflow: 'hidden', whiteSpace: areGoalsExpanded ? 'wrap' : 'nowrap', textOverflow: 'ellipsis', flexGrow: 1 }}>
                    {goal.description}
                  </Typography> 
                  {goal.targetDate &&
                    <Typography sx={{ whiteSpace: 'nowrap' }} fontWeight='medium' fontSize='body2.fontSize'> 
                      {`by ${toShortDateString(goal.targetDate, false)}`}
                    </Typography>
                  }
                </Box>}
          />
     
        </Typography>
      </Box>
      <Radio
        size={18}
        disabled={isLoadingGoals}
        onClick={handleOnClick}
        sx={{ p: 0, marginTop : areGoalsExpanded ? '2px' : 0 }}
        checked={isCompleted} />
    </Box>
  );
}

