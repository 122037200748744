import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { 
  Box
} from '@mui/material';

import ButtonGroup from './button-group';
import Chart from './chart';

import {  filterData } from './helpers';
import { Card } from '@components/v2/atoms';

export default function DiaryGraph(props){
  const { data, activeDay } = props;
  const [ activeDatasetName, setActiveDatasetName ] = useState('mood');
  const [ activeRange, setActiveRange ] = useState('1w');
  const [ filteredData, setFilteredData ] = useState([]);

  useEffect(() => {
    var result = filterData(data, activeRange);
    setFilteredData(result);
  }, [activeRange, data]);

  const getRangeOptions = () => {
    var options = [];

    if(data.filter(d => moment(d.createdAt).isBefore(moment().subtract(7, 'days'))).length > 0){
      options.push({ name: '1w', value:'1w' });
      options.push({ name: '1m', value:'1m' });
    }
    if(data.filter(d => moment(d.createdAt).isBefore(moment().subtract(1, 'month'))).length > 0){
      options.push({ name: '6m', value:'6m' });
    }
    if(data.filter(d => 
      moment(d.createdAt).isBefore(moment().subtract(6, 'month')) && 
      moment(d.createdAt).isAfter(moment().subtract(12, 'month'))).length > 0){
      options.push({ name: '1y', value:'1y' });
    }

    return options;
  };

  const getAttributeOptions = () => {
    var options = [
      { name: 'Mood', value:'mood' },
      { name: 'Pain', value:'pain' },
      { name: 'Energy', value:'energy' },
      { name: 'Sleep', value:'sleep' }
    ];

    return options;
  };

  return (
    <Card>
      <Box  sx={{ width : '100%', padding: 1, display: 'flex', justifyContent: 'center' }}>
        <ButtonGroup 
          handleChange={(value) => setActiveDatasetName(value)} 
          value={activeDatasetName}
          options = {getAttributeOptions(data)}
        />
      </Box>
      <Box sx={{ height: '17.5vh' }}>
        <Chart 
          activeDay={activeDay}
          data={filteredData}
          activeDatasetName={activeDatasetName}
          activeRange={activeRange} />
      </Box>
      <Box  sx={{ width : '100%', padding: 1, display: 'flex', justifyContent: 'center' }}>
        <ButtonGroup 
          handleChange={(value) => setActiveRange(value)} 
          value={activeRange}
          options = {getRangeOptions()}
        />
      </Box>
    </Card>
  );
}