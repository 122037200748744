
class AppConfig {
  constructor(){
    this.version = VERSION;
    this.apiBaseUrl= 'https://bracesocial-dev-api.azurewebsites.net/api/';
    this.contentBaseUrl= 'https://bracesocialdev.blob.core.windows.net/';
    this.profileImagesBaseUrl = 'https://bracesocialdev.blob.core.windows.net/';
    this.webAppUrl= 'https://app-dev.bracesocial.com';
    this.defaultInputsEnabled = false;
    this.appInsightsKey = 'InstrumentationKey=5b005d3f-dc15-44e8-bc0b-ec69ee856055;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/';
    this.consoleLoggingEnabled = true;
    this.mixPanelKey = 'b2c9efaddf78f14005849d9da1e0609d';
    this.logLevel = 0;
    this.auth0Domain = 'bracesocial-dev.eu.auth0.com';
    this.auth0Audience = 'https://bracesocial-dev.eu.auth0.com/api/v2/';
    this.auth0ClientId = 'tfegdDlYRm09w87vlZMLcIZZCR2M0yPQ';
    this.statsigKey = 'client-ivyIoi4G4hAdRbM6aPSSFvIaDCBcqSc1gFj6zz7143i';
    this.environment = 'development';
    this.accountOnboardingCourseId = '3683b659-806f-4b70-89fe-de15855c8ef4';
    this.isHubspotChatEnabled = false;
  }
} 

export default new AppConfig();