import React, { useContext } from 'react';

import {  Box, Button, CountrySelect, DatePicker, Select, TextField } from '@components/v2/atoms';
import { useTheme } from '@emotion/react';
import { UserAvatar } from '@components/v2/molecules';
import ImageUploader from './../../../components/images/image-uploader';
import { UserContext } from '@context/user';
import { useFormik } from 'formik';
import moment from 'moment';

import * as yup from 'yup';
import { Stack } from '@mui/material';
import { ServicesContext } from '@context/services';
import { isNullOrEmptyOrBlack } from '@helpers/text';

const ACTIVITY_TYPE_OPTIONS = [
  'Acrobatics',
  'American Football',
  'Archery',
  'Australian Rules Football',
  'Badminton',
  'Ballet',
  'Baseball',
  'Basketball',
  'Bodybuilding',
  'Bowling',
  'Boxing',
  'Brazilian Jiu-Jitsu (BJJ)',
  'Calisthenics',
  'Camogie',
  'Cheerleading',
  'Cricket',
  'CrossFit',
  'Cycling',
  'Dance',
  'Diving',
  'Fencing',
  'Field Hockey',
  'Figure Skating',
  'Floorball',
  'Frisbee',
  'Gaelic Football',
  'Golf',
  'Gym',
  'Gymnastics',
  'Handball',
  'Hiking',
  'Horseriding',
  'Hurling',
  'Ice Hockey',
  'Jiu-Jitsu',
  'Judo',
  'Karate',
  'Kayaking',
  'Kickboxing',
  'Martial Arts',
  'Lacrosse',
  'Luge',
  'Military',
  'Mixed Martial Arts (MMA)',
  'Motocross',
  'Muay Thai',
  'Netball',
  'None',
  'Padel',
  'Parkour',
  'Pickleball',
  'Pilates',
  'Powerlifting',
  'Rock Climbing',
  'Rowing',
  'Rugby League',
  'Rugby Sevens',
  'Rugby Union',
  'Running',
  'Sailing',
  'Skateboarding',
  'Skiing',
  'Snooker',
  'Snowboarding',
  'Snow Sports',
  'Soccer',
  'Softball',
  'Squash',
  'Surfing',
  'Swimming',
  'Table Tennis',
  'Taekwondo',
  'Tag Rugby',
  'Tennis',
  'Touch Rugby',
  'Track and Field',
  'Trail Running',
  'Trampolining',
  'Triathlon',
  'Volleyball',
  'Walking',
  'Water Sports',
  'Water Polo',
  'Weightlifting',
  'Windsurfing',
  'Work Related',
  'Wrestling',
  'Yoga',
  'Other'
];

export default function ProfileEdit(props){
  const MIN_AGE = 17;
  const { handleSaveImage, handleUpdate, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { profile, isLoading, user, switchToView } = props;

  const saveImage = async (image) => {
    return handleSaveImage(image);
  };

  const validationSchema = yup.object({
    firstName: yup
      .string('First Name is required')
      .required('First Name is required'),
    lastName: yup
      .string('Last Name is required')
      .required('Last Name is required'),
    injuryType: yup
      .string('Injury Type is required')
      .required('Injury Type is required'),
    activityType: yup
      .array()
      .test({
        message: 'Maximum number of activities is 5',
        test: arr => arr.length < 6
      }),
    dateOfBirth: yup
      .string('Date Of Birth is required')
      .required('Date Of Birth must be a valid date')
      .nullable()
      .test(
        'Date Of Birth',
        `Users must be ${MIN_AGE} or older to use Brace.`,
        (date) => moment().diff(moment(date), 'years') >= MIN_AGE
      )
      
  });

  const formik = useFormik({
    validateOnMount: true,
    initialValues:{
      firstName: profile.firstName ?? '',
      lastName: profile.lastName ?? '',
      dateOfBirth: user?.dateOfBirth ? moment(user?.dateOfBirth) : null,
      country: profile.country,
      injuryType: profile.injury?.type ?? '',
      activityType: profile.injury?.activityType?.split(',').filter(a => !isNullOrEmptyOrBlack(a)) ?? [],
      bio: profile.bio ?? ''
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      var updatedUser = await api.put({ url: 'users', getUserToken, data: {
        country: values.country,
        firstName: values.firstName,
        lastName: values.lastName,
        bio: values.bio,
        injury: {
          activityType: values.activityType.join(','),
          type: values.injuryType
        }
      } });
      setSubmitting(false);
      handleUpdate(updatedUser);
      switchToView();
    }
  });

  return (
    <Box sx={{ borderRadius: 1 }} >
      <Box flex
        center='h'
        fullWidth>
        <Box p={2}>
          <ImageUploader isEnabled={true} saveImage={saveImage} imageId='profile-screen'>
            <UserAvatar size={68}
              isEdit={true}
              user={user}
              profile={profile}
              isLoading={isLoading}/>
          </ImageUploader>
        </Box>

        <Box fullWidth p={2}>
          <Stack spacing={1}>
            <TextField label='First Name'
              placeholder='Enter your first name'
              autoComplete='given-name'
              id='firstName'
              inputProps={{ 'data-testid': 'profile-edit-text-first-name' }}
              required
              onChange={formik.handleChange}
              value={formik.values.firstName}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextField label='Last Name'
              placeholder='Enter your last name'
              autoComplete='family-name'
              id='lastName'
              inputProps={{ 'data-testid': 'profile-edit-text-last-name' }}
              required
              onChange={formik.handleChange}
              value={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <TextField label='Email'
              disabled
              value={user.email}
            />
            <CountrySelect
              autoComplete='country'
              label='Location' 
              fullWidth={true}
              placeholder='Select your location'
              onChange={(value) => {formik.setFieldValue('country', value);}}
              value={formik.values.country}
              touched={formik.touched.country}
              error={formik.errors.country}/>
            <DatePicker 
              disableFuture
              required
              dataTestId='profile-edit-text-date-of-birth'
              label='Date of Birth'  
              id='dateOfBirth'
              autoComplete='bday'
              value={formik.values.dateOfBirth}
              error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
              helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
              onBlur={() => {
                formik.setFieldTouched('dateOfBirth', true);
              }}
              onChange={(value) => {
                formik.setFieldValue('dateOfBirth', value ?  value.toISOString() : null, true);
              }}
            />  
            <TextField 
              label='What injury do you have?'
              placeholder='Eg. Ruptured ACL'
              inputProps={{ 'data-testid': 'index-sign-up-text-injury' }}
              id='injuryType'
              required
              onChange={formik.handleChange}
              value={formik.values.injuryType}
              error={formik.touched.injuryType && Boolean(formik.errors.injuryType)}
              helperText={formik.touched.injuryType && formik.errors.injuryType}
            />
            <Select
              label='What activity do you take part in?'
              placeholder='Eg. Football'
              options={ACTIVITY_TYPE_OPTIONS}
              onChange={(value) => {
                formik.setFieldValue('activityType', value);
                formik.setFieldTouched('activityType', true);
              }}
              value={formik.values.activityType}
              error={formik.touched.activityType && Boolean(formik.errors.activityType)}
              helperText={formik.touched.activityType && formik.errors.activityType}
              onBlur={() => {
                formik.setFieldTouched('activityType', true);
              }}
            />
            <TextField 
              label='Bio'
              placeholder='I’m a 800m runner from Dublin...'
              id='bio'
              inputProps={{ 'data-testid': 'profile-edit-text-bio' }}
              multiline
              rows={4}
              onChange={formik.handleChange}
              value={formik.values.bio}
              error={formik.touched.bio && Boolean(formik.errors.bio)}
              helperText={formik.touched.bio && formik.errors.bio}
            />
          </Stack>
          <Button 
            data-testid='profile-edit-save-button'
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={() => formik.handleSubmit()}
            variant='contained'
            size='large'
            color='primary'
            isLoading={formik.isSubmitting}
            isLoadable={true}
          >
              Save Changes
          </Button>
        </Box>

      </Box>
    </Box>
  );
}

