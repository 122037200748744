import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { closeModal } from './../../actions/modal';

import { Icon } from '@components/atoms';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppModal(props) {
  var dispatch = useDispatch();
  var modalState = useSelector(state => state.modal);

  const close = () => {
    dispatch(closeModal());
  };

  return (
    <div>
      <Dialog
        open={modalState.isOpen}
        TransitionComponent={Transition}
        onClose={close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ width:'30px' }}>

          </Box>
          <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'center' }}>
            {modalState.title}
          </Typography>
          <IconButton onClick={close}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <DialogContent>
          <Box width="100%"
            display='flex'
            justifyContent='center'
            direction='column'>
            <Box>
              <Icon type={modalState.icon ?? 'strength'} delay="0" trigger='none'/>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            {modalState.message}
          </Box>
        </DialogContent>
        <DialogActions>
          {modalState.action &&
             <Button 
               sx={{ width: '100%' }}
             
               color='primary'
               variant='contained'
               data-testid='success-modal-action-button'
               onClick={ modalState.action.onClick }>{modalState.action.text}
             </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}