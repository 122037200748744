import React, { useContext, useEffect, useState } from 'react';

import { Page } from '@components/v2/organisms';
import {  Box, Card } from '@components/v2/atoms';
import { UserContext, ServicesContext, EventsContext } from '@context';
import { useTheme } from '@emotion/react';
import ProfileView from './components/view';
import ProfileEdit from './components/edit';

export default function Index(){
  const theme = useTheme();
  const { user, getUserToken } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { api } = useContext(ServicesContext);
  const [ profile, setProfile ] = useState(null);
  const [ isLoadingProfile, setIsLoadingProfile ] = useState(true);
  const [ isEditing, setIsEditing ] = useState(false);

  const loadProfile = async () => {
    setIsLoadingProfile(true);
    var result = await api.get({ url: `profiles/${user.id}`, getUserToken });
    setProfile(result);
    setIsLoadingProfile(false);
  };

  useEffect(() => {
    if(user?.id){
      trackEvent('Profile:Edit:Open');
      loadProfile();
    }
  }, [user]);

  return (
    <Page title='Profile Info' includeBackButton={true}>
      <Box sx={{ height: '100%', overflow: 'scroll', paddingBottom: 1 }}>
        <Box sx={{ 
          height: '30px', 
          background: theme.palette.primary.main
        }}>
        </Box>
        <Box sx={{ marginTop: '-25px', display:'flex', justifyContent: 'center', px: 2.5 }}>
          <Card sx={{ width: '100%' }} >
            {!isEditing &&
              <ProfileView user={user}
                profile={profile}
                isLoading={isLoadingProfile}
                switchToEdit={() => setIsEditing(true)}/>
            }
            {(isEditing && !isLoadingProfile) &&
              <ProfileEdit user={user}
                profile={profile}
                isLoading={isLoadingProfile}
                switchToView={() => setIsEditing(false)}/>
            }
          </Card>
        </Box>
      </Box>
    </Page>
  );
}

