import config from '../../config';

const doRequest = (httpMethod, entity, url, resolve, reject, token, isJson = true) => {
  let xhr = new XMLHttpRequest();
  xhr.open(httpMethod, url);

  if(isJson)
    xhr.setRequestHeader('Content-type', 'application/json');

  if(token)
    xhr.setRequestHeader('Authorization', token);

  xhr.onload = () => {
    if (xhr.status >= 200 && xhr.status < 300) {
      if(xhr.response)
        resolve(JSON.parse(xhr.response));
      else
        resolve();
    } else if(xhr.status === 401){
      reject({
        errors:'Un-Authorized',
        statusCode:xhr.status
      });
    } else {
      var response = JSON.parse(xhr.response);
      reject({
        errors:response.errors,
        message:response.message,
        statusCode:xhr.status
      });
    }
  };
  xhr.onerror = () => {
    reject(xhr.statusText);
  };

  xhr.ontimeout = function (e) {
    reject('timeout');
  };

  if(entity)
    xhr.send(isJson ? JSON.stringify(entity) : entity);
  else
    xhr.send();
};

export default class CommunityApiClient {
  static constitute () { }
  constructor() {
    this.baseUrl = config.apiBaseUrl;
  }
  searchGroups({ user }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}community/groups/search`, resolve, reject, user.token);
    });
  }
  createTopic({ user, groupId, name, description, image }){

    const formData = new FormData();
    formData.append('groupId', groupId);
    formData.append('name', name);
    formData.append('description', description);

    if(image){
      formData.append('image', image);
    }

    return new Promise((resolve, reject) => {
      doRequest('POST', formData, `${this.baseUrl}community/topics`, resolve, reject, user.token, false);
    });
  }
  searchTopics({ user, groupId, sortBy }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}community/topics/search?groupId=${groupId}&sortBy=${sortBy}`, resolve, reject, user.token);
    });
  }
  getTopic({ user, topicId }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}community/topics/${topicId}`, resolve, reject, user.token);
    });
  }
  getTopicComments({ user, topicId }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}community/topics/${topicId}/comments`, resolve, reject, user.token);
    });
  }
  saveTopicComment({ user, topicId, message, parentCommentId }){
    return new Promise((resolve, reject) => {
      doRequest('POST', { parentCommentId, message }, `${this.baseUrl}community/topics/${topicId}/comments`, resolve, reject, user.token);
    });
  }
  likeTopic({ user, topicId }){
    return new Promise((resolve, reject) => {
      doRequest('PUT', null, `${this.baseUrl}community/topics/${topicId}/like`, resolve, reject, user.token);
    });
  }
  likeComment({ user, commentId }){
    return new Promise((resolve, reject) => {
      doRequest('PUT', null, `${this.baseUrl}community/comments/${commentId}/like`, resolve, reject, user.token);
    });
  }
  savePost({ user, message, image, organisation }){

    const formData = new FormData();
    formData.append('message', message);

    if(image){
      formData.append('image', image);
    }

    if(organisation){
      formData.append('organisationId', organisation.id);
    }

    return new Promise((resolve, reject) => {
      doRequest('POST', formData, `${this.baseUrl}community/posts`, resolve, reject, user.token, false);
    });
  }
  getPost({ user, id }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}community/posts/${id}`, resolve, reject, user.token);
    });
  }
  savePostComment({ user, postId, message, parentCommentId }){
    return new Promise((resolve, reject) => {
      doRequest('POST', { parentCommentId, message }, `${this.baseUrl}community/posts/${postId}/comments`, resolve, reject, user.token);
    });
  }
  getPostComments({ user, postId }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}community/posts/${postId}/comments`, resolve, reject, user.token);
    });
  }
  searchPosts({
    user,
    userId,
    searchTerm,
    createdAtLimit,
    organisation,
    team,
    page = 0
  }){

    var url = `${this.baseUrl}community/posts/search?page=${page}&createdAtLimit=${createdAtLimit}&searchTerm=${searchTerm}`;

    if(organisation?.id){
      url += `&organisationId=${organisation.id}`;
    }

    if(team?.id){
      url += `&teamId=${team.id}`;
    }

    if(userId){
      url = `${url}&userId=${userId}`;
    }

    return new Promise((resolve, reject) => {
      doRequest('GET', null, url, resolve, reject, user.token);
    });
  }
  likePost({ user, postId }){
    return new Promise((resolve, reject) => {
      doRequest('PUT', null, `${this.baseUrl}community/posts/${postId}/like`, resolve, reject, user.token);
    });
  }
  getLikes({ user, parentId }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}community/likes?parentId=${parentId}`, resolve, reject, user.token);
    });
  }
  saveDiaryEntry({ user, message, mood, energy, pain, sleep, date, image }){

    const formData = new FormData();
    formData.append('message', message);
    formData.append('mood', mood);
    formData.append('energy', energy);
    formData.append('pain', pain);
    formData.append('sleep', sleep);
    formData.append('date', date);

    if(image){
      formData.append('image', image);
    }

    return new Promise((resolve, reject) => {
      doRequest('POST', formData, `${this.baseUrl}community/diary`, resolve, reject, user.token, false);
    });
  }
  updateDiaryEntry({ id, user, message, mood, energy, pain, sleep, image }){
    const formData = new FormData();
    formData.append('message', message);
    formData.append('mood', mood);
    formData.append('energy', energy);
    formData.append('pain', pain);
    formData.append('sleep', sleep);

    if(image){
      formData.append('image', image);
    }

    return new Promise((resolve, reject) => {
      doRequest('PUT', formData, `${this.baseUrl}community/diary/${id}`, resolve, reject, user.token, false);
    });
  }
  searchDiaryEntries({ user }){
    return new Promise((resolve, reject) => {
      doRequest('GET', null, `${this.baseUrl}community/diary/search`, resolve, reject, user.token, false);
    });
  }
  
}