import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { 
  Box,
  Typography
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Icon, Card, Divider, LoadableList, Button } from '@components/v2/atoms';

import { ServicesContext, UserContext } from '@context';
import { sortByUpdatedAt } from '@helpers/sorter';
import { LoadableText } from '@components/atoms';
import { firstName } from '@helpers/text';

export default function CommunityFeed(props){
  const COMMUNITY_SECTION_HEIGHT_IN_PX = 48;
  const { height } = props;
  var history = useHistory();
  var { user, getUserToken } = useContext(UserContext);
  var { api } = useContext(ServicesContext);
  const [isLoading, setIsLoading] = useState(true);
  var [ numberOfRows, setNumberOfRows ] = useState(2);
  const [feed, setFeed] = useState([]);

  const loadFeed = async () => {
    setIsLoading(true);
    var result = await api.get({ url: 'community/feed', getUserToken });
    setFeed(sortByUpdatedAt(result.results));
    setIsLoading(false);
    
  };

  useEffect(() => {
    if(user?.id){
      loadFeed();
    }
  }, [user]);

  useEffect(() => {
    if(height > 0){
      // Minus Heading and Padding
      setNumberOfRows((height - 26 - 16) / COMMUNITY_SECTION_HEIGHT_IN_PX);
    }
  }, [height]);

  const getIcon = (feedResult) => {
    var iconType = '';
    if(feedResult.type === 'COMMENT')
      iconType = 'comment-plus';
    if(feedResult.type === 'LIKE')
      iconType = 'thumb-up';
    if(feedResult.type === 'POST')
      iconType = 'conversation';

    return <Icon 
      isLoading={isLoading} 
      type={iconType} 
      size={20}
      stroke='#fff'
    />;
  };

  const FeedRow = (props) => {
    const { feedResult } = props;

    const handleClick = () => {
      if(feedResult.type == 'POST'){
        if(feedResult.object.metadata?.type == 'NEW_TOPIC'){
          history.push(`community?topicId=${feedResult.object.metadata.topicId}`);
        }else{
          history.push(`community?checkInId=${feedResult.id}`);
        }
      }
      if(feedResult.type == 'COMMENT'){
        history.push(`community?checkInId=${feedResult.object.parentId}`);
      }
      if(feedResult.type == 'LIKE'){
        var postId = null;
        if(feedResult.object.parentType == 'COMMENT'){
          postId = feed.filter(fr => fr.id == feedResult.object.parentId)[0].object.parentId;
        }else{
          postId = feedResult.object.parentId;
        }
        history.push(`community?checkInId=${postId}`);
      }
    };

    var message = <></>;

    switch(feedResult.type){
    case 'COMMENT':
      message = <>{`${firstName(feedResult.object?.user?.name)} just commented on a post!`}</>;
      break;
    case 'LIKE':
      message = <>{`${firstName(feedResult.object?.user?.name)} just liked a post!`}</>;
      break;
    case 'POST':
      if(feedResult.object.metadata?.type == 'NEW_TOPIC'){
        message = <>{`${firstName(feedResult.object?.user?.name)} just created a new discussion!`}</>;
      }else{
        message = <>{`${firstName(feedResult.object?.user?.name)} just posted in the community!`}</>;
      }
      break;

    }

    return (
      <Box sx={{ p: 1.5, display: 'flex', alignItems: 'center' }} onClick={handleClick}>
        <Box sx={{ lineHeight: 1 }}>{getIcon(feedResult)}</Box>
        <Box sx={{ paddingLeft: 1, flexGrow: 1 }}>
          <LoadableText isLoading={isLoading} text={message}/>
        </Box>
        <ChevronRightIcon color='action'/>
      </Box>
    );
  };

  return (
    <Box p={2.5} paddingTop={2}  sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '100%' }}>
      <Box sx={{ display : 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 0.5 }}>
        <Typography onClick={() => history.push('/community')} variant='h6' sx={{ flexGrow: 1, fontWeight:'medium', color: '#0D0D12' }}>
          Community
        </Typography>
        <Button size='small' endIcon={<ChevronRightIcon />} onClick={() => history.push('/community')}>
          View All
        </Button>
      </Box>
      <Box id='feed-container' sx={{ flexGrow: 1 }}>
        <Card>
          <LoadableList 
            divider={<Divider/>}
            numberOfRows={2}
            isLoading={isLoading}
            rows={feed.slice(0, numberOfRows)}
            getKey={(feedResult) => feedResult?.id}
            getRow={(feedResult) => <FeedRow feedResult={feedResult} /> } />
        </Card>
      </Box>
    </Box>
      
  );
}

