import React, { useContext, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { 
  Box,
  Stack,
  Typography
} from '@mui/material';

import { loadUser } from '../../actions/users';

import { Page } from '@components/v2/organisms';
import Notifications from './components/notifications';
import Subscription from './components/subscription';
import Account from './components/account';
import { BillingContext } from '@context/billing';

import config from '@config';

export default function Index(){
  var dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const { isAvailable } = useContext(BillingContext);
  useEffect(() => {
    dispatch(loadUser())
      .then((result) => {
        setUser(result);
      });
  }, []);

  return (
    <Page title='Account Settings'>
      <Box sx={{ height: 'calc(100vh - 56px - 56px - env(safe-area-inset-top) - env(safe-area-inset-bottom))' }}>
        <Stack spacing={2} sx={{ p: 1 }}>
          {isAvailable &&
            <Subscription/>
          }
          <Notifications user={user}/>
          <Account />
          <Box>
            <Typography variant='caption' color='text.disabled'>
              {`v${config.version}`}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Page>
  );
}

