import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { 
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Divider
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SchoolIcon from '@mui/icons-material/School';

import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '@context';
import OnboardingBanner from '../organisms/onboarding-banner';
import useDetectKeyboardOpen from '@helpers/device';

const useStyles = makeStyles((theme) => ({
  root: {
    width:'100%',
    position: 'fixed',
    zIndex: 1000,
    bottom: 0,
    background: '#fff',
    paddingBottom: 'env(safe-area-inset-bottom)'
  },
  nav: {
    width:'100%'
  },
  action: {
    minWidth:'40px'
  },
  active: {
    color:theme.palette.primary.main
  }
}));

const NavMenuIcon = (props) => {
  return (
    <BottomNavigationAction 
      data-testid={props.dataTestId}
      component={Link}
      to={props.to}
      label={props.label}
      icon={props.icon} 
      showLabel
      sx={{ 
        minWidth: 0,
        width: '20%',
        color: props.isActive ? 'primary.main' : 'default',
        fontSize: '8px'
      }} />
  );
};

export default function Navigation(props) {
  const location = useLocation();
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [value, setValue] = React.useState(location.pathname);

  const isActive = (expectedPath) => {
    return value && 
      value.includes &&
      value.includes(expectedPath);
  };

  return (
    <Box className={classes.root} width='100%'>
      {props.showOnboardingBanner &&
        <OnboardingBanner />
      } 
      {!isKeyboardOpen &&
        <>
          <Divider />
          <Box display='flex' justifyContent="center" >
            <BottomNavigation value={value}
              showLabels
              className={classes.nav}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}>
              {(!user?.role || user?.role === 'CLIENT') &&
                <NavMenuIcon isActive={isActive('learn')}
                  dataTestId='bottom-navigation-menu-button-trophies'
                  to='/learn'
                  label="Learn"
                  icon={<SchoolIcon />}/>
              }
              <NavMenuIcon isActive={isActive('community')}
                dataTestId='bottom-navigation-menu-button-community'
                to='/community'
                label="Community"
                icon={<PeopleAltIcon />}/>
              <NavMenuIcon 
                isActive={isActive('home')}
                dataTestId='bottom-navigation-menu-button-home'
                to='/home'
                label="Home"
                icon={<HomeIcon />}/>
              <NavMenuIcon isActive={isActive('recovery')}
                dataTestId='bottom-navigation-menu-button-recovery'
                to={'/recovery'}
                label="Recovery"
                icon={<FitnessCenterIcon />}/>
              {(!user?.role || user?.role === 'CLIENT') &&
                <NavMenuIcon isActive={isActive('/diary')}
                  dataTestId='bottom-navigation-menu-button-diary'
                  to='/diary'
                  label="Diary"
                  icon={<HistoryEduIcon />} />
              }
            </BottomNavigation>
          </Box>
        </>
      }
    </Box>
  );
}

