import moment from 'moment';
import { create } from 'zustand';

const useDiaryEntriesStore = create((set, get) => ({
  hasLoaded: false,
  isLoading: false,
  diaryEntries: [],
  diaryStreak: 0,
  activeDay: moment(),
  activeDayDiaryEntry: null,
  setActiveDay: async (newActiveDay, api, getUserToken) => {
    set({ isLoading: true, activeDay: newActiveDay });
    var result = await api.get({ url: 'community/diary/search',  getUserToken });
    var results = result.results;

    var activeDayDiaryEntry = results.filter(d => 
      moment(d.createdAt).isAfter(newActiveDay.clone().startOf('day')) &&
      moment(d.createdAt).isBefore(newActiveDay.clone().add(1, 'day').startOf('day'))
    )[0];
    var streak = 1;
    var hasDiaryOnDay = true;
  
    do {
      hasDiaryOnDay = results.filter(d => 
        newActiveDay.clone().add(-(streak), 'days').startOf('day').isBefore(moment(d.createdAt)) && 
        newActiveDay.clone().add(-(streak - 1), 'days').startOf('day').isAfter(moment(d.createdAt))
      ).length > 0;
      if(hasDiaryOnDay)
        streak++;
    } while (hasDiaryOnDay);

    if(!activeDayDiaryEntry){
      streak -= 1;
    }

    set({ 
      isLoading: false, 
      hasLoaded: true, 
      diaryEntries: results,
      diaryStreak: streak,
      activeDayDiaryEntry
    });
  }
}));

export default useDiaryEntriesStore;