import React, { useContext } from 'react';

import { 
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Image from 'mui-image';
import { useTheme } from '@emotion/react';
import { Box, Button, Icon, IconButton } from '@components/v2/atoms';
import { OnboardingContext } from '../context';

export default function OnboardingPage(props){
  const { children, includeHeader = false, isNextDisabled, sx, nextOverride, nextButtonText, backOverride, isLoadingOverride, shouldAppearBehindStatusBar = false } = props;
  const { next, back, logout, isLoading } = useContext(OnboardingContext);
  const theme = useTheme();

  var doBack = backOverride ? backOverride : back;
  var doNext = nextOverride ? nextOverride : next;
  return (
    <Box flexColumn
      sx={{ 
        ...sx,
        marginTop: 'calc(0px - env(safe-area-inset-top))',
        paddingTop: shouldAppearBehindStatusBar ? 0 : 'calc(env(safe-area-inset-top))',
        height:'calc(100vh - env(safe-area-inset-bottom))'
      }}>
      {includeHeader &&
        <Box flex sx={{ background: theme.palette.primary.main, p: 3, width: '100%' }}>
          <Box center='vertical' sx={{ width: '20%' }}>
            {doBack &&
              <IconButton 
                variant='rounded-outlined' 
                onClick={doBack}
              >
                <Icon 
                  size={20}
                  type={'back'}
                  fill='rgba(255, 255, 255, 0.12)'
                  stroke={theme.palette.braceGreenDark.contrastText}
                />
              </IconButton>
            }
          </Box>
          <Box sx={{ width: '60%', display: 'flex', justifyContent: 'center', py: 1 }}>
            <Image 
              duration={0} 
              width='133px' 
              src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_White%20and%20grad.png'/>
          </Box>
        </Box>
      }
      <Box flexColumn sx={{ flexGrow: 1 }}>
        {children}
      </Box>
      <Box fullWidth sx={{ paddingBottom: 2, px: 2 }}>
        <Button variant='contained'
          size='veryLarge'
          fullWidth
          sx={{ marginBottom: 1 }}
          onClick={doNext}
          disabled={isNextDisabled}
          isLoadable
          isLoading={isLoading || isLoadingOverride}
          data-testid='onboarding-next-button'
          endIcon={<ChevronRightIcon />}>
          {nextButtonText ?? 'Continue'}
        </Button>
        {logout &&
          <Button variant='outlined'
            size='veryLarge'
            color='negative'
            fullWidth
            onClick={logout}>
              Log Out
          </Button>
        }
        {(!logout && backOverride) &&
          <Button variant='outlined'
            size='veryLarge'
            color='negative'
            fullWidth
            onClick={backOverride}>
              Back
          </Button>
        }
      </Box>
    </Box>
  );
}