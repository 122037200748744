import React, { useState } from 'react';

import { MobileStepper, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Box, Button } from '@components/v2/atoms';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Image from 'mui-image';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const steps =  [
  {
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding_step_3_mock_up_1.png'
  },
  {
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding_step_3_mock_up_2.png'
  },
  {
    image: 'https://bracesocialprod.blob.core.windows.net/static/onboarding_step_3_mock_up_3.png'
  }
];

export default function DropOff(props){
  const { confirmButtonText, confirmButtonAction, cancelButtonText, cancelButtonAction } = props;
  const [activeStep, setActiveStep] = useState(0);
  var theme = useTheme();

  const handleSwipe = (s) => {
    setActiveStep(s);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          autoplay={false}
          interval={10000}
          onChangeIndex={handleSwipe}
          enableMouseEvents
          style={{ width: '100vw' }}
          containerStyle={{  width: '100%', height: '100%', px: 2 } }
        >
          {steps.map((step, index) => (
            <Box key={index} sx={{  width: '100%', height: '100%' }}>
              <Stack sx={{
                display: 'flex',
                flexDirection: 'column',
                color: 'braceGreenDark.main',
                fontWeight: 'medium',
                height: '100%' }}>
                <Box flex
                  center='h'
                  sx={{ 
                    paddingTop: 'calc(env(safe-area-inset-top) + 4px)',
                    background: `radial-gradient(${theme.palette.secondary.main}, ${theme.palette.primary.main})`, 
                    width: '100%' }}>
                  <Image 
                    duration={100} 
                    style={{ zIndex: 2 }}
                    width='75vw'
                    height='calc(75vw * 1.61)'
                    src={step.image}/>
                </Box>
                <Box sx={{ position: 'relative', zIndex: 3 , width: '100%', height: '77px', marginTop:'-75px', backgroundImage: 'linear-gradient(transparent, #fafafa, #fafafa)' }}>
                </Box>
                <Box sx={{ position: 'relative', zIndex: 4 , width: '100%', marginTop:'-25px' }}>
                  <MobileStepper
                    sx={{ 
                      py:1, 
                      backgroundColor: '#fafafa', 
                      justifyContent: 'center', 
                      '.MuiMobileStepper-dotActive' : {
                        backgroundColor: theme.palette.primary.main
                      } }}
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                  />
                </Box>
              </Stack>
            </Box>
          ))}
        </AutoPlaySwipeableViews>
        <Box sx={{ px: 2, py: 2, textAlign: 'center', color: theme.palette.primary.main }}>
          <Typography variant='h3'fontWeight={500}>{'Recovery Is Tough'}</Typography>
        </Box>
        <Box sx={{ px: 2, paddingBottom: 1, textAlign: 'center', color: theme.palette.primary.main }}>
          <Typography variant='body1' color='text.secondary'>
            Brace is empowering our community with the physical and mental health supports to come back stronger, together.
          </Typography>
        </Box>
      </Box>
     
      <Box sx={{ px: 2, paddingBottom: 3, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button 
          sx={{ py: 1, px: 4, marginBottom: 1, fontSize: 'h6.fontSize' }}
          size='large'
          variant='contained'
          color='braceGreenDark'
          fullWidth={true}
          onClick={confirmButtonAction}
        >
          {confirmButtonText}
        </Button>
        <Button 
          sx={{ py: 1, px: 4, fontSize: 'h6.fontSize' }}
          size='large'
          variant='outlined'
          color='negative'
          fullWidth={true}
          onClick={cancelButtonAction}
        >
          {cancelButtonText}
        </Button>
      </Box>
    </>
  );
}