export const SET_DIARY_ENTRIES = 'SET_DIARY_ENTRIES';

export const loadDiary = () => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.communityApiClient.searchDiaryEntries({ user })
      .then(response => {
        dispatch({ type: SET_DIARY_ENTRIES, results: response.results });
        return response.results;
      });
  };
};

export const createDiaryEntry = (message, mood, energy, pain, sleep, date, image) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.communityApiClient.saveDiaryEntry({ user, message, mood, energy, pain, sleep, date, image })
      .then(response => {
        dispatch(loadDiary());
        return response;
      });
  };
};

export const updateDiaryEntry = (id, message, mood, energy, pain, sleep, image) => {
  return(dispatch, getState, services) => {
    var user = getState().user;

    return services.communityApiClient.updateDiaryEntry({ id, user, message, mood, energy, pain, sleep, image })
      .then(response => {
        dispatch(loadDiary());
        return response;
      });
  };
};

