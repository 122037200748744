import React, { useContext, useEffect } from 'react';

import { 
  Box
} from '@mui/material';

import { RecoveryContext } from '@context';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { UserContext } from '@context/user';
import ToDoItem from './to-do-item';

export default function SessionsToDo(){
  const { user } = useContext(UserContext);
  var history = useHistory();
  const { moveActiveDay, activeDaysSessions, isLoadingActiveDaysSessions } = useContext(RecoveryContext);
  const today = moment();

  useEffect(() => {
    if(user?.id){
      moveActiveDay(today);
    }
  }, [user]);

  var toDoSessions = activeDaysSessions.filter(s => !s.plan.isComplete);

  var sessionPlanList = toDoSessions.length > 2 ? 
    toDoSessions.slice(0, 2).map(s => (<Box key={s.id}>{`${s.plan.planName}${s.program?.name ? ' - ' + s.program?.name : ''}`}</Box>))
    : toDoSessions.map(s => (<Box key={s.id}>{`${s.plan.planName}${s.program?.name ? ' - ' + s.program?.name : ''}`}</Box>));

  if(toDoSessions.length > 2){
    sessionPlanList.push(<Box key={'XMore'}>{`+ ${toDoSessions.length - 2} More`}</Box>);
  }

  // <ToDoItem 
  // isLoading={isLoadingDiaryEntries}
  // onClick={() => setIsCreateDiaryOpen(true)}
  // iconType='diary'
  // title= {`You are ${moodToText(todaysDiaryEntry.mood)} Today${todaysDiaryEntry.mood >= 4 ? '!' : ''}`}
  // message={diaryStreak > 0 ? 
  //   `You are on a ${diaryStreak} day streak!` : 
  //   ''
  // }

  return (
    <Box>
      <Box>
        <Box>
          {toDoSessions.length > 0 && 
            <ToDoItem 
              isLoading={isLoadingActiveDaysSessions}
              onClick={() => history.push('/recovery')}
              iconType='stretching-person'
              title={`${toDoSessions.length} Session${toDoSessions.length > 1 ? 's' : ''} to Complete`}
              message={sessionPlanList}
            />
          }
          {toDoSessions.length === 0 && 
            <ToDoItem 
              isLoading={isLoadingActiveDaysSessions}
              onClick={() => history.push('/recovery')}
              iconType='stretching-person'
              title={'No Sessions'}
              message={'Add a new session?'}
            />
          }
        
        </Box>

      </Box>
    </Box>
  );
}

