import React, { useContext, useEffect, useState } from 'react';

import { 
  IconButton, 
  Box, 
  Typography,
  Tab,
  Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Page } from '@components/v2/organisms';
import { UserContext, ServicesContext, EventsContext } from '@context';
import { useTheme } from '@emotion/react';
import { useParams } from 'react-router-dom';
import SubPage from './../../../../components/page/sub-page';
import { ConfirmationModal, Tabs, a11yProps, TabPanel } from '@components/molecules';
import CreateProgramDialog from '../new/create-program-dialog';
import { DaysOfWeekPicker } from '@components/molecules';
import { LoadableText } from '@components/atoms';
import { toShortDateString } from '@helpers/dates';
import ProgramMetrics from './metrics';
import { useHistory } from 'react-router-dom';
import { RecoveryContext } from '@context/recovery';

export default function ViewProgram(){
  let { id } = useParams();
  var theme = useTheme();
  const history = useHistory();
  const { trackEvent } = useContext(EventsContext);
  const { user, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [program, setProgram] = useState(null);
  const [isLoadingProgram, setIsLoadingProgram] = useState(false);
  const [isEditProgramOpen, setIsEditProgramOpen] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [isLoadingSessions, setIsLoadingSessions] = useState(false);
  const { loadPrograms, loadDailySessionStats } = useContext(RecoveryContext);
  const [shouldShowDeleteProgramConfirmationModal, setShouldShowDeleteProgramConfirmationModal] = useState(false);

  const deleteProgram = async () => {
    setIsLoadingProgram(true);
    await api.delete({ url: `recovery/programs/${id}`, getUserToken });
    loadPrograms(true);
    setIsLoadingProgram(false);
  };

  const loadProgram = async () => {
    setIsLoadingProgram(true);
    var result = await api.get({ url: `recovery/programs/${id}`, getUserToken });
    setProgram(result);
    setIsLoadingProgram(false);
  };

  const loadSessions = async () => {
    setIsLoadingSessions(true);
    var result = await api.get({ url: 'recovery/sessions', params: { programId: id },  getUserToken });
    setSessions(result.results);
    setIsLoadingSessions(false);
  };

  useEffect(() => {
    if(user){
      trackEvent('Recovery:Programs:View');
      loadProgram();
      loadSessions();
    }
  }, [user]);

  return (
    <Page>
      <SubPage 
        title={program?.name}
        isBackButtonEnabled={true}
        endButton={
          <Box sx={{ display: 'flex', marginRight: 0.5 }}>
            <IconButton
              sx={{ px: 0.5 }}
              onClick={() => setIsEditProgramOpen(true)}
              disabled={isLoadingProgram}
              data-testid='recovery-programs-edit-button'>
              <EditIcon  style={{ fontSize: 28 }}/>
            </IconButton>
            <IconButton
              sx={{ px: 0.5 }}
              onClick={() => setShouldShowDeleteProgramConfirmationModal(true)}
              disabled={isLoadingProgram}
              data-testid='recovery-programs-create-button'>
              <DeleteIcon  style={{ fontSize: 28 }}/>
            </IconButton>
          </Box>
        }
      >
        <Box>
          <Box sx={{ p: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ paddingBottom: 1 }}>
                <Box sx={{ }}>
                  <Typography variant='caption' color='text.secondary' gutterBottom>Duration</Typography>
                </Box>
                <LoadableText isLoading={isLoadingProgram} text={`${toShortDateString(program?.startDate)} - ${toShortDateString(program?.endDate)}`} />
              </Box>
              <Box sx={{ paddingBottom: 1 }}>
                <Box sx={{ }}>
                  <Typography variant='caption' color='text.secondary' gutterBottom>Sessions Per Day</Typography>
                </Box>
                <LoadableText isLoading={isLoadingProgram} text={`${program?.numberOfTimesPerDay ?? 1}`} />
              </Box>
            </Box>
            <Box sx={{ paddingBottom: 1 }}>
              <Box>
                <Typography variant='caption' color='text.secondary' gutterBottom>Days</Typography>
              </Box>
              <Box sx={{ paddingTop: 0.5 }}>
                <DaysOfWeekPicker isDisabled={true} values={program?.daysOfWeek ?? []} onChange={() => {}}/>
              </Box>
            </Box>
            <Box>
              {program?.notes &&
              <>
                <Typography variant='caption' color='text.secondary' gutterBottom>Notes</Typography>
                <Typography component='div'
                  sx={{ pre: { 
                    fontSize: 'inherit',
                    color: 'inherit',
                    border: 'initial',
                    padding: 'initial',
                    fontFamily: 'inherit',
                    margin: 0
                  } }  }>
                  <pre>
                    <LoadableText 
                      isLoading={isLoadingProgram} 
                      text={program?.notes}  
                      sx={{
                        overflowX: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                      }}  />
                  </pre>
                </Typography>
              </>
              }
            </Box>
          </Box>
          <Tabs   
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            value={0} 
            onChange={(event, newValue) => {}} 
            aria-label="basic tabs example">
            <Tab label="Metrics" {...a11yProps(0)} />
          </Tabs>
          <Divider />
          <TabPanel value={0} index={0} dir={theme.direction}>
            <ProgramMetrics program={program} isLoading={isLoadingProgram || isLoadingSessions} sessions={sessions} />
          </TabPanel>
        </Box>
        
      </SubPage>
      <CreateProgramDialog 
        programBeingEdited={isEditProgramOpen ? program : null}
        isOpen={isEditProgramOpen}
        close={(shouldRefresh) => {
          setIsEditProgramOpen(false);
          if(shouldRefresh){
            loadProgram();
            loadPrograms(true);
            loadDailySessionStats(true);
          }
        }}
      />
      <ConfirmationModal 
        confirmDataTestId='recovery-view-program-delete-confirmation-modal-button'
        isOpen={shouldShowDeleteProgramConfirmationModal} 
        title='Delete Program?' 
        message={'Are you sure you want to delete this program and all recovery sessions?'}
        cancelText={'Cancel'}
        handleClose={() => {
          setShouldShowDeleteProgramConfirmationModal(false);
          history.goBack();
        }}
        isDeleteAction='true'
        confirmText={'Delete'}
        confirmationSuccessMessage={`Program "${program?.name}" was successfully deleted."`}
        handleConfirmation={() => deleteProgram()} />
    </Page>
  );
}

