import React, { useState, useEffect, useContext, useRef } from 'react';

import {
  Dialog,
  Box,
  Divider,
  Typography,
  TextField,
  Chip
}from '@mui/material';
import { styled } from '@mui/material/styles';

import { 
  LocalizationProvider,
  DatePicker
} from '@mui/lab';
import PickersDay from '@mui/lab/PickersDay';
import AdapterMoment from '@mui/lab/AdapterMoment';

import { DialogHeader, DialogTransition } from '@components/atoms';
import Buttons from './buttons';
import { UserContext, ServicesContext } from '@context';
import { EventsContext } from '@context/events';
import { SelectPlan } from '@components/organisms';
import { NewPlanDialog } from '@components/templates';
import { usePrevious } from '@helpers/hooks';
import { RecoveryContext } from '@context/recovery';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <DialogTransition direction="up" ref={ref} {...props} />;
});

export default function CreateSessionDialog(props) {
  const { isOpen, close } = props;
  const { trackEvent } = useContext(EventsContext);
  const { getUserToken } = useContext(UserContext);
  const { loadDailySessionStats } = useContext(RecoveryContext);
  const { api } = useContext(ServicesContext);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoadingPlans, setIsLoadingPlans] = useState(true);
  const [isNewPlanDialogOpen, setIsNewPlanDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);

  const prevIsOpen = usePrevious({ isOpen }, useRef, useEffect);

  const saveSessions = async () => {
    setIsSaving(true);
    for(var day of selectedDays){
      await api.post({ url: 'recovery/sessions', data:{ planId: selectedPlan.id, plannedStartDate: day.startOf('day').toISOString() }, getUserToken });
    }
    trackEvent('Recovery:Sessions:Save');
    loadDailySessionStats(true);
    setIsSaving(false);
    close(true);
  };

  useEffect(() => {
    if(isOpen){
      trackEvent(`Recovery:Sessions:New:${activeStep}`);
    }
  }, [activeStep, isOpen]);

  useEffect(() => {
    if(!isOpen){
      if(prevIsOpen?.isOpen){
        trackEvent('Recovery:Sessions:Closed');
      }
      
      setActiveStep(0);
      setSelectedPlan('');
      setSelectedDays([]);
    }
  }, [isOpen]);

  const isDaySelected = (day) => {
    return selectedDays.filter(sd => sd.isSame(day)).length > 0;
  };

  const handleChange = (currentDay) => {
    var currentDateStartOfDay = currentDay.startOf('day');
    if(currentDay != null 
        && selectedDays.filter(d => d.isSame(currentDateStartOfDay)).length == 0){
      setSelectedDays([...selectedDays, currentDateStartOfDay]);
    }else{
      setSelectedDays(selectedDays.filter(sd => !sd.isSame(currentDateStartOfDay)));
    }
  };

  const removeDate = (date) => {
    var dateStartOfDay = date.startOf('day');
    setSelectedDays(selectedDays.filter(d => !d.isSame(dateStartOfDay)));
  };

  const handleNext = () => {
    if(activeStep == 0){
      setActiveStep(activeStep + 1);
    }else{
      saveSessions();
    }
  };

  const isNextDisabled = activeStep == 0 ?
    selectedPlan == '' :
    selectedDays.length == 0;

  const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'selected'
  })(({ theme, selected }) => ({
    ...(selected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.dark
      },
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%'
    })
  }));

  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!selectedDays) {
      return <PickersDay {...pickersDayProps} />;
    }

    const selected = isDaySelected(date.startOf('day'));

    return (
      <CustomPickersDay
        data-testid={`recovery-create-session-select-day-option-${date.format('YYYYMMDD')}`}
        {...pickersDayProps}
        onClick={(e) => handleChange(date)}
        disableMargin
        selected={selected}
      />
    );
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Box sx={{ 
        height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))', 
        marginTop: 'calc(env(safe-area-inset-top))', 
        marginBottom: 'calc(env(safe-area-inset-bottom))', 
        display: 'flex', 
        flexDirection: 'column' }}>
        <DialogHeader title='New Session' close={close}/>
        <Divider />
        {activeStep == 0 && 
        <Box p={2} >
          <Typography variant='h6' fontWeight='normal' gutterBottom>
            Select Exercise Plan
          </Typography>
          <Typography variant='body2'>
            Please select the exercise plan that you would like to attach to this session.
          </Typography>
          <Box sx={{ py: 2 }}>
            {!isNewPlanDialogOpen &&
              <SelectPlan 
                shouldAddCreateNewPlanOption={true}
                selectDataTestId={'recovery-create-session-select-plan-button'}
                selectedPlan={selectedPlan}
                handleChange={(plan) => {
                  if(plan.id == 'CREATE_NEW_PLAN_OPTION'){
                    setIsNewPlanDialogOpen(true);
                  }else{
                    setSelectedPlan(plan);
                  }
                  
                }}
                handleLoadingStateChange ={(isLoading) => {
                  setIsLoadingPlans(isLoading);
                }} />
            }
          </Box>
        </Box>
        }
        {activeStep == 1 && 
          <Box p={2} >
            <Typography variant='h6' fontWeight='normal' gutterBottom>
              Session Date(s)
            </Typography>
            <Typography variant='body2'>
              Please select the date(s) you would like to add this session for.
            </Typography>
            <Box sx={{ py: 2 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Select Days"
                  name="endDate"
                  closeOnSelect={false}
                  inputFormat="DD/MM/yyyy"
                  value={null}
                  renderDay={renderPickerDay}
                  onChange={() => {}}
                  renderInput={(params) => <TextField data-testid='recovery-create-session-select-days-input' fullWidth {...params} />}
                />
              </LocalizationProvider>
              <Box sx={{ py: 1, width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                {selectedDays.sort((a, b) => a.unix() - b.unix()).map(day => <Chip key={day.format()}
                  sx={{ m: 0.25 }}
                  label={day.format('MMM D,YYYY')}
                  variant="outlined"
                  onDelete={() => removeDate(day)} />)}
              </Box>
            </Box>
            {selectedDays.length > 2 &&
              <Typography variant='body2'>
                You can set multiple recurring session by creating a program instead
              </Typography>
            } 
          </Box>
        }
        <Buttons 
          isNextDisabled={isNextDisabled || isLoadingPlans || isSaving}
          isBackDisabled={isLoadingPlans || isSaving}
          nextText = {activeStep == 1 ? 'Add' : 'Next'}
          next={handleNext}
          back={activeStep != 0 ? () => setActiveStep(activeStep - 1) :  null}
          backText='Back'
        />
      </Box>
      <NewPlanDialog 
        isOpen={isNewPlanDialogOpen}
        close={() => {
          setIsNewPlanDialogOpen(false);
        }}/>
    </Dialog>
  );
}