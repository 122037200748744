import React, { useState, useEffect, useContext } from 'react';
import { Typography } from '@mui/material';

import { Page, DayScroller } from '@components/v2/organisms';
import { Box, Card, FabButton, IconWithBackground, LoadableText } from '@components/v2/atoms';
import { useTheme } from '@emotion/react';
import moment from 'moment';
import { UserContext } from '@context/user';
import { ServicesContext } from '@context/services';
import { moodToText } from '@helpers/text';

import useDiaryEntriesStore from './../../../stores/diary-entries';
import { ChevronRight } from '@material-ui/icons';

import CreateDiaryDialog from './create/create-dialog';
import DiaryGraph from './graph';
import DiaryEntry from './entry';

const IndexContainer = () => {
  const theme = useTheme();
  const { user, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { isLoading, diaryEntries, diaryStreak, activeDay, setActiveDay, activeDayDiaryEntry } = useDiaryEntriesStore();
  const [ isCreateDiaryOpen, setIsCreateDiaryOpen ] = useState(false);
  const [ diaryEntryBeingEdited, setDiaryEntryBeingEdited ] = useState(null);
  
  const moveActiveDay = (newActiveDay) => {
    setActiveDay(newActiveDay.clone(), api, getUserToken);
  };

  const getIconForMood = (diaryEntry) => {
    switch(diaryEntry?.mood){

    case 1:
      return 'sentiment-angry';
    case 2:
      return 'sentiment-tired';
    case 3:
      return 'sentiment-ok';
    case 4:
      return 'sentiment-content';
    case 5:
      return 'sentiment-delighted';
    default:
    }
  };

  const mapStatsToDayScrollerProps = () => {
    var result = [];
    diaryEntries?.forEach(de => {
      result.push({
        day: moment(de.createdAt).startOf('day'),
        count: 1,
        value: 100,
        completeIcon: getIconForMood(de)
      });
    });
    return result;
  };

  useEffect(() => {
    if(user){
      setActiveDay(moment(), api, getUserToken);
    }
  }, [user]);

  var isPastTense = activeDay.isBefore(moment().startOf('day'));

  return (
    <Page title='Diary'>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ background: theme.palette.braceGreenDark.main, paddingTop: 0, overflow: 'hidden', minHeight: 76 }}>
          <DayScroller 
            thickness={1}
            activeDay={activeDay} 
            moveActiveDay={moveActiveDay} 
            isLoading={isLoading}
            data={mapStatsToDayScrollerProps()}
          />
      
        </Box>
        <Box sx={{ flexGrow: 1, overflow: 'scroll' }}>
          <Box sx={{ p: 2, paddingTop: 1 }}>
            <Typography 
              component='div'
              fontWeight='medium'
              color='text.main'
              paddingBottom={1}>
              {`How ${isPastTense ? ' were' : 'are'} you feeling${isPastTense ? '' : ' today'}?`}
            </Typography>
            <Card 
              sx={{ display: 'flex', alignItems: 'center', height: '100%', px: 2, py: 1 }}
              data-testid='diary-today-button'
              onClick={() => {
                setIsCreateDiaryOpen(true);
                setDiaryEntryBeingEdited(activeDayDiaryEntry);
              }}>
              <IconWithBackground 
                isLoading={isLoading}
                type={activeDayDiaryEntry ? getIconForMood(activeDayDiaryEntry) : 'diary'}
                size={40} />
              <Box sx={{ flexGrow: 1, px: 2 }}>
                <Typography variant='body1'
                  component='div'
                  fontWeight='medium'>
                  <LoadableText isLoading={isLoading}
                    text={activeDayDiaryEntry ? 
                      `You ${isPastTense ? ' were' : 'are'} ${moodToText(activeDayDiaryEntry.mood)}${activeDayDiaryEntry.mood >= 4 ? '!' : ''}` : 
                      `Complete ${isPastTense ? 'Diary Entry' : 'Today\'s Diary'}`} />
                </Typography>
                <Typography variant='body2' component='div' color='text.disabled'>
                  <LoadableText isLoading={isLoading}
                    text={
                      diaryStreak > 0 ? `${isPastTense ? 'You were' : 'You’re'} on a ${diaryStreak} day streak!` :
                        'It’s important to keep track!'
                  
                    } />
                </Typography>
              </Box>
              <ChevronRight color='disabled'/>
            </Card>
          </Box>
          <Box sx={{ px: 2, paddingBottom: 2 }}>
            <Typography 
              component='div'
              fontWeight='medium'
              color='text.main'
              paddingBottom={1}>
              History
            </Typography>
            <DiaryGraph activeDay={activeDay.clone()} data={diaryEntries}/>
          </Box>
          {diaryEntries.length > 0 &&
          <Box sx={{ px: 2 }}>
            <Typography 
              component='div'
              fontWeight='medium'
              color='text.main'>
              My Diary Entries
            </Typography>
            {diaryEntries.map(de => {
              return (
                <Box key={de.id} my={1}>
                  <DiaryEntry diaryEntry={de}
                    onClick={() => {
                      setIsCreateDiaryOpen(true);
                      setDiaryEntryBeingEdited(de);
                    }}/>
                </Box>
              );
            })}
          </Box>
          }
        </Box>
      </Box>
      <CreateDiaryDialog 
        activeDay={activeDay}
        isOpen={isCreateDiaryOpen} 
        diaryEntryBeingEdited={diaryEntryBeingEdited}
        close={() => {
          setIsCreateDiaryOpen(false); 
          setDiaryEntryBeingEdited(null);
          setActiveDay(activeDay, api, getUserToken);}}/>
    </Page>
  );
};

export default IndexContainer;