import React from 'react';

import { useTheme } from '@emotion/react';

import { Box, Icon } from '@components/v2/atoms';
import { useMediaQuery } from '@material-ui/core';

export default function RoundIcon(props) {
  const { iconType='crown' } = props;
  const theme = useTheme();
  const largeMatches = useMediaQuery('(min-height: 900px)');
  return (
    <Box 
      flex
      center='v'
      justifyContent='center'
      sx={{ 
        width: largeMatches ? 72 : 64,
        height: largeMatches ? 72 : 64,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '50%' }}>
      <Icon 
        size={largeMatches ? 36 : 32}
        type={iconType}
        fill={theme.palette.braceGreenDark.main}
        stroke={theme.palette.braceGreenDark.main}
      />
    </Box>
  );
}