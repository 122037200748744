import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';
import { Card, Icon } from '@components/v2/atoms';

export default function EmptySection(props){
  const { iconType, iconSize=36, action, title, message, opacity, dataTestId } = props;

  return (
    <Card 
      data-testid={dataTestId}
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center', 
        height: 'calc(100% - 8px)', 
        p: 1, 
        px: 2, 
        mx: 2,
        marginBottom: 1,
        opacity 
      }}
      onClick={action}
    >
      <Icon type={iconType} size={iconSize} stroke={'#fff'}/>
      <Box sx={{ paddingTop: 1 }}>
        <Typography variant='body1'
          textAlign='center'
          fontWeight='medium'
          sx={{ paddingX: 1 }}>
          {message}
        </Typography>
      </Box>
    </Card>
  );
}

