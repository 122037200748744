import React, {} from 'react';
import { useDispatch } from 'react-redux';

import { 
} from '@mui/material';

import { loadUser } from './../../actions/users';
import { Page } from '@components/v2/organisms';

import { Community }from '@components/templates';

export default function Index(){
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    dispatch(loadUser());
  }, []);

  return (
    <Page title='Community'>
      <Community />
    </Page>
  );
}

