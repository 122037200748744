import React from 'react';

import { 
  Box,
  Card
} from '@mui/material';

import MoodEmoji from '../../../components/atoms/mood-emoji';

import { red, amber, grey, green } from '@mui/material/colors';

export default function EmotionSelector(props) {
  const { value } = props;

  const onSelect = (selectionValue) => {
    props.onSelect(selectionValue);
  };

  const Option = (props) => {
    return (
      <Card elevation={value === props.value ? 3 : 0}
        data-testid={`emotion-selector-${props.value}`}
        sx={{ backgroundColor: props.bgColor, padding: 0.5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        onClick={() => onSelect(props.value)}>
        <Box>
          <MoodEmoji displayText={true} level={props.value}/>
        </Box>
      </Card>
    );
  };

  return (
    <Box>
      <Box sx={{ typography: 'h6', py: 1 }}>
        How are you feeling today?
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '18%' }}>
          <Option
            bgColor={ red[50]}
            value={1}/>
        </Box>
        <Box sx={{ width: '18%' }}>
          <Option
            bgColor={ amber[50] }
            value={2}/>
        </Box>
        <Box sx={{ width: '18%' }}>
          <Option
            bgColor={ grey[50] }
            value={3}/>
        </Box>
        <Box sx={{ width: '18%' }}>
          <Option
            bgColor={ green[50] }
            value={4}/>
        </Box>
        <Box sx={{ width: '18%' }}>
          <Option
            bgColor={ green[100] }
            value={5}/>
        </Box>
      </Box>
    </Box>
  );
}