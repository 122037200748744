import React, { useContext, useEffect, useState } from 'react';

import { 
  Box 
} from '@mui/material';

import { DayScroller, Page } from '@components/v2/organisms';

import { EventsContext } from '@context/events';
import { UserContext, RecoveryContext } from '@context';
import Plans from './plans';
import Programs from './programs';
import Sessions from './sessions';
import { useTheme } from '@emotion/react';
import moment from 'moment';

const mapStatsToDayScrollerProps = (dailySessionStats) => {
  var result = [];
  dailySessionStats.sessions?.forEach(ss => {
    result.push({
      day: moment(ss.plannedStartDate),
      count: ss.completeCount + ss.incompleteCount,
      value: ss.completeCount * 100 / (ss.completeCount + ss.incompleteCount)
    });
  });
  return result;
};

export default function Home(){
  const { user } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { loadAll, isLoadingPlans, plans, activeDay, moveActiveDay, dailySessionStats, isLoadingDailySessionStats } = useContext(RecoveryContext);
  const [ areSessionsExpanded, setAreSessionsExpanded ] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if(user){
      trackEvent('Recovery:Open');
      loadAll(true);
    }
  }, [user]);

  useEffect(() => {
    setAreSessionsExpanded(false);
  }, [activeDay?.valueOf()]);

  const hasNoPlans = !isLoadingPlans && plans.length == 0;

  if(!user?.role || user?.role !== 'CLIENT'){
    return (
      <Page title='Recovery'>
        <Box p={1}>
          <>Practitioners should use our Web App to:</>
          <li>Invite & Manage Clients</li>
          <li>Create Private Groups</li>
          <li>Allocate Plans & Goals</li>
          <li>Track Progress Metrics</li>
          <a href='https://app.bracesocial.com'>Web App</a>
        </Box>
       
      </Page>
    );
  }

  var heights = {
    sessions: '45%',
    programs: '27.5%',
    plans: '27.5%'
  };

  if(areSessionsExpanded){
    heights = {
      sessions: 'calc(100% - 40px)', // Show Less Section Height
      programs: '0%',
      plans: '0%'
    };
  }else if(hasNoPlans){
    heights = {
      sessions: '30%',
      programs: '30%',
      plans: '40%'
    };
  }

  return (
    <Page title='Recovery'>
      <Box sx={{ height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ background: theme.palette.braceGreenDark.main, paddingTop: 0, overflow: 'hidden', minHeight: 76 }}>
          <DayScroller 
            activeDay={activeDay} 
            moveActiveDay={moveActiveDay} 
            isLoading={isLoadingDailySessionStats}
            data={mapStatsToDayScrollerProps(dailySessionStats)}
          />
        </Box>
        <Box sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          paddingTop: 0.5, 
          overflow: 'hidden'
        }}>
          {(hasNoPlans && !areSessionsExpanded) &&
            <Box sx={{ 
              height: heights.plans, 
              width: '100%' 
            }}>
              <Plans />
            </Box>
          }
          <Box sx={{ 
            height: heights.sessions, 
            width: '100%' 
          }}>
            <Sessions areSessionsExpanded={areSessionsExpanded} toggleExpansion={() => setAreSessionsExpanded(!areSessionsExpanded)}/>
          </Box>
          {!areSessionsExpanded &&
              <>
                <Box sx={{ 
                  height: heights.programs, 
                  width: '100%' 
                }}>
                  <Programs />
                </Box>
                {!hasNoPlans &&
                  <Box sx={{ 
                    height: heights.plans, 
                    width: '100%' 
                  }}>
                    <Plans />
                  </Box>
                }
              </>
          }
        </Box>
      </Box>
    </Page>
  );
}

