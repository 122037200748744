import React, { useContext, useEffect } from 'react';

import { 
} from '@mui/material';

import Step1 from './components/steps/1';
import Step2 from './components/steps/2';
import Step3 from './components/steps/3';
import Step4 from './components/steps/4';
import { OnboardingContext, OnboardingProvider } from './context';
import { preloadImage } from '@helpers/images';

function OnboardingIndex(){
  const { activeStep } = useContext(OnboardingContext);
  return (
    <>
      {activeStep == 0 &&
        <Step1 />
      }
      {activeStep == 1 &&
        <Step2 />
      }
      {activeStep == 2 &&
        <Step3 />
      }
      {activeStep == 3 &&
        <Step4 />
      }
    </>
  );
}

export default function Component(){

  useEffect(() => {
    preloadImage('https://bracesocial-prod-cdn.azureedge.net/static/onboarding_step_1.png');
    preloadImage('https://bracesocial-prod-cdn.azureedge.net/static/onboarding_step_2.png');
    preloadImage('https://bracesocialprod.blob.core.windows.net/static/onboarding_step_3_mock_up_1.png');
    preloadImage('https://bracesocialprod.blob.core.windows.net/static/onboarding_step_3_mock_up_2.png');
    preloadImage('https://bracesocialprod.blob.core.windows.net/static/onboarding_step_3_mock_up_3.png');
  }, []);

  return (
    <OnboardingProvider >
      <OnboardingIndex />
    </OnboardingProvider>
  );
}