import React, { useContext, useState, useEffect } from 'react';

import { Box } from '@mui/material';

import { Page } from '@components/v2/organisms';
import { UserContext, ServicesContext, EventsContext } from '@context';
import Course from './course';
import ViewCourse from './view';

const EmptyLoadingState = () => {
  var rows = [];
  for (var i = 0; i < 10; i++) {
    rows.push(<Course key={i} course={{}} isLoading={true}/>);
  }
  return rows;
};

export default function Index(){
  const { user } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { trackEvent } = useContext(EventsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const loadCourses = async () => {
    setIsLoading(true);
    var result = await api.get({ url: 'courses/search', user });

    var sortedCourses = result.results.sort((c1, c2) => {
      // Both started
      if((!c1?.account?.completedAt && c1?.account?.startedAt) && (!c2?.account?.completedAt && c2?.account?.startedAt)){
        return 0;
      }
      // c1 started
      else if(!c1?.account?.completedAt && c1?.account?.startedAt){
        return -1;
      }
      // c2 started
      else if(!c2?.account?.completedAt && c2?.account?.startedAt){
        return 1;
      }
      // both completed
      else if(c1?.account?.completedAt && c2?.account?.completedAt){
        return 0;
      }
      // c1 completed
      else if(c1?.account?.completedAt){
        return 1;
      }
      // c2 completed
      else if(c2?.account?.completedAt){
        return -1;
      }
      return 0; 
    });

    setCourses(sortedCourses);
    setIsLoading(false);
  };

  useEffect(() => {
    if(user?.id){
      trackEvent('Community:Courses:Open');
      loadCourses();
    }
  }, [user]);

  return (
    <Page title='Learn'>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'scroll', paddingTop: 2 }}>
        {isLoading && courses.length === 0 &&
          <EmptyLoadingState />
        }
        <Box sx={{ overflow: 'auto' }}>
          {(!isLoading && courses.length > 0) && 
            courses.map(c => <Course key={c.id} course={c} isLoading={false}/>)
          }
        </Box>
      </Box>
      <ViewCourse refresh={loadCourses}/>
    </Page>
  );
}

