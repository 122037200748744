import React from 'react';

import {
} from '@mui/material';

import Profile from './';
import { Dialog, Box } from '@components/v2/atoms';
import useDialogsStore from '@stores/dialogs';

export default function ProfileDialog(props) {
  const { profileDialogId, setProfileDialogId } = useDialogsStore();

  return (
    <Dialog
      isDenseHeader
      enableCloseButton={true}
      isOpen={profileDialogId != null}
      close={() => setProfileDialogId(null)}
      variant='near-fullscreen'
      sx={{ ...props.sx, marginTop: 'env(safe-area-inset-top)' }} >
      <Box fullHeight sx={{ overflowY: 'scroll' }}>
        <Profile profileId={profileDialogId}/>
      </Box>
    </Dialog>
  );
}