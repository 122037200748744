import moment from 'moment';
let width, height, gradient;
function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  width = chartWidth;
  height = chartHeight;
  gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
  return gradient;
}

const getDisplayFormat = (activeRange) => {
  if(activeRange == '1w'){
    return 'dd';
    
  }
  return 'D MMM';
};

export function buildDataset({ label, data, activeDatasetName }){
  return {
    label,
    data,
    tension: 0.33,
    borderWidth: 2,
    borderColor: function(context) {
      const chart = context.chart;
      const { chartArea } = chart;
      if (!chartArea) {
        // This case happens on initial chart load
        return;
      }
      return '#003F43';
    },
    hidden: activeDatasetName != label.toLowerCase()
  };
}

export function buildOptions({ minDate, activeRange, data, activeDay }){

  var plugins = {
    legend: {
      display: false
    },
    annotation: {
      annotations: {}
    }
  };

  if(activeDay){
    var duration = moment.duration(moment().diff(minDate));
    var hourDiff = duration.asHours();

    plugins.annotation.annotations.activeDayLine = {
      type: 'line',
      yMin: 0.5,
      yMax: 5.5,
      xMin: activeDay,
      xMax: activeDay,
      borderDash:[5, 5],
      borderColor: 'rgb(100, 100, 100)',
      borderWidth: 1
    };
    plugins.annotation.annotations.activeDayLineTop = {
      type: 'label',
      xValue: activeDay.clone().subtract(hourDiff / 50, 'hours'),
      yValue: 5.25,
      backgroundColor: 'rgba(245,245,245, 0)',
      content: ['5'],
      font: {
        size: 10
      }
    };
    plugins.annotation.annotations.activeDayLineBottom = {
      type: 'label',
      xValue: activeDay.clone().subtract(hourDiff / 50, 'hours'),
      yValue: 0.75,
      backgroundColor: 'rgba(245,245,245, 0)',
      content: ['1'],
      font: {
        size: 10
      }
    };
  }

  if(data.length == 0){
    plugins.annotation.annotations.noData = {
      type: 'label',
      xValue: moment().subtract(84, 'hours'),
      yValue: 3.5,
      backgroundColor: 'rgba(245,245,245, 0)',
      content: ['No Data To Display'],
      font: {
        size: 16
      }
    };
  }

  return {
    responsive: true,
    maintainAspectRatio: false,
    elements:{
      point:{
        radius: 1
      }
    },
    layout:{
      padding: {
        left: 20,
        right: 20
      }
    },
    plugins,
    scales: {
      y: {
        min: 0.5,
        max: 5.5,
        display: false 
      },
      x: {
        type: 'time',
        time: {
          tooltipFormat:'MM/DD/YYYY',
          unit: 'day',
          displayFormats: {
            day: getDisplayFormat(activeRange)
          }
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7
        },
        min: minDate,
        max: moment().add(1, 'hour'),
        grid:{
          display: true
        }
      }
    }
  };
}

export const filterData = (data, activeRange) => {
  var limit = moment().subtract(7, 'day');
  switch(activeRange){
  case '1w':
    limit = moment().subtract(7, 'day');
    break;
  case '1m':
    limit = moment().subtract(1, 'month');
    break;
  case '6m':
    limit = moment().subtract(6, 'month');
    break;
  case '1y':
    limit = moment().subtract(12, 'month');
    break;
  default:
    limit = moment().subtract(7, 'day');
  }
  return data.filter(d => moment(d.createdAt).isAfter(limit));
};

export const getMinDate = (data) => {
  var result = moment().subtract(7, 'day');
  
  data.forEach(d => {
    if(moment(d.createdAt).isBefore(result)){
      result = moment(d.createdAt);
    }
  });

  return result;
};

