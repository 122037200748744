import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Typography, Stack } from '@mui/material';

import {  Box, Card, Divider, Dot, IconWithBackground, LoadableText } from '@components/v2/atoms';
import { useTheme } from '@emotion/react';
import { ServicesContext, UserContext } from '@context';
import { toShortDateString } from '@helpers/dates';
import { ProgressLinear } from '@components/atoms';

export default function ProfileStatistics(props){
  const { profileId } = props;
  const { api } = useContext(ServicesContext);
  const { getUserToken } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({});
  const theme = useTheme();

  const loadStatistics = async () => {
    setIsLoading(true);
    var result = await api.get({ url: `profiles/${profileId}/v2/stats`, getUserToken });
    setStats(result);
    setIsLoading(false);
  };

  useEffect(() => {
    if(profileId){
      loadStatistics();
    }
  }, [profileId]);

  const Statistic = (props) => {
    const { values, width, justifyContent='space-between' } = props;
    return (
      <Box sx={{ width: width }}>
        <Card
          variant='outlined'
          sx={{ 
            display: 'flex',
            height: '100%',
            justifyContent,
            borderRadius: 1,
            py: 1
          }}>
          {values.map((v, index) => (
            <Fragment key={v.text}>
              <Box
                sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: v.width,
                  px: 1
                }}
              >
                {v.icon &&
              <Box sx={{ paddingBottom: 0.5 }}>
                <IconWithBackground shape='round'
                  type={v.icon}
                  size={22}
                  stroke={theme.palette.primary.main}
                  fill={theme.palette.secondary.main}
                />
              </Box>
                }
                <Box>
                  <Typography component='div'
                    variant='caption'
                    color='text.secondary'
                    fontSize='10px'>
                    {v.text}
                  </Typography>
                </Box>

                <Box>
                  <Typography component='div' fontWeight='medium'>
                    <LoadableText isLoading={isLoading} text={v.value ?? 0}/>
                  </Typography>
                </Box>
                {v.progress >= 0 && 
                <Box sx={{ marginTop: 1 }}>
                  <ProgressLinear value={v.progress} height={8}/>
                </Box>
                }
              </Box>
              
              {(index < values.length - 1) && <Divider variant="middle"
                orientation="vertical"
                flexItem
                sx={{ marginX : justifyContent == 'space-between' ? 1 : 2 }} />}
            </Fragment>
          ))}
        </Card>
      
      </Box>
    );
  };

  const StatisticHeader = (props) => {
    const { text, smallPaddingTop } = props;
    return (
      <Box sx={{ paddingTop: smallPaddingTop ? 1 : 2, paddingBottom: 1 }}>
        <Typography color='text.secondary'>
          {text}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ p: 1 }} >
      <StatisticHeader smallPaddingTop text='Recovery Actions'/>
      <Stack direction='row' spacing={1} sx={{ alignItems: 'stretch' }}>
        <Statistic 
          width='33.33%'
          values={[{ 
            icon: 'dumbbell',
            text: 'Sessions Completed', 
            width: '100%', 
            value: stats?.recovery?.completeSessionsCount
          }]}/>
        <Statistic
          width='33.33%'
          values={[{ 
            icon: 'calendar',
            text: 'Programs Completed', 
            width: '100%', 
            value: stats?.recovery?.completeProgramsCount
          }
          ]}/>
        <Stack spacing={1} sx={{ height: '100%', width: '33.33%' }}>
          <Statistic values={[{ 
            text: 'Sets', 
            width: '100%', 
            height: '50%',
            value: stats?.recovery?.completeSetsCount
          }]}/>
          <Statistic values={[{ 
            text: 'Reps', 
            width: '100%', 
            height: '50%',
            value: stats?.recovery?.completeRepsCount
          }]}/>
        </Stack>
      </Stack>
      <StatisticHeader text='Community Engagement'/>
      <Stack direction='row' spacing={1}>
        <Statistic 
          width='100%'
          values={[{ 
            icon: 'conversation',
            text: 'Posts', 
            value: stats?.community?.postsCount
          },
          { 
            icon: 'thumb-up',
            text: 'Likes', 
            value: stats?.community?.likesCount
          },
          { 
            icon: 'comment',
            text: 'Comments', 
            value: stats?.community?.commentsCount
          },
          { 
            icon: 'up-arrow',
            text: 'Up Votes', 
            value: stats?.community?.postsCount
          }]}/>
      </Stack>
      <StatisticHeader text='Diary Entries'/>
      <Stack direction='row' spacing={1}>
        <Statistic 
          width='100%'
          justifyContent='none'
          values={[{ 
            icon: 'diary',
            text: 'No. Of Entries', 
            value: stats?.diary?.numberOfDiaryEntries
          },
          { 
            icon: 'flame',
            text: 'Longest Streak', 
            value: stats?.diary?.longestStreakStartDate ? 
              stats?.diary?.streakLength > 1 ?
                `${toShortDateString(stats?.diary?.longestStreakStartDate, false)} - ${toShortDateString(stats?.diary?.longestStreakEndDate, false)}` :
                `${toShortDateString(stats?.diary?.longestStreakStartDate, false)}` :
              '-'
          }]}/>
      </Stack>
      <StatisticHeader text='Learn Completion'/>
      <Statistic 
        width='100%'
        values={[{ 
          text: 'Course Statistics', 
          width: '100%', 
          progress: stats?.courses?.completionPercentage,
          value: 
          <Box fullWidth flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            {`${stats?.courses?.completionPercentage}% Complete`}<Dot />{`${stats?.courses?.completeCourses} of ${stats?.courses?.totalCourses} Courses`}
          </Box>
        }]}/>
    </Box>
  );
}

