import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import Emoji from 'react-emoji-render';

import { 
  Box,
  Stack,
  Skeleton
} from '@mui/material';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import Avatar from './../../../components/avatar/avatar';

import { markAsRead } from './../../../actions/notifications';
import useNotificationsStore from '@stores/notifications';
import { UserContext, ServicesContext } from '@context';

const getMessage = (notification) => {
  if(notification.type === 'COMMENT')
    return `${notification.details.fromName} commented on one of your check-ins!`;
  if(notification.type === 'LIKE')
    return `${notification.details.fromName} liked one of your check-ins!`;
  if(notification.type === 'BRACE_CUSTOM_NOTIFICATION')
    return <Emoji text={notification.details.message} />;
  if(notification.type === 'PLAN_ASSIGNMENT')
    return `${notification.details.professionalName} assigned you a new exercise plan!`;
};

const getIcon = (notification) => {
  if(notification.type === 'COMMENT')
    return <InsertCommentIcon sx={{ color: 'primary.main' }}/>;
  if(notification.type === 'LIKE')
    return <ThumbUpIcon sx={{ color: 'error.main' }}/>;
  if(notification.type === 'BRACE_CUSTOM_NOTIFICATION')
    return <NotificationsActiveIcon sx={{ color: 'warning.light' }}/>;
  if(notification.type === 'PLAN_ASSIGNMENT')
    return <FactCheckIcon sx={{ color: 'secondary.light' }}/>;
};

const getGoToAction = (notification) => {
  if(notification.type === 'BRACE_CUSTOM_NOTIFICATION')
    return `/${notification.details.link}`;
  if(notification.type === 'PLAN_ASSIGNMENT')
    return '/recovery';

  var id = notification.details.checkInId ? notification.details.checkInId : notification.details.postId;

  return `community?checkInId=${id}`;
};

export default function Notification(props){
  let history = useHistory();
  let dispatch = useDispatch();
  const { load } = useNotificationsStore();
  const { getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);

  const goToCheckIn = () => {
    if(!props.notification.hasBeenRead){
      dispatch(markAsRead(props.notification.id));
      load(api, getUserToken);
    }
    history.push(getGoToAction(props.notification));
  };

  return (
    <Stack p={1}
      direction="row"
      spacing={1} 

      sx={{ 
        // bgcolor: props.notification?.hasBeenRead ? 'background.paper' : 'rgb(229, 246, 253)',
        fontWeight: props.notification?.hasBeenRead ? 'regular' : 'bold'
      }}>
      <Box>
        {!props.isLoading &&
          getIcon(props.notification)
        }
        {props.isLoading &&
          <Skeleton variant="rectangular" width={20} height={20} />
        }
      </Box>
      <Box>
        <Avatar userId={props.notification?.details.fromId} isLoading={props.isLoading}/>
      </Box>
      {!props.isLoading &&
        <Box onClick={goToCheckIn}>
          {getMessage(props.notification)}
        </Box>
      }
      {props.isLoading &&
        <Skeleton sx={{ width: '100%' }}/>
      }
    </Stack>
  );
}

