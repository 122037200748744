import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { LoadableText } from '@components/atoms';
import { IconWithBackground, Card } from '@components/v2/atoms';

export default function ToDoItem(props){
  const { dataTestId, headerDataTestId, onClick, iconType, isLoading, title, message } = props;
  return (
    <Card 
      data-testid={dataTestId}
      sx={{ display: 'flex', alignItems: 'center', height: '100%', px: 2, py: 1 }}
      onClick={onClick}
    >
      <IconWithBackground 
        isLoading={isLoading}
        type={iconType}
        size={40} />
      <Box sx={{ flexGrow: 1, px: 2 }}>
        <Typography variant='body1'
          data-testid={headerDataTestId}
          component='div'
          fontWeight='medium'>
          <LoadableText isLoading={isLoading} text={title} />
        </Typography>
        <Typography variant='body2' component='div' color='text.disabled'>
          <LoadableText isLoading={isLoading} text={message} />
        </Typography>
      </Box>
      <ChevronRightIcon color='disabled'/>
    </Card>
  );
}

