import React, { useContext, useState } from 'react';

import { 
} from '@mui/material';
import Trial from './components/trial';
import { DeleteAccountDialog, FeedbackDialog } from '@components/v2/organisms';
import Subscription from './components/subscription';
import { useHistory } from 'react-router-dom';
import { BillingContext, AppContext } from '@context';
import AccessCode from './components/access-code';

export default function Paywall(props){
  const { handleDelete, isTrial } = props;
  const history = useHistory();
  const { subscription } = useContext(BillingContext);
  const { accessCode, clearAccessCode } = useContext(AppContext);
  const [ isDeleteAccountOpen, setIsDeleteAccountOpen ] = useState(false);
  const [ shouldShowDropOffScreen, setShouldShowDropOffScreen ] = useState(false);
  const [ isFeedbackDialogOpen, setIsFeedbackDialogOpen ] = useState(false);

  return (
    <>
      {accessCode != false && 
        <AccessCode code={accessCode} clearCode={clearAccessCode}/>
      }
      {accessCode == false &&
        <>
          {(!subscription && !accessCode) && 
            <Trial openDeleteAccount={() => setIsDeleteAccountOpen(true)} />
          }
          {(subscription && !accessCode && !isTrial) && 
            <Subscription 
              setShouldShowDropOffScreen={setShouldShowDropOffScreen} 
              shouldShowDropOffScreen={shouldShowDropOffScreen} 
              openDeleteAccount={() => setIsDeleteAccountOpen(true)} 
              setIsFeedbackDialogOpen={setIsFeedbackDialogOpen}
              setIsDeleteAccountOpen={setIsDeleteAccountOpen} />
          }
          
          <FeedbackDialog
            isDeleteAccount={true}
            isOpen={isFeedbackDialogOpen} 
            close={(wasSurveyOpen, wasSurveyComplete) => {
              setIsFeedbackDialogOpen(false);
              if(!wasSurveyOpen){
                setIsDeleteAccountOpen(true);
              }
              if(wasSurveyComplete){
                history.push('/');
              }
            }}
          />

          <DeleteAccountDialog
            deleteAccount={handleDelete}
            isOpen={isDeleteAccountOpen} 
            close={() => setIsDeleteAccountOpen(false)}
          />
        </>
      }
    </>
  );
}