import React, { useEffect } from 'react';

import { 
  Typography
} from '@mui/material';

import { Box } from '@components/v2/atoms';
import OnboardingPage from './../../page';
import Image from 'mui-image';
import { useTheme } from '@emotion/react';
import { StatusBar } from '@capacitor/status-bar';

export default function Step1(){
  const theme  = useTheme();

  useEffect(() => {
    StatusBar.setOverlaysWebView({ overlay: true });
  }, []);

  return (
    <OnboardingPage shouldAppearBehindStatusBar sx={{ overflow: 'hidden' }} nextButtonText='See Features'>
      <Box>
        <Image
          width='calc(100vw + 2px)'
          height='calc(100vw * 1.15)'
          style={{ marginLeft:-1, zIndex: 1 }}
          duration={0}
          src='https://bracesocial-prod-cdn.azureedge.net/static/onboarding_step_2.png'
        />
        <Box sx={{ position: 'relative', zIndex: 2 , width: '100%', height: '152px', marginTop:'-150px', backgroundImage: 'linear-gradient(transparent, #fafafa, #fafafa)' }}>
        </Box>
      </Box>
      <Box sx={{ position: 'relative', color: theme.palette.primary.main, px: 3, marginTop:'-50px', zIndex: 3 }}>
        <Typography variant='h5'
          textAlign='center'
          fontWeight='medium'
        >
          Recovery is a Tough Journey
        </Typography>
        <Typography variant='body1'
          textAlign='center'
        >
          It can be lonely, uncertain and hard to stay on track
        </Typography>
        <Typography variant='h5'
          textAlign='center'
          fontWeight='medium'
          sx={{ marginTop: 2 }}
        >
          Don't worry, you're not alone.
        </Typography>
        <Typography variant='body1'
          textAlign='center'
        >
          Brace is empowering our community with the physical and mental health supports to comeback stronger, together.
        </Typography>
      </Box>
    </OnboardingPage>
  );
}