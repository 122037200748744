import React, { useContext, useEffect, useState } from 'react';

import { 
  IconButton,
  Box,
  Tab,
  Divider
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Page } from '@components/v2/organisms';
import SubPage from '../../../../components/page/sub-page';
import { UserContext, RecoveryContext, EventsContext } from '@context';
import { useTheme } from '@emotion/react';
import { LoadableList } from '@components/atoms';
import { EmptyState, Tabs, TabPanel, a11yProps } from '@components/molecules';
import moment from 'moment';
import { getProgramCompletionPercentage } from '@helpers/programs';
import Program from './program';
import CreateProgramDialog from '../new/create-program-dialog';

export default function ManagePrograms(){
  var theme = useTheme();
  const { trackEvent } = useContext(EventsContext);
  const { user } = useContext(UserContext);
  const { programs, loadPrograms, isLoadingPrograms, loadActiveDaySessions, loadDailySessionStats } = useContext(RecoveryContext);
  const [activeTab, setActiveTab] = useState(0);
  var [isCreateProgramDialogOpen, setIsCreateProgramDialogOpen] = useState(false);

  useEffect(() => {
    if(user){
      trackEvent('Recovery:Programs:Manage:Open');
      loadPrograms();
    }
  }, [user]);

  var currentMaxTabNumber = 0;

  var activePrograms = programs.filter(p => moment(p.startDate).isBefore(moment()) && moment(p.endDate).isAfter(moment()) && getProgramCompletionPercentage(p) < 100);
  var upcomingPrograms = programs.filter(p => moment(p.startDate).isAfter(moment()));
  var completePrograms = programs.filter(p => moment(p.endDate).isBefore(moment()) || getProgramCompletionPercentage(p) == 100);

  var activeProgramsTabNumber = activePrograms.length > 0 ? 
    currentMaxTabNumber++ 
    : null;
  var upcomingProgramsTabNumber = upcomingPrograms.length > 0 ? 
    currentMaxTabNumber++
    : null;
  var completeProgramsTabNumber = completePrograms.length > 0 ? 
    currentMaxTabNumber++
    : null;
  
  if(currentMaxTabNumber > 0 && activeTab >= currentMaxTabNumber){
    setActiveTab(0);
  }

  return (
    <Page>
      <SubPage 
        title={'My Programs'}
        isBackButtonEnabled={true}
        endButton={
          <IconButton color='primary'
            onClick={() => setIsCreateProgramDialogOpen(true)}
            disabled={isLoadingPrograms}
            data-testid='recovery-programs-create-button'>
            <AddCircleIcon  style={{ fontSize: 28 }}/>
          </IconButton>}
      >
        {isLoadingPrograms &&
          <LoadableList isLoading={true} getRow={(p, index) => <Program isLoadingPrograms={isLoadingPrograms} key={index} program={{}}/>}/>
        }
        {(!isLoadingPrograms && programs.length == 0) &&
          <Box p={6}>
            <EmptyState 
              shouldHideBorder={true}
              size={100}
              iconType='appointment' 
              message={'Build your first program to start your recovery journey!'}
              buttonText='Create Program'
              buttonAction={() => setIsCreateProgramDialogOpen(true)}
            />
          </Box>
        }
        {!isLoadingPrograms && programs.length > 0 &&
        <>
          <Tabs   
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            value={activeTab} 
            onChange={(event, newValue) => {setActiveTab(newValue);}} 
            aria-label="basic tabs example">
            {activePrograms.length > 0 &&
            <Tab label="Active" {...a11yProps(activeProgramsTabNumber)} />
            }
            {upcomingPrograms.length > 0 &&
            <Tab label="Upcoming" data-testid='recovery-manage-programs-upcoming-tab' {...a11yProps(upcomingProgramsTabNumber)} />
            }
            {completePrograms.length > 0 &&
            <Tab label="Complete" data-testid='recovery-manage-programs-complete-tab' {...a11yProps(completeProgramsTabNumber)} />
            }
          </Tabs>
          <Divider />
        </>
        }
        
        {activePrograms != null &&
          <TabPanel value={activeTab} index={activeProgramsTabNumber} dir={theme.direction}>
            {activePrograms.map(p => <Program type='ACTIVE' key={p.id} program={p}/>)}
          </TabPanel>
        }
        {upcomingPrograms != null &&
          <TabPanel value={activeTab} index={upcomingProgramsTabNumber} dir={theme.direction}>
            {upcomingPrograms.map(p => <Program type='UPCOMING' key={p.id} program={p}/>)}
          </TabPanel>
        }
        {completePrograms != null &&
          <TabPanel value={activeTab} index={completeProgramsTabNumber} dir={theme.direction}>
            {completePrograms.map(p => <Program type='COMPLETE' key={p.id} program={p}/>)}
          </TabPanel>
        }
      </SubPage>
      <CreateProgramDialog 
        isOpen={isCreateProgramDialogOpen}
        close={(shouldRefresh) => {
          setIsCreateProgramDialogOpen(false);
          if(shouldRefresh){
            loadPrograms(true);
            loadActiveDaySessions(true);
            loadDailySessionStats(true);
          }
        }}
      />
    </Page>
  );
}

