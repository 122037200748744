import React, { createContext, useContext, useEffect, useState  } from 'react';
import { UserContext } from './user';
import { BillingContext } from './billing';
import moment from 'moment';
import { ServicesContext } from './services';
import { DialogContext } from './dialogs';
import { EventsContext } from './events';

export const TriggersContext = createContext();

export function TriggersProvider(props){
  const TRIGGERS_DATA_NAME = 'triggers';
  const OVERALL_TRIGGER_PAUSE_IN_MINUTES = 5; // We dont want to fire triggers in quick succession so this is overall pause duration
  const { user } = useContext(UserContext);
  const { storage } = useContext(ServicesContext);
  const { subscription } = useContext(BillingContext);
  const { openSurveyDialog } = useContext(DialogContext);
  const { trackEvent } = useContext(EventsContext);
  const [isCheckingTriggers, setIsCheckingTriggers] = useState(false);
  const [triggersState, setTriggersState] = useState(null);

  const TRIGGERS = [
    {
      id: 'FEEDBACK_DAY_2',
      pauseDurationInHours: 24,
      check: () => {
        return subscription != null &&
          subscription.isTrial &&
          moment().diff(moment(subscription.purchasedAt).startOf('day'), 'days') == 1;
      },
      action: async () => {
        setTimeout(() => {
          openSurveyDialog();
        }, [1000]);
      }
    },
    {
      id: 'FEEDBACK_DAY_4',
      pauseDurationInHours: 24,
      check: () => {
        return subscription != null &&
          subscription.isTrial &&
          moment().diff(moment(subscription.purchasedAt).startOf('day'), 'days') == 3;
      },
      action: async () => {
        setTimeout(() => {
          openSurveyDialog();
        }, [1000]);

      }
    }
  ];

  const getTriggersState = async () => {
    if(triggersState){
      return triggersState;
    }
    var data = await storage.get(TRIGGERS_DATA_NAME);
    if(data && data.userId == user.id){
      return data;
    }

    return {};
  };

  const saveTriggersData = async (data) => storage.save(TRIGGERS_DATA_NAME, data);

  const isTriggerPaused = (triggerData, trigger) => {
    return (triggerData.lastFiredTrigger && moment().diff(triggerData.lastFiredTrigger, 'minutes') < OVERALL_TRIGGER_PAUSE_IN_MINUTES) || 
      (triggerData[trigger.id] 
        && moment().diff(moment(triggerData[trigger.id]), 'hours') < trigger.pauseDurationInHours);
  };

  const markTriggerAsFired = (triggerData, trigger) => {
    triggerData.userId = user.id;
    triggerData[trigger.id] = moment().toISOString();
    triggerData.lastFiredTrigger = moment().toISOString();
    saveTriggersData(triggerData);
    setTriggersState(triggerData);
  };

  const fireTrigger = async (trigger) => {
    var triggerData = await getTriggersState();
    if(trigger.check({ subscription }) && !isTriggerPaused(triggerData, trigger)){
      trackEvent(`Trigger:${trigger.id}:Fired`);
      await trigger.action({ subscription });
      markTriggerAsFired(triggerData, trigger);
    }
  };

  const checkTriggers = async (id) => {
    setIsCheckingTriggers(true);
    var triggersToCheck = TRIGGERS.filter(t => !id || t.id == id);
    for (var trigger of triggersToCheck) {
      if(await fireTrigger(trigger)){
        break;
      }
    }
    setIsCheckingTriggers(false);
  };

  useEffect(() => {
    if(user && subscription && !isCheckingTriggers){
      checkTriggers();
    }

  }, [user, subscription]);

  return (
    <TriggersContext.Provider value={{ 
      checkTriggers
    }}>
      {props.children}
    </TriggersContext.Provider>
  );
}