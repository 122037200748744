import React, { createContext, useState, useContext, useEffect } from 'react';

import { UserContext } from '@context';
import { useHistory } from 'react-router-dom';

export const OnboardingContext = createContext();

export function OnboardingProvider(props){
  const { user, handleLogout, refresh } = useContext(UserContext);
  const [activeStep, setActiveStep] = useState(1);
  const [userType, setUserType] = useState('CLIENT');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if(user?.status == 'ACTIVE'){
      setActiveStep(2);
    }
  }, [user]);

  const next = () => {
    if(activeStep == 3){
      refresh();
      history.push('/paywall');
    }else{
      setActiveStep(activeStep + 1);
    }
   
  };

  const back = () => {
    setActiveStep(activeStep - 1);
  };

  const logout = () => {
    handleLogout(() => history.push('/'));
  };

  const isLogoutEnabled = activeStep > 0 && activeStep != 3;

  const isBackEnabled = activeStep > 1;

  return (  
    <OnboardingContext.Provider value={{ 
      activeStep,
      userType,
      setUserType,
      next,
      back: isBackEnabled ? back : null,
      logout: isLogoutEnabled ? logout : null,
      isLoading,
      setIsLoading
    }}>
      {props.children}
    </OnboardingContext.Provider>
  );
}