import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Skeleton,
  Avatar
} from '@mui/material';

import { LoadableText, ProgressLinear, TruncatedText } from '@components/atoms';
import { Card, Box, Divider, Icon, Dot } from '@components/v2/atoms';
import { Image } from 'mui-image';

import { getProgress } from './helpers';

import { toShortDateString } from '@helpers/dates';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';

export default function Course(props) {
  const{ course } = props;
  const theme = useTheme();
  const IMAGE_SIZE = 75;

  var getImageUrl = () => {
    return `https:${course.imageUrl}`;
  };

  return (
    <Box sx={{ px: 2, py: '6px' }}>
      <Card sx={{ p: 0 }}>
        <Box 
          sx={{ display: 'flex', alignItems: 'center', px: 1.5, py: 1.5, textDecoration: 'none', maxWidth: '100%', fontWeight: 'medium' }}
          component={Link} 
          to={`?courseId=${course?.id}`}
        >
          <Box>
            {props.isLoading ? (
              <Skeleton variant="rectangular" sx={{ borderRadius: 2 }}>
                <Avatar sx={{ width: IMAGE_SIZE, height: IMAGE_SIZE, borderRadius: 1 }}/>
              </Skeleton>
            ) : (
              <Image duration={1000} src={getImageUrl()} style={{ width: `${IMAGE_SIZE}px` }} />
            )}
          </Box>
          <Box sx={{ px: 1, flexGrow: 1 }}>
            <Box sx={{ typography: 'h6', lineHeight: 1.25, color: 'text.primary' }}>
              <LoadableText text={course.title} isLoading={props.isLoading}/>
            </Box>
            <Box sx={{ typography: 'body1', color: 'text.disabled' }}>
              <TruncatedText text={course.description}  isLoading={props.isLoading} maxLines={3} />
            </Box>
          
          </Box>
        </Box>
        {course?.account?.startedAt &&
          <>
            <Divider />
            <Box sx={{ px: 2.5, py: 1.5, background: grey[50] }}>
              {(course?.account?.startedAt && !course?.account?.completedAt) &&
                <Box>
                  <Box sx={{ 
                    typography: 'body1', 
                    color: 'primary.main', 
                    paddingBottom: 0.5, 
                    display: 'flex', 
                    alignItems: 'center', 
                    fontWeight: 'medium'
                  }}>
                    <LoadableText 
                      text={`${getProgress(course)}% Completed`}
                      isLoading={props.isLoading}/>
                    <Dot sx={{ mx: 1 }}/>
                    <LoadableText 
                      text={`${course?.account?.stepsCompleted.length} of ${course?.content?.steps.length} lessons`}
                      isLoading={props.isLoading}/>
                  </Box>
                  <ProgressLinear value={getProgress(course)}/>
                  <Box sx={{ typography: 'body2', color: 'text.disabled', paddingTop: 0.5, display: 'flex', alignItems: 'center' }}>
                    <Icon type='clock'
                      fill='none'
                      stroke={theme.palette.text.disabled}
                      size={14}
                    />
                    <LoadableText 
                      sx={{ marginLeft: 1 }}
                      text={<>
                      Started on <span style={{ fontWeight: 600 }}>{toShortDateString(course?.account?.startedAt)}</span></>
                      }
                      isLoading={props.isLoading}/>
                  </Box>
                </Box>
              }
              {(course?.account?.startedAt && course?.account?.completedAt) &&
                <Box>
                  <Box sx={{ 
                    typography: 'body1', 
                    color: 'primary.main', 
                    paddingBottom: 0.5, 
                    display: 'flex', 
                    alignItems: 'center', 
                    fontWeight: 'medium'
                  }}>
                    <LoadableText 
                      text='100% Completed'
                      isLoading={props.isLoading}/>
                    <Dot sx={{ mx: 1 }}/>
                    <LoadableText 
                      text={`${course?.account?.stepsCompleted.length} of ${course?.content?.steps.length} lessons`}
                      isLoading={props.isLoading}/>
                  </Box>
                  <ProgressLinear value={getProgress(course)}/>
                  <Box sx={{ typography: 'body2', color: 'text.disabled', paddingTop: 0.5, display: 'flex', alignItems: 'center' }}>
                    <LoadableText 
                      text={<>
                      Completed On <span style={{ fontWeight: 600 }}>{toShortDateString(course?.account?.completedAt)}</span></>
                      }
                      isLoading={props.isLoading}/>
                  </Box>
                </Box>
              }
            </Box>
          </>
        }
      </Card>
    </Box>
  );
}