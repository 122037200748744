import React from 'react';

import { 
  Typography,
  Stack
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {  Box, Button, LoadableText, ProfileDetail } from '@components/v2/atoms';
import { hexToRGB } from '@helpers/theme';
import { useTheme } from '@emotion/react';
import { UserAvatar } from '@components/v2/molecules';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function ProfileHeader(props){
  const { profile, isLoading, user } = props;
  const history = useHistory();
  const theme = useTheme();

  const isThisUser = user?.id == profile?.id;

  return (
    <Box sx={{ borderRadius: 1, background: hexToRGB(theme.palette.primary.main, 0.05) }} >
      <Box flex
        center='h'
        fullWidth
        p={2}>
        <UserAvatar size={50}
          profile={profile}
          isLoading={isLoading}/>
        <Typography fontWeight={600} py={1} component='div'>
          <LoadableText isLoading={isLoading} text={profile?.name} dataTestId='profile-account-name-text'/>
        </Typography>
        <Stack direction='row'
          spacing={1}
          flex
          sx={{ flexWrap: 'wrap', justifyContent: 'center', paddingBottom: 1 }}>
          <ProfileDetail
            fontSize={12}
            iconSize={16}
            icon='location'
            text={profile?.country}
            isLoading={isLoading}/>
          {isThisUser &&
            <>
              <ProfileDetail
                fontSize={12}
                iconSize={16}
                icon='heart-beat'
                text={profile?.injury?.type}
                isLoading={isLoading}/>
              <ProfileDetail
                fontSize={12}
                iconSize={16}
                icon='running-person'
                text={profile?.injury?.activityType}
                isLoading={isLoading}/>
            </>
          }
        </Stack>
        <Typography variant='caption'
          color='text.secondary'
          textAlign='center'
          data-testid='profile-account-bio-text'>
          {profile?.bio}
        </Typography>
      </Box>
      {isThisUser && 
      <Box flex
        fullWidth
        px={2}
        sx={{ flexDirection: 'row-reverse', borderBottomRightRadius: '4px', borderBottomLeftRadius: '4px', background: hexToRGB(theme.palette.primary.main, 0.1) }}>
        <Button  endIcon={<ChevronRightIcon />}
          size='small'
          onClick={() => history.push('/profile/edit')}
          data-testid='profile-dialog-account-edit-profile-button'>
          Profile Info
        </Button>
      </Box>}
    </Box>
  );
}

