import React, { useContext, useState } from 'react';

import {
  Box,
  Divider
} from '@mui/material';

import AccountOnboardingDialog from './dialog';
import { useTheme } from '@emotion/react';
import Banner from './banner';
import { AccountOnboardingContext } from '@context';

export default function OnboardingBanner() {
  var { accountOnboarding } = useContext(AccountOnboardingContext);
  var [ isDialogOpen, setIsDialogOpen ] = useState(false);
  var theme = useTheme();

  if(accountOnboarding == null || accountOnboarding?.isComplete === true){
    return <></>;
  }

  return (
    <Box 
      sx={{ 
        paddingBottom: 1, 
        color: '#fff',
        //backgroundColor: theme.palette.braceGreenDark.main, 
        backgroundImage: `linear-gradient(${theme.palette.braceGreenDark.dark} 0%, ${theme.palette.braceGreenDark.main} 100%)`
      }}
    > 
      <Banner openDialog={() => setIsDialogOpen(true)}/>
      <AccountOnboardingDialog isOpen={isDialogOpen}
        close={() => {
          setIsDialogOpen(false);
        }}/>
    </Box>
  );
}