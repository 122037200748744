import React from 'react';

import { 
} from '@material-ui/core';

import { Page } from '@components/v2/organisms';

import { ProfileUpdate as ProfileUpdateForm } from '@components/templates';

export default function ProfileUpdate(){
  return (
    <Page title='Edit Profile'>
      <ProfileUpdateForm />
    </Page>
  );
}

