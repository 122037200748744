import React, { useContext } from 'react';

import { 
  Typography, 
  ButtonBase, 
  Card,
  Stack
} from '@mui/material';

import { Box, Radio } from '@components/v2/atoms';
import OnboardingPage from './../../page';
import Image from 'mui-image';
import { useTheme } from '@emotion/react';
import { OnboardingContext } from '../../../context';
import { grey } from '@mui/material/colors';

export default function Step1(){
  const theme  = useTheme();
  const { userType, setUserType } = useContext(OnboardingContext);

  function ButtonCard(props){
    const { type, title, dataTestId } = props;
    const isActive = type == userType;
    return (
      <ButtonBase sx={{ textAlign: 'left', width: '100%' }} onClick={() => setUserType(type)} data-testid={dataTestId}>
        <Card sx={{ 
          display: 'flex', 
          width: '100%', 
          borderColor: isActive ? theme.palette.secondary.main : grey[200],
          color: theme.palette.braceGreenDark.main,
          backgroundColor: isActive ? '00E39A' : theme.palette.background.paper
        }}
        variant='outlined'>
          <Box flex sx={{ flexGrow: 1, padding: 2.75, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box sx={{ 
              flexGrow: 1,
              fontSize: 'body1.fontSize', 
              fontWeight: 'medium' }}>
              {title}
            </Box>
            <Radio sx={{ p: 0 }} checked={isActive}/>
          </Box>
        </Card>
      </ButtonBase>
    );
    
  }

  return (
    <OnboardingPage shouldAppearBehindStatusBar sx={{ overflow: 'hidden', display: 'flex' }}>
      <Box sx={{ overflow: 'hidden' }}> 
        <Image
          width='calc(100vw + 2px)'
          style={{ marginLeft:-1 }}
          duration={0}
          src='https://bracesocial-prod-cdn.azureedge.net/static/onboarding_step_1.png'
        />
      </Box>
      <Box 
        flexColumn
        fullWidth
        sx={{
          flexGrow: 1,
          backgroundColor: theme.palette.background.default, 
          paddingTop: 6, 
          px: 2  
        }}>
        <Typography variant='h4'
          textAlign='center'
          fontWeight='bold'
          sx={{ paddingBottom: 1, px: 2 }}>
          Welcome to Brace!
        </Typography>
        <Typography variant='body2'
          textAlign='center'
          color='text.disabled'
          sx={{ px: 2 }}>
          Brace has two types of accounts, one for users who are Rehabilitating and one for our Practitioners.
        </Typography>
        <Box 
          flexColumn
          sx={{ flexGrow: 1, justifyContent: 'center' }}
        >
          <Stack spacing={2}>
            <ButtonCard 
              title='Rehabilitating'
              dataTestId='index-sign-up-role-selection-client'
              type='CLIENT'
            />
            <ButtonCard title='Practitioner'
              dataTestId='index-sign-up-role-selection-client'
              type='PRACTITIONER'
            />
          </Stack>
        </Box>
      </Box>
    </OnboardingPage>
  );
}