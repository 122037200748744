import React, { useContext, useEffect, useState } from 'react';

import { 
  Box
} from '@mui/material';

import { ServicesContext, UserContext  } from '@context';
import CreateDiaryDialog from '../../../diary/create/create-dialog';
import { moodToText } from '@helpers/text';
import ToDoItem from './to-do-item';
import useDiaryEntriesStore from '@stores/diary-entries';
import moment from 'moment';

export default function DiaryToDo(){
  const { user, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { isLoading, activeDayDiaryEntry, diaryStreak, setActiveDay } = useDiaryEntriesStore();
  const [isCreateDiaryOpen, setIsCreateDiaryOpen] = useState(false);

  const getIconForMood = () => {
    switch(activeDayDiaryEntry?.mood){

    case 1:
      return 'sentiment-angry';
    case 2:
      return 'sentiment-tired';
    case 3:
      return 'sentiment-ok';
    case 4:
      return 'sentiment-content';
    case 5:
      return 'sentiment-delighted';
    default:
    }
  };

  useEffect(() => {
    if(user?.id){
      setActiveDay(moment(), api, getUserToken);
    }
  }, [user]);

  return (
    <Box data-testid='home-diary-to-do'>
      <>
        {!activeDayDiaryEntry && 
            <ToDoItem 
              dataTestId='home-diary-to-do'
              headerDataTestId='home-diary-to-do-header'
              isLoading={isLoading}
              onClick={() => setIsCreateDiaryOpen(true)}
              iconType='diary'
              title={'Complete Today\'s Diary'}
              message={diaryStreak > 0 ? 
                `You’re on a ${diaryStreak} day streak!` : 
                'Start your diary streak!'
              }
            />
        }
        {activeDayDiaryEntry && 
            <ToDoItem 
              dataTestId='home-diary-to-do'
              headerDataTestId='home-diary-to-do-header'
              isLoading={isLoading}
              onClick={() => setIsCreateDiaryOpen(true)}
              iconType={getIconForMood()}
              title= {`You are ${moodToText(activeDayDiaryEntry.mood)}${activeDayDiaryEntry.mood >= 4 ? '!' : ''}`}
              message={diaryStreak > 0 ? 
                `You’re on a ${diaryStreak} day streak!` : 
                ''
              }
            />
        }
      </>
      <CreateDiaryDialog userId={user?.id}
        isOpen={isCreateDiaryOpen}
        diaryEntryBeingEdited={activeDayDiaryEntry}
        close={(shouldRefresh) => {
          setIsCreateDiaryOpen(false);
          if(shouldRefresh){
            setActiveDay(moment(), api, getUserToken);
          }
        }} />
    </Box>
  );
}

