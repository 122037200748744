import React from 'react';

import { 
  Box,
  Button
} from '@components/v2/atoms';
import { grey } from '@mui/material/colors';

export default function ButtonGroup(props){
  const { handleChange, options, value } = props;

  const FilterButton = (props) => {
    const { onClick, children, isLoading, buttonValue } = props;
    return (
      <Button 
        disabled={isLoading}
        color={buttonValue == value ? 'secondary' : 'primary'}
        isRounded={true}
        sx={{ 
          marginRight: 1, 
          p: 0,
          boxShadow: 'none',
          borderColor: buttonValue == value ? 'inherit' : grey[300] }}
        onClick={onClick}
        variant={buttonValue == value ? 'contained' : 'outlined'}>
        {children}
      </Button>
    ); 
  };

  return (
    <Box>
      {options.map(o =>(
        <FilterButton 
          buttonValue={o.value}
          key={o.name}
          onClick = {() => handleChange(o.value)}>
          {o.name}
        </FilterButton>
      ))}
    </Box>
  );
}