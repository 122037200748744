import React, { useContext, useEffect, useState } from 'react';

import { CommunityFilterProvider } from '@components/v2/organisms';
import {  Box, Card } from '@components/v2/atoms';
import { UserContext, ServicesContext, EventsContext } from '@context';
import { useTheme } from '@emotion/react';
import ProfileHeader from './components/header';
import { Tabs, Tab, a11yProps } from '@components/v2/molecules';
import { grey } from '@mui/material/colors';
import ProfileStatistics from './components/statistics';
import { PostFeed } from '@components/organisms';

export default function Index(props){
  const { profileId } = props;
  const theme = useTheme();
  const { user, getUserToken } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { api } = useContext(ServicesContext);
  const [ profile, setProfile ] = useState(null);
  const [ isLoadingProfile, setIsLoadingProfile ] = useState(true);
  const [ activeTab, setActiveTab ] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const loadProfile = async () => {
    setIsLoadingProfile(true);
    var result = await api.get({ url: `profiles/${profileId}`, getUserToken });
    setProfile(result);
    setIsLoadingProfile(false);
  };

  useEffect(() => {
    if(user?.id && profileId){
      trackEvent('Profile:Open');
      loadProfile();
    }
  }, [user, profileId]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        style={{ flexGrow: 1, overflow: 'hidden' }}
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ height: '100%', background: theme.palette.mode === 'dark' ? grey[900] : '#fff'  }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  return (
    <Card sx={{ width: '100%', p: 1, marginBottom: 2 }} >
      <ProfileHeader profile={profile} isLoading={isLoadingProfile} user={user}/>
      <Tabs 
        value={activeTab}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        dark={false}>
        <Tab dataTestId='profile-tab-statistics'
          dark={false}
          label="Statistics"
          width='50%'
          size='small'
          {...a11yProps(0)} />
        <Tab 
          dark={false}
          dataTestId='profile-tab-trophies'
          label="Feed"
          width='50%'
          size='small'
          {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={activeTab} index={0} dir={theme.direction}>
        <ProfileStatistics profileId={profileId} />
      </TabPanel>
      <TabPanel value={activeTab} index={1} dir={theme.direction}>
        <CommunityFilterProvider isSearchEnabled={false} userId={profile?.id}>
          <PostFeed 
            emptyStateTitle={`${profile?.id == user?.id ? 'You Have' : `${profile?.name} Has`} No Check-Ins Yet`} 
            emptyStateMessage=''
          />
        </CommunityFilterProvider>
      </TabPanel>

    </Card>
  );
}

