import React from 'react';

import { Box, Card, Icon } from '@components/v2/atoms';
import { useTheme } from '@emotion/react';
import { Typography, Divider as MuiDivider } from '@mui/material';
import { getDateNumber, getMonth3Letter } from '@helpers/dates';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import { moodToText } from '@helpers/text';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { TruncatedText } from '@components/atoms';

export default function DiaryEntry(props){
  const { diaryEntry, onClick } = props;
  const theme = useTheme();

  var date = moment(diaryEntry.createdAt);

  const getColor = (value, isReverse = false) => {
    var level = !isReverse ? value : 6 - value;

    switch(level){
    case 1:
      return '#EE695D';
    case 2:
      return '#FFBF0F';
    case 3:
      return '#FFCB3D';
    case 4:
      return '#48B164';
    case 5:
      return '#34A853';
    default: 
      return grey[400];
    }
  };

  const getIconForMood = () => {
    switch(diaryEntry?.mood){

    case 1:
      return 'sentiment-angry';
    case 2:
      return 'sentiment-tired';
    case 3:
      return 'sentiment-ok';
    case 4:
      return 'sentiment-content';
    case 5:
      return 'sentiment-delighted';
    default:
    }
  };

  const MoodBar = (props) => {
    const { value, isReverse = false } = props;

    const normalise = (value) => ((value - 0) * 100) / (5 - 0);

    return (
      <LinearProgress 
        sx={{ 
          width: 24, 
          height: 5,
          borderRadius: '1px',
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: getColor(value, isReverse)
          },
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#D9D9D9'
          }
        }} 
        variant="determinate"
        value={normalise(value)}/>
    );
  };

  const Detail = (props) => {
    const { title, value, sx, isReverse = false } = props;
    return (
      <Box sx={{ ...sx, px: 0.5 }}>
        <Typography color='text.disabled' variant='body2'>
          {title}
        </Typography>
        <Typography color='text.main' variant='body1'>
          {value}/5
        </Typography>
        <MoodBar value={value} isReverse={isReverse}/>
        
      </Box>
    );
  };

  const Divider = () => {
    return <MuiDivider 
      sx={{ mx: 0.5 }}
      flexItem
      orientation='vertical'
      variant="middle" />;  
  };

  return (
    <Card onClick={onClick}>
      <Box sx={{ p: 2, paddingBottom: diaryEntry.message ? 1 : 2, display: 'flex', alignItems: 'center' }}>
      
        <Box sx={{ 
          backgroundColor: theme.palette.primary.main, 
          width: 40, 
          minWidth: 40,
          height: 40, 
          borderRadius: 2, 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          marginRight: 1
        }}>
          <Typography fontWeight='medium' lineHeight={1}>
            {getDateNumber(date)}
          </Typography>
          <Typography variant='body2' lineHeight={1}>
            {getMonth3Letter(date)}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent:'space-between' }}>
          <Box
            sx={{ flexGrow: 1, color: getColor(diaryEntry.mood), display: 'flex', flexDirection: 'column' }}>
            <Typography color='text.disabled' variant='body2' sx={{ marginRight: 0.5 }}>
            Mood
            </Typography>
            <Box center='vertical' sx={{ flexGrow: 1 }}>
              <Icon 
                size={14} 
                stroke='#fff'
                fill={getColor(diaryEntry.mood)}
                type={getIconForMood()} 
              />
              <Typography variant='body2' sx={{ marginLeft: 0.5 }}>
                {moodToText(diaryEntry.mood)}
              </Typography>
            
              <Box/>
            </Box>
          </Box>
          <Divider />
          <Detail title='Pain' isReverse={true} value={diaryEntry.pain}/>
          <Divider />
          <Detail title='Energy' value={diaryEntry.energy}/>
          <Divider />
          <Detail title='Sleep' value={diaryEntry.sleep}/>
        </Box>
      </Box>
      {diaryEntry.message &&
        <Box sx={{ p: 2, py: 1, backgroundColor: grey[50] }}>
          <Typography component='div' color='text.secondary'> 
            <TruncatedText text={diaryEntry.message} maxLines={1}/>
          </Typography>
        </Box>
      }
    </Card>
  );
}