import React, { useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import { 
  Box,
  Dialog,
  Slide,
  Divider,
  Fade,
  InputBase,
  Alert
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import DiarySlider from './diary-slider';
import EmotionSelector from './emotion-selector';

import { createDiaryEntry, updateDiaryEntry } from '../../../actions/diary';
import ImageUploader from '../../../components/images/image-uploader';
import Image from '../../../components/atoms/image';
import { EventsContext, UserContext, AccountOnboardingContext } from '@context';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up"
    ref={ref}
    {...props} />;
});

const painMarks = [
  {
    value: 1,
    label: 'None'
  }, 
  {
    value: 5,
    label: 'Severe'
  }
];

const sleepMarks = [
  {
    value: 1,
    label: 'Poor'
  }, 
  {
    value: 5,
    label: 'Great'
  }
];

const energyMarks = [
  {
    value: 1,
    label: 'Very Low'
  },
  {
    value: 5,
    label: 'High'
  }
];

export default function CreateDiaryDialog(props) {
  var dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [moodLevel, setMoodLevel] = useState(3);
  const [energyLevel, setEnergyLevel] = useState(3);
  const [painLevel, setPainLevel] = useState(3);
  const [sleepLevel, setSleepLevel] = useState(3);
  const [commentValue, setCommentValue] = useState('');
  const [step, setStep] = useState(1);
  const [image, setImage] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);
  const { trackEvent } = useContext(EventsContext);
  const { activeOrganisation } = useContext(UserContext);
  var { accountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  
  useEffect(() => {
    if(props.diaryEntryBeingEdited){
      var diary = props.diaryEntryBeingEdited;
      setId(diary.id);
      setMoodLevel(diary.mood);
      setEnergyLevel(diary.energy);
      setPainLevel(diary.pain);
      setSleepLevel(diary.sleep === 0 ? 3 : diary.sleep);
      setCommentValue(diary.message ?? '');
      setImageUrl(diary.imageUrl);
      setStep(5);
    }
  }, [props.diaryEntryBeingEdited]);

  useEffect(() => {
    if(props.isOpen){
      trackEvent(`Diaries:${props.diaryEntryBeingEdited ? 'Edit' : 'New'}:Open`);
    }
  }, [props.isOpen]);

  const handleMoodSelection = (selectionValue) => {
    setMoodLevel(selectionValue);
    if(step < 2){
      setStep(2);
    }
  };

  const handleEnergySelection = (selectionValue) => {
    setEnergyLevel(selectionValue);
    if(step < 3){
      setStep(3);
    }
  };

  const handlePainSelection = (selectionValue) => {
    setPainLevel(selectionValue);
    if(step < 4){
      setStep(4);
    }
  };

  const handleSleepSelection = (selectionValue) => {
    setSleepLevel(selectionValue);
    if(step < 5){
      setStep(5);
    }
  };

  const handleCommentChange = (event) => {
    setCommentValue(event.target.value);
  };

  const saveImage = (image) => {
    setImageUrl(null);
    setImage(image);
  };

  const close = (shouldRefresh) => {
    trackEvent(`Diaries:${props.diaryEntryBeingEdited ? 'Edit' : 'New'}:Close`);
    setStep(1);
    setId(null);
    setMoodLevel(3);
    setEnergyLevel(3);
    setPainLevel(3);
    setSleepLevel(3);
    setCommentValue('');
    setImage(null);
    setImageUrl(null);
    setIsLoading(false);
    props.close(shouldRefresh);
  };

  const submit = () => {
    setIsLoading(true);
    trackEvent(`Diaries:${props.diaryEntryBeingEdited ? 'Edit' : 'New'}:Save`);
    if(!id){
      dispatch(createDiaryEntry(commentValue, moodLevel, energyLevel, painLevel, sleepLevel, (props.activeDay ?? moment()).toISOString(), image))
        .then(() => {
          setIsLoading(false);
          enqueueSnackbar('Diary entry created successfully.', { variant: 'success' });      
          close(true);
          if(!accountOnboarding.hasAddedDiary){
            loadAccountOnboarding();
          }

        });
    }else{
      dispatch(updateDiaryEntry(id, commentValue, moodLevel, energyLevel, painLevel, sleepLevel, image))
        .then(() => {
          enqueueSnackbar('Diary entry updated successfully.', { variant: 'success' }); 
          setIsLoading(false);
          close(true);
        });
    }
  };

  return (
    <Box>
      <Dialog open={props.isOpen}
        fullScreen
        TransitionComponent={Transition}
        onClose={close}
      >
        <Box sx={{
          p: 1,   
          display: 'flex',
          justifyContent: 'space-between', 
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          marginTop: 'env(safe-area-inset-top)'
        }} >
          <CloseIcon onClick={close}
            style={{ fontSize: 26 }}
            color="text.primary" />
          <Box>
            <LoadingButton loading={isLoading}
              data-testid='diary-save-button'
              onClick={submit}
              disabled={step < 4}
              size='small'
              variant='contained'
              color='secondary'>
              {id ? 'Update' : 'Create'}
            </LoadingButton>
          </Box>
        </Box>
        <Divider light/>
        <Box>
          {activeOrganisation?.id &&
            <Box sx={{ p: 1 }}>
              <Alert severity="info">Your diary metrics are also visible to your practitioner. Your diary notes are only visible to you.</Alert>
            </Box>
          }
          <Box sx={{ px: 1 }}>
            <Box sx={{ paddingTop: 1 }}>
              <EmotionSelector onSelect={handleMoodSelection} value={moodLevel}/>
            </Box>
            <Fade in={step > 1}>
              <Box sx={{ paddingTop: 1 }}>
                <Box sx={{ typography: 'h6', py: 1 }}>
                  How are your energy levels?
                </Box>
                <Box sx={{ px: 3 }}>
                  <DiarySlider 
                    dataTestId='energy-slider'
                    value={energyLevel}
                    marks={energyMarks}
                    inverse={false}
                    onSelect={handleEnergySelection}/>
                </Box>
              </Box>
            </Fade>
            <Fade in={step > 2}>
              <Box sx={{ paddingTop: 1 }}>
                <Box sx={{ typography: 'h6', py: 1 }}>
                  How are your pain levels?
                </Box>
                <Box sx={{ px: 3 }}>
                  <DiarySlider 
                    dataTestId='pain-slider'
                    value={painLevel}
                    marks={painMarks}
                    inverse={true}
                    onSelect={handlePainSelection}/>
                </Box>
              </Box>
            </Fade>
            <Fade in={step > 3}>
              <Box sx={{ paddingTop: 1 }}>
                <Box sx={{ typography: 'h6', py: 1 }}>
                  How did you sleep last night?
                </Box>
                <Box sx={{ px: 3 }}>
                  <DiarySlider 
                    dataTestId='sleep-slider'
                    value={sleepLevel}
                    marks={sleepMarks}
                    onSelect={handleSleepSelection}/>
                </Box>
              </Box>
            </Fade>
            <Fade in={step > 4}>
              <Box sx={{ paddingTop: 1 }}>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ flexGrow: 1, typography: 'h6', py: 1 }}>
                 Note to yourself?
                  </Box>
                  <Box sx={{ display: 'flex', lineHeight: 0, alignItems: 'center' }}>
                    <ImageUploader isEnabled={true} saveImage={saveImage}>
                      <CameraAltIcon color={'primary'} fontSize="large" />
                    </ImageUploader>
                  </Box>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <InputBase
                    fullWidth
                    multiline
                    autoFocus={true}
                    sx={{ color: 'text.primary', flexGrow: 1 }}
                    placeholder={'Note to self...'}
                    value={commentValue}
                    onChange={handleCommentChange} />
                </Box>
                {image &&
                  <Box p={2} width='100%'>
                    <img style= {{ width: '100%' }} src={URL.createObjectURL(image)} />
                  </Box>
                }
                {imageUrl &&
                  <Box p={2} width='100%'>
                    <Image src={imageUrl} />
                  </Box>
                }
              </Box>
              
            </Fade>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}