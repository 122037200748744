import React from 'react';

import { 
  Box,
  Typography,
  Stack
} from '@mui/material';

import moment from 'moment';
import DiaryToDo from './diary-to-do';
import SessionsToDo from './sessions-to-do';

export default function ToDo(){
  const today = moment();

  return (
    <Box px={2.5} paddingTop={1}>
      <Box sx={{ display : 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 1 }}>
        <Typography variant='h6' sx={{ lineHeight: 1, flexGrow: 1, fontWeight:'medium', color: '#0D0D12' }}>
          {`${today.format('dddd, Do MMMM')}`}
        </Typography>
      </Box>
      <Stack spacing={1}>
        <SessionsToDo />
        <DiaryToDo />
      </Stack>
    </Box>
  );
}

