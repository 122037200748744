import React, { useContext, useEffect, useState } from 'react';

import { Link, Stack, Typography, useMediaQuery } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Page from './page';
import { useTheme } from '@emotion/react';
import { useSnackbar } from 'notistack';
import { BillingContext, EventsContext } from '@context';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, Icon } from '@components/v2/atoms';
import { LoadingButton } from '@mui/lab';
import { FeedbackDialog } from '@components/v2/organisms';
import DropOff from './drop-off';
import RoundIcon from './round-icon';

export default function Trial(props){
  var theme = useTheme();
  const { openDeleteAccount } = props;
  const { enqueueSnackbar } = useSnackbar();
  const matches = useMediaQuery('(min-height: 800px)');
  const { startFreeTrial } = useContext(BillingContext);
  const { trackEvent } = useContext(EventsContext);
  const [ isStartingTrial, setIsStartingTrial ] = useState(false);
  const [ shouldShowDropOffScreen, setShouldShowDropOffScreen ] = useState(false);
  const [ isFeedbackDialogOpen, setIsFeedbackDialogOpen ] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if(shouldShowDropOffScreen){
      trackEvent('Payments:Trial:DropOffScreen');
    }
  }, [shouldShowDropOffScreen]);

  useEffect(() => {
    trackEvent('Payments:Trial:Open');
  }, []);

  const BulletPoint = (props) => {
    const { title, message, iconType } = props;

    return (
      <Card sx={{ display: 'flex', p: 2 }}>
        <Box sx={{ 
          background: theme.palette.secondary.main, 
          borderRadius: '50%', 
          width:'27px',
          minWidth:'27px',
          height:'27px',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          color: theme.palette.braceGreenLight.main
        }}>
          <Icon type={iconType} size={16} />
        </Box>
        <Box sx={{ paddingLeft: 2 }}>
          <Box>
            <Typography variant='body1' fontWeight='medium'>
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='text.secondary'>
              {message}
            </Typography>
          </Box>
        </Box>
        
      </Card>
    );
  };

  const startTrial = async () => {
    setIsStartingTrial(true);
    await startFreeTrial();
    setIsStartingTrial(false);
    enqueueSnackbar('Successfully started you Brace 7 day trial.', { 
      variant: 'success'
    });
    trackEvent('Payments:Trial:Started');
    history.push('/');
  };

  return (
    <Page showHeader={!shouldShowDropOffScreen}>
      {!shouldShowDropOffScreen &&
      <>
        {matches &&
          <Box fullWidth
            flexColumn
            center='h'
            paddingTop={2}>
            <RoundIcon iconType='calendar-trial' /> 
          </Box>
        }
        <Box sx={{ px: 2, paddingTop: matches ? 2 : 3, paddingBottom: 2, textAlign: 'center', color: theme.palette.primary.main }}>
          <Typography variant='h4'fontWeight={500}>{'Empower Your Recovery, First Week\'s On Us.'}</Typography>
        </Box>
        <Stack spacing={3} sx={{ flexGrow: 1, justifyContent: 'center', px: 2 }}>
          <BulletPoint title='Today: Start Your Free Trial' message='Join today with no commitment.' iconType='key' />
          <BulletPoint title='Days 1-7: Unlimited Access' message='Everything needed to kickstart your physical and mental recovery' iconType='infinity'/>
          <BulletPoint title='Day 7: Choose Your Plan' message='Choose the subscription offer that suits your recovery needs.' iconType='crown'/>
        </Stack>
        <Box sx={{ px: 2, paddingTop: 3, paddingBottom: 1, textAlign: 'center' }}>
          <Box>
            <Typography variant='body2' color='text.secondary'>{'We\'ll ask you at the end of your trial if you\'d like to subscribe to a Brace Plan.'}</Typography>
          </Box>
          <Box>
            <LoadingButton 
              fullWidth
              sx={{ marginTop: 2, marginBottom: 2, py: 1, fontSize: 'h6.fontSize' }}
              size='large'
              variant='contained'
              color='braceGreenDark'
              onClick={startTrial}
              loading={isStartingTrial}
              data-testid='paywall-start-trial-button'
              endIcon={<ChevronRightIcon />}
            >
            Continue
            </LoadingButton>
          </Box>
        
          <Button 
            sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
            size='small'
            color='braceGreenDark'
            onClick={() => setShouldShowDropOffScreen(true)}
          >
            No Thanks
          </Button>
          <Link target='_blank' href='https://www.bracesocial.com/policies' sx={{ display: 'flex', justifyContent: 'center', textDecoration: 'none', color: theme.palette.braceGreenDark.main  }}>
            <Box>
            Terms
            </Box>
            <Box mx={1}>
            &bull;
            </Box>
            <Box>
            Privacy
            </Box>
          </Link>
        </Box>
      </>
      }
      {shouldShowDropOffScreen &&
        <DropOff 
          confirmButtonText='Continue'
          confirmButtonAction={() => {
            trackEvent('Payments:Trial:Reengagment');
            setShouldShowDropOffScreen(false);
          }}
          cancelButtonText='Delete Account'
          cancelButtonAction={() => setIsFeedbackDialogOpen(true)}
        />
      }

      <FeedbackDialog
        isDeleteAccount={true}
        isOpen={isFeedbackDialogOpen} 
        close={(wasSurveyOpen, wasSurveyComplete) => {
          setIsFeedbackDialogOpen(false);
          if(!wasSurveyOpen){
            openDeleteAccount();
          }
          if(wasSurveyComplete){
            history.push('/');
          }
        }}
      />
    </Page>
  );
}