import React, { useContext, useState, useEffect } from 'react';

import {
  Stack,
  Typography,
  Card,
  Button,
  useMediaQuery
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Radio } from '@components/v2/atoms';

import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { LoadingButton } from '@mui/lab';
import { EventsContext, BillingContext } from '@context';
import RedeemOfferSection from './redeem-offer-section';
import { billingPeriodToNumberOfMonths, billingPeriodToFrequency, billingPeriodToPlanTitle } from '@helpers/text';
import { AccountOnboardingContext } from '@context/account-onboarding';
import { DialogContext } from '@context/dialogs';
import { isBraceSubscription } from '@helpers/subscription';
import { titleCase } from '@helpers/text';

import Page from './page';
import { grey } from '@mui/material/colors';
import DropOff from './drop-off';
import RoundIcon from './round-icon';

export default function Paywall(props) {
  const { setShouldShowDropOffScreen, shouldShowDropOffScreen, setIsFeedbackDialogOpen, setIsDeleteAccountOpen } = props;
  const { subscription, offerings, hasActiveSubscription, purchasePackage, purchaseDiscount, isPurchasingPackage, isPollingForSubscription, cancelPollingForSubscription } = useContext(BillingContext);
  const { accountOnboarding } = useContext(AccountOnboardingContext);
  const { openSurveyDialog, closeSurveyDialog } = useContext(DialogContext);
  const { trackEvent } = useContext(EventsContext);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [showDiscountCodeInput, setShowDiscountCodeInput] = useState(false);
  const [discounts, setDiscounts] = useState([]);

  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery('(min-height: 800px)');

  const handlePurchase = () => {
    if(selectedDiscount){
      trackEvent('Payments:Subscription:Discount:Selected');
      purchaseDiscount(selectedDiscount, () => {
        trackEvent('Payments:Subscription:Discount:Purchase');
        history.push('/');
      });
    }else{
      trackEvent('Payments:Subscription:Plan:Selected');
      purchasePackage(selectedPackage.rcOffering, () => {
        trackEvent('Payments:Subscription:Plan:Purchase');
        history.push('/');
      });
    }

  };

  const BulletPoint = (props) => {
    const { message } = props;

    return (
      <Box flex center='v'>
        <Box sx={{ 
          color: theme.palette.braceGreenDark.main
        }}>
          <Radio checked sx={{ p: 0.5 }}/>
        </Box>
        <Box sx={{ paddingLeft: 1 }}>
          <Typography variant='body2' color='text.secondary'>
            {message}
          </Typography>
        </Box>
        
      </Box>
    );
  };

  const calculateSaving = (offering) => {
    var highestPricePerMonth = 0;

    offerings.forEach(o => {
      var pricePerMonth = o.rcOffering?.product?.pricePerMonth;
      if(pricePerMonth > highestPricePerMonth){
        highestPricePerMonth = pricePerMonth;
      }
    });

    var absoluteSaving = highestPricePerMonth - offering.rcOffering?.product?.pricePerMonth;

    return Math.round((absoluteSaving * 100) / highestPricePerMonth);

  };

  const Offering = (props) => {
    const { offering } = props;

    const isSelected = selectedPackage?.id == offering.id;
    const availableDiscount = discounts.filter(d => d.id == offering.id)[0];
    const hasAvailableDiscount = availableDiscount != null;
    
    var pricePerMonthString = offering.rcOffering?.product?.pricePerMonthString;
    var title = `${billingPeriodToPlanTitle(offering.period)} - ${offering.price}`;
    var message = `${pricePerMonthString}/mo when billed ${billingPeriodToFrequency(offering.period)}`;
    var saving = 0;

    if(hasAvailableDiscount){
      if(availableDiscount?.freePeriod){
        title = `${billingPeriodToNumberOfMonths(availableDiscount.freePeriod)} Free`;
        message = `then ${pricePerMonthString}/mo billed ${billingPeriodToFrequency(offering.period)}`;
      }else if(availableDiscount?.discountedPeriod){
        var price = availableDiscount?.prices ?
          availableDiscount?.prices[offering.currencyCode] : 
          availableDiscount.defaultPrice;

        if(availableDiscount.offer){
          var offerPrice = availableDiscount.offer?.introPhase?.price?.formatted;

          if(offerPrice){
            price = offerPrice;
          }
        }
       
        title = `${billingPeriodToNumberOfMonths(availableDiscount.discountedPeriod)} for ${price}`;
        message = `${offering.price} there after billed ${billingPeriodToFrequency(availableDiscount?.discountedPeriod)}`;
      }
    }else{
      saving = calculateSaving(offering);
    }

    const Saving = (props) => {
      const { label } = props;
      return(
        <Box sx={{ 
          py: '0px',
          px: 0.5,
          borderRadius: 1,
          backgroundColor: isSelected ? theme.palette.secondary.main : '#E0FFF5'
        }}>
          <Typography variant='caption' lineHeight={1}>
            {label}
          </Typography>
          
        </Box>
      );
    };

    return (
      <Card 
        variant='outlined'
        sx={{ 
          p: 2,
          display: 'flex',
          width: '100%', 
          borderColor: isSelected ? theme.palette.secondary.main : grey[200],
          color: theme.palette.braceGreenDark.main,
          backgroundColor: isSelected ? '#E0FFF5' : theme.palette.background.paper
        }}
        onClick={() => {
          setSelectedDiscount(availableDiscount);
          setSelectedPackage(offering);
        }}>
        <Box center='h'>
          <Radio
            sx={{ p: 0 }}
            checked={isSelected}
          />
        </Box>
        <Box flexColumn sx={{ paddingLeft: 1, flexGrow: 1 }}>
          <Box flex fullWidth>

            <Typography variant='body1'  fontWeight='medium' sx={{ flexGrow: 1, lineHeight: 1, paddingBottom: 0.5 }}>
              {title}
            </Typography>
            {availableDiscount?.discountPercent > 0 &&
              <Saving label= {`${availableDiscount.discountPercent * 100}% OFF`}/>
            }
            {(!availableDiscount?.discountPercent && saving > 0) &&
              <Saving label= {`${saving}% OFF`}/>
            }
          </Box>
          <Box>
            <Typography variant='body2' color='text.secondary'>
              {message}
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  };

  useEffect(() => {
    setShowDiscountCodeInput(false);
    if(subscription?.id){
      trackEvent('Payments:Paywall:Open');
      if(subscription.isTrial){
        openSurveyDialog((shouldShowSurvey, wasSubmitted) => {
          if(wasSubmitted){
            history.push('/');
          }
        });
      }
    }

    return () => {closeSurveyDialog();};
  }, []);

  const isAccountOnboardingComplete = accountOnboarding?.isComplete;
  
  return (
    <Page showHeader={!shouldShowDropOffScreen}>
      {!shouldShowDropOffScreen &&
      <>
        {matches &&
          <Box fullWidth
            flexColumn
            center='h'
            paddingTop={2}>
            <RoundIcon />
          </Box>
        }
        <Box sx={{ px: 2, paddingTop: matches ? 2 : 3, paddingBottom: 2, textAlign: 'center', color: theme.palette.primary.main }}>
          <Typography variant='h4'fontWeight={500}>{'Empower Your Recovery, Come Back Stronger'}</Typography>
        </Box>
        {isBraceSubscription(subscription) &&
          <Stack spacing={0.2} sx={{ px: 3 }}>
            <BulletPoint message='Prioritise Your Health & Wellbeing' />
            <BulletPoint message='Stay On Track With Your Recovery Plan' />
            <BulletPoint message='Access Community Insights & Motivation' />
            {isAccountOnboardingComplete &&  <BulletPoint message={<span>Brace Pro Discount Code: <b>BRACESTART10</b></span>} />}
            {!isAccountOnboardingComplete &&  <BulletPoint message='10% off for Brace Starters'/>}
          </Stack>
        }
        {!isBraceSubscription(subscription) &&
          <Box px={3} color='text.secondary'>
            {subscription?.status == 'EXPIRED' &&
              <>
                <Box>
                  <Typography variant='h5' fontWeight={'medium'} textAlign='center' >
                    {'Your Subscription Has Finished!'}
                  </Typography>
                </Box>
              </>
            }
            <Box sx={{ paddingTop: 1 }}>
              <Typography variant='body1' fontWeight={'medium'} textAlign='center' >
                {`Please repurchase a subscription on the ${titleCase(subscription?.source)} platform, or you can directly subscribe to a plan below.`}
              </Typography>
            </Box>
          </Box>
        }
        <Stack spacing={matches ? 2 : 1} sx={{ paddingTop: 2, px: 2, flexGrow: 1 }}>
          {offerings.map(o => <Offering key={o.id} offering={o}/>)}
        </Stack>
        {showDiscountCodeInput && 
          <RedeemOfferSection close={() => setShowDiscountCodeInput(false)}
            handleDiscounts={(d) => {
              setSelectedPackage(null);
              setDiscounts(d);
            }}/>
        }
        {(!isPollingForSubscription && !showDiscountCodeInput  && discounts?.length > 0) && 
            <Typography textAlign='center'>Discount Code Applied!</Typography>
        }
        {isPollingForSubscription &&
            <Typography textAlign='center'>Checking For Subscription...</Typography>
        }
        <Box flexColumn center='h' sx={{ paddingBottom: 2 }}>
          {!showDiscountCodeInput && 
            <>
              {(discounts?.length == 0) &&
                <Button 
                  sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
                  size='small'
                  color='braceGreenDark'
                  onClick={() => setShowDiscountCodeInput(true)}
                >
                  Have a discount code?
                </Button>
              }
              <Box sx={{ width: '100%', p: 2 }}>
                <LoadingButton 
                  sx={{ py: 1, px: 4, fontSize: 'h6.fontSize' }}
                  size='large'
                  variant='contained'
                  color='braceGreenDark'
                  fullWidth={true}
                  disabled={!selectedPackage}
                  loading={isPurchasingPackage || isPollingForSubscription}
                  onClick={handlePurchase}
                  endIcon={<ChevronRightIcon />}
                >
                  {selectedDiscount ? 'CONTINUE TO OFFER' : 'CONTINUE'}
                </LoadingButton>
              </Box>
            </>
          }
          {(isPollingForSubscription) &&
           <Button 
             sx={{ px: 4 }}
             color='braceGreenDark'
             fullWidth={true}
             onClick={cancelPollingForSubscription}
           >
            Cancel
           </Button>
          }
          {(!isPollingForSubscription && hasActiveSubscription()) &&
           <Button 
             sx={{ px: 4 }}
             color='braceGreenDark'
             fullWidth={true}
             onClick={() => history.goBack()}
           >
            Cancel
           </Button>
          }
          {(!isPollingForSubscription && !hasActiveSubscription()) &&
              <Button 
                sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
                size='small'
                color='braceGreenDark'
                onClick={() => setShouldShowDropOffScreen(true)}
              >
                Not Right Now
              </Button>
          }
        </Box>
      </>
      }
      {shouldShowDropOffScreen &&
        <DropOff 
          confirmButtonText='View Plans'
          confirmButtonAction={() => setShouldShowDropOffScreen(false)}
          cancelButtonText='Delete Account'
          cancelButtonAction={() => {
            if(subscription?.isTrial){
              setIsFeedbackDialogOpen(true);
            }else{
              setIsDeleteAccountOpen(true);
            }
                    
          }}
        />
      }
    </Page>
  );
}