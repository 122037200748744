import React, { useContext, useEffect, useState } from 'react';

import { 
  Box,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { ServicesContext, UserContext, AccountOnboardingContext } from '@context';
import { LoadableList } from '@components/atoms';
import { Icon, Button, Card } from '@components/v2/atoms';
import { CreateGoalDialog } from '@components/organisms';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';
import UserGoal from './goal';

export default function Goals(){
  var { user, getUserToken } = useContext(UserContext);
  var { accountOnboarding, loadAccountOnboarding } = useContext(AccountOnboardingContext);
  var { api } = useContext(ServicesContext);
  const [isLoadingGoals, setIsLoadingGoals] = useState(true);
  const [goals, setGoals] = useState([]);
  const [areGoalsExpanded, setAreGoalsExpanded] = useState(false);
  const [isCreateGoalDialogOpen, setIsCreateGoalDialogOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const query = new URLSearchParams(
    location.search
  );

  const loadGoals = async () => {
    setIsLoadingGoals(true);
    var result = await api.get({ url: 'goals', getUserToken });
    setGoals(result.results);
    setIsLoadingGoals(false);
  };

  function EmptySection(){
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 1 }}>
        <Box sx={{ p: 1 }}>
          <Icon type='target' size='30'/>
        </Box>
        <Typography textAlign='center'
          fontWeight='medium'
          width='75%'
          paddingBottom={1}>
              Set Goals For Your Recovery Journey!
        </Typography>
      </Box>
    );
  }

  useEffect(() => {
    if(user?.id){
      loadGoals();
    }
    if(query.get('defaultNewGoalOpen') === 'true'){
      setIsCreateGoalDialogOpen(true);
      query.delete('defaultNewGoalOpen');
      history.replace({
        search: query.toString()
      });
    }
  }, [user]);

  useEffect(() => {
    if(query.get('defaultNewGoalOpen') === 'true'){
      setIsCreateGoalDialogOpen(true);
      query.delete('defaultNewGoalOpen');
      history.replace({
        search: query.toString()
      });
    }
  }, [query.get('defaultNewGoalOpen')]);

  const openCreateGoalDialog = () => {
    setIsCreateGoalDialogOpen(true);
  };

  const sortedGoals = goals.sort(function(a, b) {
    if(a?.isCompleted && !b?.isCompleted){
      return 1;
    }
    if(!a?.isCompleted && b?.isCompleted){
      return -1;
    }

    if(a.targetDate && !b.targetDate){
      return -1;
    }

    if(!a.targetDate && b.targetDate){
      return 1;
    }

    if(a.targetDate && b.targetDate){
      return moment(a.targetDate).valueOf() - moment(b.targetDate).valueOf();
    }

    return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();
  });

  var goalDescriptions = document.getElementsByClassName('goal-description');

  var isADescriptionOverflowing = false;

  for (let goalDescription of goalDescriptions) {
    if(goalDescription.scrollHeight > goalDescription.clientHeight || goalDescription.scrollWidth > goalDescription.clientWidth){
      isADescriptionOverflowing = true;
      break;
    }
  }

  return (
    <Box sx={{ paddingBottom: 1 }} >
      <Box sx={{ 
        height: '30px', 
        background: theme.palette.primary.main
      }}>
      </Box>
      <Box sx={{ marginTop: '-25px', display:'flex', justifyContent: 'center', px: 2.5 }}>
        <Card sx={{ width: '100%', p: 1.5 }} >
          {(!isLoadingGoals && goals.length == 0) && 
            <EmptySection />
          }
          {(!isLoadingGoals && goals.length > 0) && 
            <Typography fontWeight='medium' marginBottom={1}>
              My Goals
            </Typography>
          }
          <Box>
            <LoadableList 
              numberOfRows={2}
              isLoading={isLoadingGoals}
              rows={sortedGoals.slice(0, areGoalsExpanded ? sortedGoals.length : 3)}
              getRow={(goal) => <UserGoal key={goal.id}
                goal={goal}
                isLoadingGoals={isLoadingGoals}
                areGoalsExpanded={areGoalsExpanded} /> }
            />
          </Box>
          {((!isLoadingGoals && (sortedGoals.length > 3 || isADescriptionOverflowing)) && !areGoalsExpanded) &&
          <Box 
            sx={{ display : 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: 'text.secondary', marginTop: 0.5  }}
            onClick={() => setAreGoalsExpanded(!areGoalsExpanded)}>
            <Typography sx={{ p: 0, lineHeight: 1 }} variant='caption'>Show More</Typography>
          </Box>
          }
          {(!isLoadingGoals && areGoalsExpanded) &&
              <Box 
                sx={{ display : 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: 'text.secondary' }}
                onClick={() => setAreGoalsExpanded(!areGoalsExpanded)}>
                <Typography sx={{ p: 0, lineHeight: 1 }} variant='caption'>Show Less</Typography>
              </Box>
          }
          <Button 
            variant='outlined' 
            data-testid={'home-open-add-goal-dialog'} 
            onClick={openCreateGoalDialog}
            disabled={isLoadingGoals}
            fullWidth
            sx={{ borderColor: grey[200], marginTop: 1, color: '#030712' }}
            startIcon={<AddIcon color='#030712'/>}>
            {`Add ${sortedGoals.length > 0 ? 'More' : ''} Goals`}
          </Button>
        </Card>
      </Box>
     
      <CreateGoalDialog 
        isOpen={isCreateGoalDialogOpen}
        close={(shouldRefresh) => {
          if(shouldRefresh == true){
            loadGoals();
            if(!accountOnboarding?.hasAddedRecoveryGoal){
              loadAccountOnboarding();
            }
          }

          setIsCreateGoalDialogOpen(false);
        }}
      />
    </Box>
  );
}

