import Paywall from '@components/v2/templates/paywall';
import { UserContext } from '@context/user';
import { Box } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAccount } from './../../actions/users';
import { EventsContext } from '@context/events';
import { ColorModeContext } from '@context/theme';
import { useHistory } from 'react-router-dom';

export default function PaywallContainer(){
  var dispatch = useDispatch();
  var { handleLogout } = useContext(UserContext);
  var { trackEvent } = useContext(EventsContext);
  const history = useHistory();

  const handleDelete = (feedback) => {
    trackEvent('Account:Deleted');
    return dispatch(deleteAccount(feedback))
      .then(() => {
        handleLogout(() => history.push('/'));
      });
  };

  return (
    <Box sx={{ height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))' }}>
      <Paywall handleDelete={handleDelete} />
    </Box>
  );
}

