import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './store';
import { Capacitor } from '@capacitor/core';

import { 
  IconButton 
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { ServicesProvider } from '@context/services';
import { UserProvider } from '@context/user';
import { EventsProvider } from '@context/events';
import { FeaturesProvider } from '@context/features';
import { RecoveryProvider } from '@context/recovery';
import { BillingProvider } from '@context/billing';
import { NewPostProvider } from '@components/organisms';
import { DialogProvider } from '@context/dialogs';
import { AppProvider } from '@context/app';
import { AccountOnboardingProvider } from '@context/account-onboarding';

import CssBaseline from '@material-ui/core/CssBaseline';

import { SnackbarProvider } from 'notistack';

import ToggleColorMode from '@context/theme';

import config from '@config';

import { loadUser, logOut } from './actions/users';
import { openSuccessModal } from './actions/modal';
import { TriggersProvider } from '@context/triggers';
import { isNative } from '@helpers/device';

const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <Provider store={store}>
    <ToggleColorMode shouldForceLight={true}>
      <SnackbarProvider 
        autoHideDuration={3000}
        ref={notistackRef}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIcon sx={{ color: '#fff' }}/>
          </IconButton>
        )}>
        <ServicesProvider config={config}>
          <Auth0Provider
            domain={config.auth0Domain}
            clientId={config.auth0ClientId}
            cacheLocation='localstorage'
            useRefreshTokens= {isNative()}
            authorizationParams={{
              audience: config.auth0Audience,
              redirect_uri: isNative() ?
                'com.bracesocial.app://auth.bracesocial.com/capacitor/com.bracesocial.app/callback' :
                window.location.origin
            }}>
            <AppProvider>
              <UserProvider
                loadUser={loadUser}
                openSuccessModal={openSuccessModal}
                logOut={logOut}>
          
                <FeaturesProvider>
                  <BillingProvider>
                    <EventsProvider config={config}>
                      <AccountOnboardingProvider openSuccessModal={openSuccessModal}>
                        <DialogProvider>
                          <TriggersProvider>
                            <NewPostProvider>
                              <RecoveryProvider>
                                <CssBaseline />
                                <App />
                              </RecoveryProvider>
                            </NewPostProvider>
                          </TriggersProvider>
                        </DialogProvider>
                      </AccountOnboardingProvider>
                    </EventsProvider>
                  </BillingProvider>
                </FeaturesProvider>
              </UserProvider>
            </AppProvider>
          </Auth0Provider>
        </ServicesProvider>
      </SnackbarProvider>
    </ToggleColorMode>
  </Provider>,
  document.getElementById('app')
);