import React, { useContext, useState } from 'react';

import { 
  Typography 
} from '@mui/material';

import { Box } from '@components/v2/atoms';
import OnboardingPage from './../../page';
import { useTheme } from '@emotion/react';
import { OnboardingContext } from '../../../context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { getCookie, COOKIE_NAMES } from '@helpers/cookies';
import { EventsContext, UserContext, ServicesContext } from '@context';
import Step1 from './step-1';
import Step2 from './step-2';
import config from '@config';
import { faker } from '@faker-js/faker';

const TEST_VALUES = {
  firstName: faker.person.firstName(),
  lastName: faker.person.lastName(),
  dateOfBirth: moment().subtract(20, 'years'),
  country: '',
  injuryType: '',
  activityType: [],
  bio: ''
};

export default function OnboardingStep2(){
  const MIN_AGE = 17;
  const NUMBER_OF_STEPS = 2;
  const theme  = useTheme();
  const [ step, setStep ] = useState(0);
  const { next, back } = useContext(OnboardingContext);
  const { trackEvent } = useContext(EventsContext);
  const { getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [clickCount, setClickCount] = useState(0);

  const isLastStep = step == NUMBER_OF_STEPS - 1;

  const validationSchema = yup.object({
    firstName: yup
      .string('First Name is required')
      .required('First Name is required'),
    lastName: yup
      .string('Last Name is required')
      .required('Last Name is required'),
    injuryType: yup
      .string('Injury Type is required')
      .required('Injury Type is required'),
    activityType: yup
      .array()
      .test({
        message: 'Maximum number of activities is 5',
        test: arr => arr.length < 6
      }),
    dateOfBirth: yup
      .string('Date Of Birth is required')
      .required('Date Of Birth must be a valid date')
      .nullable()
      .test(
        'Date Of Birth',
        `Users must be ${MIN_AGE} or older to use Brace.`,
        (date) => moment().diff(moment(date), 'years') >= MIN_AGE
      )
      
  });

  const formik = useFormik({
    validateOnMount: true,
    initialValues:{
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      country: '',
      injuryType: '',
      activityType: [],
      bio: ''
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      if(!isLastStep){
        trackEvent('Onboarding:AccountDetails:Submit');
        setStep(step + 1);
        return;
      }
      setSubmitting(true);
      var inviteToken = getCookie(COOKIE_NAMES.INVITE_TOKEN);
      await api.put({ url: 'accounts/activate', getUserToken, 
        data: { 
          firstName: values.firstName, 
          lastName: values.lastName, 
          dateOfBirth: values.dateOfBirth, 
          role:'CLIENT', 
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
          inviteToken 
        } });
      await api.put({ url: 'users', getUserToken, data: {
        country: values.country,
        firstName: values.firstName,
        lastName: values.lastName,
        bio: values.bio,
        injury: {
          activityType: values.activityType.join(','),
          type: values.injuryType
        }
      } });

      trackEvent('Onboarding:ProfileDetails:Submit');
      setSubmitting(false);
      next(next);
    }
  });

  const handleNext = () => {
    if(step == 0){
      setStep(step + 1);
    }else{
      formik.handleSubmit();
    }
  };

  const isNextDisabled = () => {
    if(step == 0){
      return formik.errors.firstName != null || formik.errors.lastName != null || formik.errors.dateOfBirth != null;
    }else{
      return !formik.isValid;
    }
  };

  console.log(formik);

  return (
    <OnboardingPage
      includeHeader
      isNextDisabled={isNextDisabled()}
      nextOverride={handleNext}
      isLoadingOverride={formik.isSubmitting}
      backOverride={() => {
        if(step == 0){
          back();
        }else{
          setStep(step - 1);
        }
      }}>
      <Box 
        flexColumn
        fullWidth
        fullHeight
        sx={{ 
          backgroundColor: theme.palette.background.default, 
          paddingTop: 3, 
          px: 2  
        }}>
        <Typography variant='h4'
          textAlign='center'
          fontWeight='bold'
          onClick={() => {
            if(clickCount < 2){
              setClickCount(clickCount + 1);
            }else if(config.isTestingEnabled){
              formik.setValues(TEST_VALUES);
              setStep(1);
            }
          }}
          sx={{ paddingBottom: 1, px: 2 }}>
          Let’s get started
        </Typography>
        <Typography variant='body2'
          textAlign='center'
          color='text.disabled'
          sx={{ px: 2 }}>
          {step == 0 &&
            <>
              Please enter your account details below
            </>
          }
          {step == 1 &&
            <>
              Please enter your profile details to let people know a little bit about you
            </>
          }
          
        </Typography>
        {step == 0 &&
          <Step1 formik={formik}/>
        }
        {step == 1 &&
          <Step2 formik={formik}/>
        }
      </Box>
    </OnboardingPage>
  );
}