import React, { useCallback, useContext, useState } from 'react';

import { 
  Box,
  Typography,
  Stack,
  IconButton
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { RecoveryContext } from '@context';

import Section from './section';
import { LoadableList, LoadableText } from '@components/atoms';
import { Card, Dot, Icon } from '@components/v2/atoms';
import { useHistory } from 'react-router-dom';
import { NewPlanDialog } from '@components/templates';
import EmptySection from './empty-section';
import { optionalPlural } from '@helpers/text';
import { toShortDateString } from '@helpers/dates';
import { useTheme } from '@emotion/react';

export default function Plans(){
  const PLANS_SECTION_HEIGHT_IN_PX = 68;
  const { plans, plansIncludedDraftsAndArchived, isLoadingPlans, loadPlans } = useContext(RecoveryContext);
  const [isPlanDialogOpen, setIsPlanDialogOpen] = useState(false);
  const history = useHistory();
  const [numberOfRows, setNumberOfRows] = useState(2);
  const theme = useTheme();

  const Plan = (props) => {
    const { plan } = props;

    return (
      <Card
        sx={{ p: 1, display: 'flex', alignItems: 'center' }}
        onClick={() => history.push(`/recovery/plans/${plan.id}`)} 
        data-testid={`recovery-manage-plans-plan-${plan.id}`}
      >
        <Box sx={{ height: '100%' }}>
          <Icon type='clipboard' size={24}/>
        </Box>
        
        <Box sx={{ flexGrow: 1, paddingLeft: 1.5 }}>
          <Typography component='div'
            variant='body1'
            color='#000' 
            fontWeight='medium'>
            <LoadableText isLoading={isLoadingPlans} text={plan?.name} minWidth={200}/>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='div'
              variant='caption'
              color='text.secondary'>
              <LoadableText isLoading={isLoadingPlans} text={`${plan?.exerciseCount} ${optionalPlural('Exercise', plan?.exerciseCount)}`} minWidth={100}/>
            </Typography>
            <Dot sx={{ mx: 1 }}/>
            <Typography component='div'
              variant='caption'
              color='text.secondary'>
              <LoadableText isLoading={isLoadingPlans} text={`Created : ${toShortDateString(plan?.createdAt)}`} minWidth={100}/>
            </Typography>
          
          </Box>
        </Box>
        <IconButton onClick={() => history.push(`/recovery/plans/${plan.id}`)} sx={{ paddingRight: 0 }}>
          <ChevronRightIcon color='disabled' sx={{ fontSize: 16 }} />
        </IconButton>
      </Card>
    );
  };

  const plansStackRef = useCallback(node => {
    if (!node) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      var height = entries[0].contentRect.height;
      if(height){
        setNumberOfRows(Math.max(1,
          Math.floor(height / PLANS_SECTION_HEIGHT_IN_PX))
        );
      }
    });
    resizeObserver.observe(node);
  }, []);

  return (
    <Section title='Plans' 
      buttonDataTestId={plansIncludedDraftsAndArchived.length > 0 ? 'recovery-plans-manage' : 'recovery-plans-add'}
      buttonText={plansIncludedDraftsAndArchived.length > 0 ? 'View All' : null}
      buttonDisabled={isLoadingPlans}
      buttonAction={() => { plansIncludedDraftsAndArchived.length > 0 ? 
        history.push('/recovery/plans')
        : setIsPlanDialogOpen(true);} }
      buttonIcon= {plansIncludedDraftsAndArchived.length > 0 ? 
        <ChevronRightIcon /> 
        :         <Icon 
          type='circle-plus'
          fill={theme.palette.secondary.main}
          stroke={theme.palette.primary.main}
          size={24}/>}
    >
      {(!isLoadingPlans && plans.length == 0) &&
      <>
        <EmptySection 
          dataTestId='recovery-plan-the-first-step'
          iconType='clipboard'
          message=' Create an Plan to Assign to your Programs and Sessions'
          action={() => setIsPlanDialogOpen(true)}/>
        <NewPlanDialog isOpen={isPlanDialogOpen}
          close={(shouldRefresh) => {
            if(shouldRefresh){
              loadPlans(shouldRefresh);
            }
            setIsPlanDialogOpen(false);
          }}/>
      </>
      }
      {(isLoadingPlans || plans.length > 0) &&
        <Stack spacing={1} sx={{ flexGrow: 1, px: 2  }} ref={plansStackRef}>
          <LoadableList 
            numberOfRows={numberOfRows}
            isLoading={isLoadingPlans}
            rows={plans.slice(0, numberOfRows)}
            getRow={(plan) => <Plan 
              key={plan.id}
              plan={plan}
            /> }
          />
        </Stack>
      }
    </Section>
  );
}

