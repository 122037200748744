import React from 'react';

import { Chip, Typography, Stack } from '@mui/material';

import {  Box, Button, Divider, LoadableText } from '@components/v2/atoms';
import { useTheme } from '@emotion/react';
import { UserAvatar } from '@components/v2/molecules';
import { toDateOfBirth } from '@helpers/dates';
import { isNullOrEmptyOrBlack } from '@helpers/text';

export default function ProfileView(props){
  const { profile, isLoading, user, switchToEdit } = props;
  const theme = useTheme();

  const Detail = (props) => {
    var { name, value } = props;
    return (
      <Box flex
        fullWidth
        px={2}
        py={1}
        sx={{ justifyContent: 'space-between', maxWidth: '100%' }}>
        <Typography color='text.secondary' variant='body2' sx={{ marginRight: 1 }}>
          {name}
        </Typography>
        <Typography component='div'
          fontWeight='medium'
          variant='body2'
          sx={{ flexGrow: 1, textAlign: 'right', overflowX: 'hidden', 'a':{ color: 'text.primary', textDecoration: 'none' } }}>
          <LoadableText isLoading={isLoading}
            text={value ?? '-'}
            minWidth='100px'
            sx={{ width: '100%', maxWidth: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowX: 'hidden' }}/>
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ borderRadius: 1 }} >
      <Box flex
        center='h'
        fullWidth>
        <Box p={2}>
          <UserAvatar size={68}
            user={user}
            profile={profile}
            isLoading={isLoading}/>
        </Box>
        <Detail name='NAME' value={`${profile?.firstName} ${profile?.lastName}`} />
        <Divider flexItem/>
        <Detail name='EMAIL' value={user?.email} />
        <Divider flexItem/>
        <Detail name='LOCATION' value={profile?.country} />
        <Divider flexItem/>
        <Detail name='DATE OF BIRTH' value={user?.dateOfBirth ? toDateOfBirth(user?.dateOfBirth) : null} />
        <Divider flexItem/>
        <Box
          fullWidth
          px={2}
          py={1}>
          <Typography color='text.secondary' variant='body2'>
            BIO
          </Typography>
          <Typography component='div'
            fontWeight='medium'
            variant='body2'
            paddingTop={0.5}>
            <LoadableText isLoading={isLoading} text={isNullOrEmptyOrBlack(profile?.bio) ? '-' : profile?.bio} minWidth='100px'/>
          </Typography>
        </Box>
        <Divider flexItem/>
        <Box
          fullWidth
          px={2}
          py={1}>
          <Typography color='text.secondary' variant='body2'>
            INJURY
          </Typography>
          <Typography component='div'
            fontWeight='medium'
            variant='body2'
            paddingTop={0.5}>
            <LoadableText isLoading={isLoading} text={isNullOrEmptyOrBlack(profile?.injury?.type) ? '-' : profile?.injury?.type} minWidth='100px'/>
          </Typography>
        </Box>       
        <Divider flexItem/>
        <Box
          fullWidth
          px={2}
          py={1}>
          <Typography color='text.secondary' variant='body2'>
            ACTIVITIES
          </Typography>
          {(isLoading || isNullOrEmptyOrBlack(profile?.injury?.activityType)) && <LoadableText isLoading={isLoading} text={'-'} minWidth='100px'/>}
          <Box 
            flex
            paddingTop={0.5}
            sx={{ flexWrap: 'wrap', 
              'div:not(:last-child)':{
                mx:0.25
              },
              'div:not(:first-of-type)':{
                mx:0.25
              }
            }}>
            {profile?.injury?.activityType?.split(',').filter(a => !isNullOrEmptyOrBlack(a)).map(a => 
              <Chip size='small'
                sx={{ marginY: 0.5 }}
                variant='outlined'
                key={a}
                label={a}/>)}
          </Box>
        </Box>
        <Divider flexItem/>
        <Box fullWidth p={2}>
          <Button fullWidth
            m={1}
            disabled={isLoading}
            size='large'
            color='negative'
            variant='outlined'
            data-testid='profile-edit-button'
            onClick={switchToEdit}>
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

