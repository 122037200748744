import React, { useContext, useEffect, useState } from 'react';

import { 
  Box,
  Typography,
  Tab,
  Divider,
  Card,
  IconButton
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Page } from '@components/v2/organisms';
import SubPage from '../../../../components/page/sub-page';
import { UserContext, EventsContext } from '@context';
import { useTheme } from '@emotion/react';
import { NewPlanDialog } from '@components/templates';
import { EmptyState, Tabs, TabPanel, a11yProps } from '@components/molecules';
import { LoadableList, LoadableText } from '@components/atoms';
import { toShortDateTimeString } from '@helpers/dates';
import { useHistory } from 'react-router-dom';
import { RecoveryContext } from '@context/recovery';

export default function ManagePlans(){
  var theme = useTheme();
  var history = useHistory();
  const { trackEvent } = useContext(EventsContext);
  const { user, activeOrganisation } = useContext(UserContext);
  const { plansIncludedDraftsAndArchived: plans, isLoadingPlans, loadPlans } = useContext(RecoveryContext);
  const [activeTab, setActiveTab] = useState(0);
  const [isPlanDialogOpen, setIsPlanDialogOpen] = useState(false);
  const [planBeingEdited, setIsPlanBeingEdited] = useState(null);

  useEffect(() => {
    if(user){
      trackEvent('Recovery:Plans:Manage:Open');
      loadPlans();
    }
  }, [user]);

  const Plan = (props) => {
    var { plan } = props;
    return (
      <Box sx={{ width: '100%', p: 1, cursor: 'pointer' }} onClick={() => history.push(`/recovery/plans/${plan.id}`)} data-testid={`recovery-manage-plans-plan-${plan.id}`}>
        <Card variant='outlined' sx={{ p: 1 }}>
          <Box sx={{ display: 'flex', paddingBottom: 0.5, alignItems: 'end' }}>
            <Typography 
            
              sx={{ flexGrow: 1 }}
              variant='body1'
              fontWeight='medium'
              component='div'>
              <LoadableText dataTestId={`recovery-manage-plans-plan-${plan.id}-name`} isLoading={isLoadingPlans} text={plan.name}/>
            </Typography>
            {(!plan?.isDraft && !plan.deletedAt) &&
              <Typography component='div' variant='body2'>
                <LoadableText isLoading={isLoadingPlans} text={`Exercises: ${plan.exerciseCount}`}/>
              </Typography>
            }
          </Box>
          {(!plan?.isDraft && !plan.deletedAt) &&
          <>
            <Box sx={{ display: 'flex' }}>
              <Typography component='div' variant='body2' sx={{ flexGrow: 1 }}>
                <LoadableText isLoading={isLoadingPlans} text={`Total Sets: ${plan.setCount}`}/>
              </Typography>
              <Typography component='div' variant='body2'>
                <LoadableText isLoading={isLoadingPlans} text={` Total Reps: ${plan.repCount}`}/>
             
              </Typography>
            </Box>
            {plan.assignedByUserName &&
              <Box sx={{ display: 'flex' }}>
                <Typography component='div' variant='body2'>
                  <LoadableText isLoading={isLoadingPlans} text={`Assigned By: ${plan.assignedByUserName}`}/>
                </Typography>
                
              </Box>
            }
          </>
          }
          {(!plan?.assignedByUserId && !plan.deletedAt) &&
            <Box>
              <Typography component='div' variant='body2'>
                {`Last Updated : ${toShortDateTimeString(plan.updatedAt ?? plan.createdAt)}`}
              </Typography>
            </Box>
          }
          {plan?.deletedAt &&
            <Box>
              <Typography component='div' variant='body2'>
                {`Archived Date : ${toShortDateTimeString(plan?.deletedAt)}`}
              </Typography>
            </Box>
          }
        </Card>
      </Box>
    );
  };

  var currentMaxTabNumber = 0;

  var assignedPlans = plans.filter(p => p.assignedByUserId && !p.deletedAt && !p.isDraft);
  var createdPlans = plans.filter(p => !p.assignedByUserId && !p.deletedAt && !p.isDraft);
  var archivedPlans = plans.filter(p => p.deletedAt && !p.isDraft);
  var draftPlans = plans.filter(p => !p.deletedAt && p.isDraft);
  var assignedPlansTabNumber = assignedPlans.length > 0 ? 
    currentMaxTabNumber++ 
    : null;
  var createdPlansTabNumber = createdPlans.length > 0 ? 
    currentMaxTabNumber++
    : null;
  var archivedPlansTabNumber = archivedPlans.length > 0 ? 
    currentMaxTabNumber++
    : null;
  var draftPlansTabNumber = draftPlans.length > 0 ? 
    currentMaxTabNumber++
    : null;
  
  if(currentMaxTabNumber > 0 && activeTab >= currentMaxTabNumber){
    setActiveTab(0);
  }

  return (
    <Page>
      <SubPage 
        title={'My Plans'}
        isBackButtonEnabled={true}
        endButton={
          <IconButton color='primary' onClick={() => setIsPlanDialogOpen(true)} disabled={isLoadingPlans}>
            <AddCircleIcon  style={{ fontSize: 28 }}/>
          </IconButton>}
      >
        {isLoadingPlans &&
          <LoadableList isLoading={true} getRow={(p, index) => <Plan key={index} plan={{}}/>}/>
        }
        {!isLoadingPlans && plans.length > 0 &&
        <>
          <Tabs   
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            value={activeTab} 
            onChange={(event, newValue) => {setActiveTab(newValue);}} 
            aria-label="basic tabs example">
            {assignedPlans.length > 0 &&
            <Tab label="Assigned" {...a11yProps(assignedPlansTabNumber)} />
            }
            {createdPlans.length > 0 &&
            <Tab label="Created" data-testid='recovery-manage-plans-created-tab' {...a11yProps(createdPlansTabNumber)} />
            }
            {archivedPlans.length > 0 &&
            <Tab label="Archived" data-testid='recovery-manage-plans-archived-tab' {...a11yProps(archivedPlansTabNumber)} />
            }
            {draftPlans.length > 0 &&
            <Tab label="Draft" data-testid='recovery-manage-plans-draft-tab'  {...a11yProps(draftPlansTabNumber)} />
            }
          </Tabs>
          <Divider />
          {assignedPlansTabNumber != null &&
            <TabPanel value={activeTab} index={assignedPlansTabNumber} dir={theme.direction}>
              <Box>
                <Typography px={1} paddingTop={1} variant='h5'>
                  {activeOrganisation?.name}
                </Typography>
                <Box>
                  {assignedPlans.map(p => <Plan key={p.id} plan={p}/>)}
                </Box>
              </Box>
            
            </TabPanel>
          }
          {createdPlansTabNumber != null &&
            <TabPanel value={activeTab} index={createdPlansTabNumber} dir={theme.direction}>
              {createdPlans.map(p => <Plan key={p.id} plan={p}/>)}
            </TabPanel>
          }
          {archivedPlansTabNumber != null &&
            <TabPanel value={activeTab} index={archivedPlansTabNumber} dir={theme.direction}>
              {archivedPlans.map(p => <Plan key={p.id} plan={p}/>)}
            </TabPanel>
          }
          {draftPlansTabNumber != null &&
            <TabPanel value={activeTab} index={draftPlansTabNumber} dir={theme.direction}>
              {draftPlans.map(p => <Plan key={p.id} plan={p}/>)}
            </TabPanel>
          }

        </>
        }
        {!isLoadingPlans && plans.length === 0 &&
        <Box p={6}>
          <EmptyState 
            shouldHideBorder={true}
            size={100}
            iconType='to-do-list' 
            message={'Let\'s get started, create your first exercise plan!'}
            buttonText='Create Plan'
            buttonAction={() => setIsPlanDialogOpen(true)}
          />
        </Box>
        }
      </SubPage>
      <NewPlanDialog isOpen={planBeingEdited != null || isPlanDialogOpen}
        planBeingEdited={planBeingEdited}
        close={(shouldRefresh) => {
          if(shouldRefresh){
            loadPlans(shouldRefresh);
          }
          setIsPlanBeingEdited(null);
          setIsPlanDialogOpen(false);
        }}/>
    </Page>
  );
}

