import React, { useCallback, useContext, useState } from 'react';
import { 
  Typography,
  Stack
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { 
  Icon, 
  Radio, 
  Card, 
  IconWithBackground, 
  Dot, 
  Box 
} from '@components/v2/atoms';

import { RecoveryContext } from '@context';

import Section from './section';
import { LoadableList, LoadableText } from '@components/atoms';
import { useHistory } from 'react-router-dom';
import CreateSessionDialog from '../sessions/create/create-session-dialog';
import EmptySection from './empty-section';
import { ServicesContext, UserContext } from '@context';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { closeModal, openSuccessModal } from './../../../actions/modal';

import axios from 'axios';
import { svgToImage } from './../../../utils/images';
import { NewPostContext } from '@components/organisms';

import { useTheme } from '@emotion/react';
import { optionalPlural } from '@helpers/text';
import { getBottomNavbarHeight } from '@helpers/elements';

export default function Sessions(props){
  const SESSION_SECTION_HEIGHT_IN_PX = 75;
  const { getUserToken } = useContext(UserContext);
  const theme = useTheme();
  const { api } = useContext(ServicesContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  const { toggleExpansion, areSessionsExpanded } = props;
  const { activeDaysSessions, isLoadingActiveDaysSessions, loadActiveDaySessions, loadDailySessionStats, loadPrograms, plans } = useContext(RecoveryContext);
  var [ isNewSessionDialogOpen, setIsNewSessionDialogOpen ] = useState(false);
  var [ numberOfRows, setNumberOfRows ] = useState(2);
  const history = useHistory();

  const Session = (props) => {
    const { session } = props;
    const dispatch = useDispatch();

    const getSetsRemaining = () => {
      var remainingCount = 0;
      if(session?.plan){
        session.plan.exercises.forEach(e => {
          e.sets.forEach(set => {
            if(!set.isComplete){
              remainingCount++;
            }
          });
        });
      }
      return remainingCount;
    };

    const savePost = async (data) => {
      const formData = new FormData();
      formData.append('message', data.message);
  
      if(data.image){
        formData.append('image', data.image);
      }
  
      if(data.organisation){
        formData.append('organisationId', data.organisation.id);
      }
  
      if(data.team){
        var teamIds = [ data.team.id ];
        formData.append('teamIds', teamIds);
      }
  
      await api.post({ data: formData, url: 'community/posts', getUserToken });
      dispatch(closeModal());
    };
  
    const openCreatePost = (i) => {
      openNewPostDialog({ 
        title: 'New Check-In', 
        placeholder: 'Your Post..', 
        buttonAction: savePost, 
        successToastMessage : 'Check-in Created!', 
        isAccessControlEnabled: true,
        isPrivateEnabled: true,
        initialImage: i
      });
    };
  
    const displayCompleteModal = () => {
      dispatch(openSuccessModal('Plan Completed For Today',  (<><p>Well done - you are now one step closer to your recovery goal!</p><p>Why not post about it to the Brace community?</p></>), 'confetti', 
        { text: 'Post To Community', 
          onClick: () => {
            axios({
              method: 'get',
              url: 'https://bracesocialprod.blob.core.windows.net/static/testing_svg.svg'
            })
              .then(async(response) => {
                var svg = response.data.replace('$$PLAN_NAME_GOES_HERE$$', session.plan.planName);
                var i = await svgToImage({ svg, mimetype: 'image/png', quality: 1, outputFormat: 'blob' });
  
                openCreatePost(i);
              });
  
          } }));
    };

    const completeAll = async () => {
      if(!session.plan.isComplete){
        await api.put({ url: `recovery/sessions/${session.id}/exercises/completeAll`, getUserToken });
        displayCompleteModal();
        loadActiveDaySessions(session.id);
        loadDailySessionStats(true);
        if(session.program?.id){
          loadPrograms(true);
        }
      }
    };

    var setsRemaining = getSetsRemaining(session);

    return (
      <Card
        sx={{ p: 1.5, display: 'flex', alignItems: 'center', backgroundColor : '#fff' }}
        data-testid={`recovery-session-card-${session?.plan?.planId}-${moment(session?.plannedStartDate).format('YYYYMMDD')}`}
      >
        <IconWithBackground size={40} type='stretching-person'/>
        <Box  sx={{ paddingLeft: 1.5, flexGrow: 1 }} onClick={() => history.push(`/recovery/sessions/${session.id}`)}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component='div'
              variant='body1'
              fontWeight='medium'
              color='#000'>
              <LoadableText isLoading={isLoadingActiveDaysSessions} text={session?.plan?.planName} minWidth={200}/>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component='div' variant='caption' color='text.secondary'>
              <LoadableText isLoading={isLoadingActiveDaysSessions} text={`${session?.plan?.exercises.length} ${optionalPlural('Exercise', session?.plan?.exercises.length)}`} minWidth={100}/>
            </Typography>
            <Dot sx={{ mx: 1 }}/>
            <Typography component='div' variant='caption' color={ setsRemaining > 0 ? 'warning.main' : 'success.main'}>
              <LoadableText isLoading={isLoadingActiveDaysSessions}
                text={
                  setsRemaining > 0 ?
                    `${optionalPlural('Set', setsRemaining)} Remaining : ${setsRemaining}` :
                    'Sets Complete'
                }
                minWidth={100}/>
            </Typography>
          </Box>
        </Box>

        <Radio
          size={18}
          onClick={completeAll}
          sx={{ p: 0 }}
          checked={session?.plan?.isComplete}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleIcon />}
        />
      </Card>
    );
  };

  const sessionsStackRef = useCallback(node => {
    
    if (!node) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      
      var height = entries[0].contentRect.height;
      if(height){
        // -36 for the show more button
        setNumberOfRows(Math.max( 1,
          Math.floor((height - 36) / SESSION_SECTION_HEIGHT_IN_PX))
        );
      }
    });
    resizeObserver.observe(node);
  }, []);

  const sortedSessions = activeDaysSessions.sort(function(a, b) {
    if(a.plan?.isComplete && !b.plan?.isComplete){
      return 1;
    }
    if(!a.plan?.isComplete && b.plan?.isComplete){
      return -1;
    }
    return moment(b.updatedAt ?? b.createdAt).valueOf() - moment(a.updatedAt ?? a.createdAt).valueOf();
  });

  const showLess = () => {
    setNumberOfRows(2);
    toggleExpansion();
  };

  return (
    <Section id='sessions-section'
      buttonDataTestId='recovery-session-create-button'
      title='Todays Sessions'
      enabledScroll={areSessionsExpanded}
      buttonAction={plans.length > 0 ? () => setIsNewSessionDialogOpen(true) : null}
      buttonIcon={<Icon 
        type='circle-plus'
        fill={theme.palette.secondary.main}
        stroke={theme.palette.primary.main}
        size={24}/>
      }>
      {(!isLoadingActiveDaysSessions && activeDaysSessions.length == 0) &&
        <>
          <EmptySection 
            opacity= {plans.length === 0 ? 0.4 : 1}
            iconType='stretching-person'
            message={plans.length > 0 ?
              'Sessions to be complete in a day will be shown here.' 
              : 'Create A Plan to Add and Complete Sessions.'}
            action={plans.length > 0 ?
              () => setIsNewSessionDialogOpen(true)
              : null} />
        </>
      }
      <Box 
        sx={{ 
          height: '100%', 
          overflowY: 'scroll',
          px: 2
        }} 
        ref={sessionsStackRef}>
        <Stack 
          spacing={1}>
          <LoadableList 
            numberOfRows={numberOfRows}
            isLoading={isLoadingActiveDaysSessions}
            rows={sortedSessions.slice(0, areSessionsExpanded ? 1000 : numberOfRows)}
            getRow={(session) => <Session key={session.id} session={session}/> }
          />
          {((!isLoadingActiveDaysSessions && activeDaysSessions.length > numberOfRows) && !areSessionsExpanded) &&
         <Box 
           sx={{ display : 'flex', flexDirection: 'column', alignItems: 'center', color: 'text.secondary' }}
           onClick={toggleExpansion}>
           
           <Typography sx={{ p: 0, lineHeight: 1 }} variant='caption'>Show More</Typography>
           <ExpandMoreIcon sx={{ p: 0, fontSize: '1rem' }} />
         </Box>
          }
        </Stack>
      </Box>

      {(!isLoadingActiveDaysSessions && areSessionsExpanded) &&
            <Box 
              sx={{ 
                position: 'fixed',
                width: '100%',
                background: theme.palette.background.color,
                bottom: `calc(${getBottomNavbarHeight() + 8}px + env(safe-area-inset-bottom))`,
                left: 0,
                right: 0,
                display : 'flex', 
                flexDirection: 'column', 
                alignItems: 'center' 
              }}
              onClick={showLess}>
              <ExpandLessIcon sx={{ p: 0, fontSize: '1.125rem' }} />
              <Typography sx={{ p: 0, lineHeight: 1 }} variant='body2'>Show Less</Typography>
           
            </Box>
      }

      <CreateSessionDialog isOpen={isNewSessionDialogOpen}
        close={(shouldRefresh) => {
          if(shouldRefresh){
            loadActiveDaySessions(true);
          }
          setIsNewSessionDialogOpen(false);}
        } />
    </Section>
  );
}

