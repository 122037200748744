import React from 'react';

import {
  Stack
} from '@mui/material';

import { TextField, Select } from '@components/v2/atoms';

const ACTIVITY_TYPE_OPTIONS = [
  'Acrobatics',
  'American Football',
  'Archery',
  'Australian Rules Football',
  'Badminton',
  'Ballet',
  'Baseball',
  'Basketball',
  'Bodybuilding',
  'Bowling',
  'Boxing',
  'Brazilian Jiu-Jitsu (BJJ)',
  'Calisthenics',
  'Camogie',
  'Cheerleading',
  'Cricket',
  'CrossFit',
  'Cycling',
  'Dance',
  'Diving',
  'Fencing',
  'Field Hockey',
  'Figure Skating',
  'Floorball',
  'Frisbee',
  'Gaelic Football',
  'Golf',
  'Gym',
  'Gymnastics',
  'Handball',
  'Hiking',
  'Horseriding',
  'Hurling',
  'Ice Hockey',
  'Jiu-Jitsu',
  'Judo',
  'Karate',
  'Kayaking',
  'Kickboxing',
  'Martial Arts',
  'Lacrosse',
  'Luge',
  'Military',
  'Mixed Martial Arts (MMA)',
  'Motocross',
  'Muay Thai',
  'Netball',
  'None',
  'Padel',
  'Parkour',
  'Pickleball',
  'Pilates',
  'Powerlifting',
  'Rock Climbing',
  'Rowing',
  'Rugby League',
  'Rugby Sevens',
  'Rugby Union',
  'Running',
  'Sailing',
  'Skateboarding',
  'Skiing',
  'Snooker',
  'Snowboarding',
  'Snow Sports',
  'Soccer',
  'Softball',
  'Squash',
  'Surfing',
  'Swimming',
  'Table Tennis',
  'Taekwondo',
  'Tag Rugby',
  'Tennis',
  'Touch Rugby',
  'Track and Field',
  'Trail Running',
  'Trampolining',
  'Triathlon',
  'Volleyball',
  'Walking',
  'Water Sports',
  'Water Polo',
  'Weightlifting',
  'Windsurfing',
  'Work Related',
  'Wrestling',
  'Yoga',
  'Other'
];

export default function Step1(props){
  const { formik } = props;

  return (
    <Stack 
      spacing={3}
      sx={{ flexGrow: 1, marginTop: 2, marginBottom: 2 }}
    >
      <TextField 
        label='What injury do you have?'
        placeholder='Eg. Ruptured ACL'
        inputProps={{ 'data-testid': 'index-sign-up-text-injury' }}
        id='injuryType'
        required
        onChange={formik.handleChange}
        value={formik.values.injuryType}
        error={formik.touched.injuryType && Boolean(formik.errors.injuryType)}
        helperText={formik.touched.injuryType && formik.errors.injuryType}
      />
      <Select
        label='What activity do you take part in?'
        placeholder='Eg. Football'
        options={ACTIVITY_TYPE_OPTIONS}
        onChange={(value) => {
          formik.setFieldValue('activityType', value);
          formik.setFieldTouched('activityType', true);
        }}
        value={formik.values.activityType}
        error={formik.touched.activityType && Boolean(formik.errors.activityType)}
        helperText={formik.touched.activityType && formik.errors.activityType}
        onBlur={() => {
          formik.setFieldTouched('activityType', true);
        }}
      />
      <TextField 
        label='Add to your Brace Bio'
        placeholder='I’m a 800m runner from Dublin...'
        id='bio'
        multiline
        rows={4}
        onChange={formik.handleChange}
        value={formik.values.bio}
        error={formik.touched.bio && Boolean(formik.errors.bio)}
        helperText={formik.touched.bio && formik.errors.bio}
      />

    </Stack>
  );
}